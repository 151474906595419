import {LayerOutput} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {ColorClasses} from "./data/ColorClasses";
import {PAPER_SIZE_MULTIPLE} from "../../utils/getFitCalendarZoom";

type Props = {
    layer?: LayerOutput,
    zoom: number,
    adjustedZoom: number,
}

export default function LeftColumn(props : Props) {
    const {
        layer,
        zoom,
        adjustedZoom
    } = props;

    let text = 'no layer'

    let classes = ColorClasses['stone'];
    if (layer) {
        classes = ColorClasses[layer.color];
        text = layer.name;
    }

    const coreWidth = 2 * (25 * (adjustedZoom / 3)) + (adjustedZoom * 3) + 52 * adjustedZoom
    // const coreHeight = 9 * (9 * (zoom / 3)) + (8 * zoom * 1.5) + 90 * zoom
    const availableSpace = PAPER_SIZE_MULTIPLE * zoom
    const sideMargins= (availableSpace - coreWidth) / 2
    const availableLeftMargin = sideMargins - zoom * 2 // subtract the width of the tick

    return (
        <div className={`absolute left-0 h-full ${classes.split(' ')[3]} border-none`} style={{
            width: availableLeftMargin,
        }}>
            <div className="whitespace-nowrap absolute top-1/2 left-1/2" style={{
                transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
            }}>
                <p
                    className='text-center uppercase tracking-widest whitespace-normal line-clamp-2 font-bold'
                    style={{
                        fontSize: text.length > 40 ? zoom * 3 : zoom * 3,
                        lineHeight: 1,
                        width: zoom * 100,
                    }}
                >
                    {text}
                </p>
            </div>
        </div>
    );
}