import LayersContainer from "./LayersContainer";
import LayerCardContainer from "./LayerCardContainer";

export default function LayersSkeleton() {
    return (
        <LayersContainer>
            {
                [...Array(9)].map((value, index) => {
                    return (
                        <LayerCardContainer
                            key={index}
                            extraClasses='bg-stone-200 animate-pulse shadow-md'
                        >
                        </LayerCardContainer>
                    )
                })
            }
        </LayersContainer>
    )
}