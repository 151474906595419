import {ChangeEvent} from "react";
import FormField, {FormFieldProps} from "./components/FormField";
import {labels} from "../../data/labels";

type EmailFieldProps = {
    value: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
} & Partial<FormFieldProps>

export default function EmailField(props : EmailFieldProps) {
    const {
        name = 'email',
        label = labels.fields.email.label,
        ...rest
    } = props;

    return (
        <FormField
            label={label}
            name={name}
            type='email'
            maxLength={256}
            {...rest}
        />
    )
}