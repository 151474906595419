import {Analytics, API, Auth, Logger} from 'aws-amplify';
import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react'
import {pushToast} from "../toast/toastSlice";
import {getErrorToast} from "../../../utils/toastUtils";


type method = 'GET' | 'POST' | 'DELETE'
type ExtraOptions = {
    isDemoRequest?: boolean
}

const logger = new Logger('apiSlice')

const amplifyBaseQuery = ({baseUrl = ''}: { baseUrl: string }): BaseQueryFn<
    {
        url: string
        method: method,
        data?: any
        params?: any
    }, // Args
    unknown, // Result
    unknown, // Error
    ExtraOptions // DefinitionExtraOptions
    // Meta
> => async ({url, method, data, params}, api) => {
    logger.debug('amplifyBaseQuery -> params: ', {url, method, data, params})
    logger.debug('amplifyBaseQuery -> api.endpoint: ', api.endpoint)

    Analytics.record({
        name: 'api',
        attributes: {
            endpoint: api.endpoint,
            url,
            method,
        }
    })

    try {
        let calendarID

        if (params?.calendarID) {
            calendarID = params.calendarID
        } else {
            try {
                const user = await Auth.currentAuthenticatedUser();
                calendarID = user.attributes.sub
            } catch (error: any) {
                logger.info('amplifyBaseQuery -> err: ', error)
            }
        }

        const apiName = 'apilifecalendar';
        let response;

        switch (method) {
            case 'GET':
                response = await API.get(apiName, `${baseUrl}/${calendarID}/${url}`, {})
                break;
            case 'POST':
                response = await API.post(apiName, `${baseUrl}/${calendarID}/${url}`, {body: data})
                break
            case "DELETE":
                response = await API.del(apiName, `${baseUrl}/${calendarID}/${url}`, {})
                break
        }

        logger.debug('amplifyBaseQuery -> response: ', response)

        return {data: response}
    } catch (error: any) {
        logger.error('amplifyBaseQuery -> err: ', error)

        const toast = getErrorToast(error)
        if (toast) {
            api.dispatch(pushToast(toast))
        }

        Analytics.record({
            name: 'apiError',
            attributes: {
                endpoint: api.endpoint,
                url,
                method,
                error: typeof error === 'string' ? error : JSON.stringify(error),
            }
        })

        return {
            error: {
                status: 500,
                data: 'An error occurred',
            }
        }
    }
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: amplifyBaseQuery({
        baseUrl: '/calendars'
    }),
    tagTypes: ['Layers', 'Events'],
    endpoints() {
        return {

        }
    },
})