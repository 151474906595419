import {forwardRef, Fragment, ReactNode} from "react";
import {Transition} from "@headlessui/react";

type Props = {
    show?: boolean;
    children: ReactNode;
}
const PopoverTransition = forwardRef<any, Props>((props, ref) => {
    const {
        show,
        children,
    } = props;

    return (
        <Transition
            ref={ref}
            as={Fragment}
            show={show}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
        >
            {children}
        </Transition>
    )
})

export default PopoverTransition;