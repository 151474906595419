import {Toast} from "../redux/features/toast/toastSlice";

type ToastResponse = Omit<Toast, 'id' | 'isOpen'> | null;

const getErrorToast = (error: Error): ToastResponse => {
    let message = ''
    let title = 'An error occurred'

    if (error.message === 'Network error') {
        title = 'Network error'
        message = 'Please check your internet connection and try again.'
    }

    if (error.name === 'LimitExceededException') {
        title = 'Too many requests'
        message = 'Please try again later.'
    }

    if (message !== '') {
        return {
            title: title,
            message: message,
            color: 'error',
        }
    }

    return null
}

export {getErrorToast}