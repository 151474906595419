import FormField, {FormFieldProps} from "./components/FormField";
import {labels} from "../../data/labels";
import Button from "../ui/buttons/Button";
import FormFieldHelper from "./components/FormFieldHelper";


type VerificationCodeFieldProps = {
    name?: string,
    onHelperClick: () => void,
    helpActionInProgress?: boolean,
} & Omit<FormFieldProps, 'name' | 'helper'>


export default function VerificationCodeField(props : VerificationCodeFieldProps) {
    const {
        name = 'verificationCode',
        label = labels.fields.verificationCode.label,
        onHelperClick,
        required = true,
        minLength= 6,
        maxLength= 6,
        helpActionInProgress,
        ...rest
    } = props;

    return (
        <FormField
            label={label}
            name={name}
            required={required}
            minLength={minLength}
            maxLength={maxLength}
            helper={(
                <FormFieldHelper>
                    <div className='flex items-center gap-x-2 justify-end'>
                        <div className='text-xxs sm:text-xs'>{labels.fields.verificationCode.helper.text}</div>
                        <Button
                            type='button'
                            size='small'
                            variant='text'
                            label={labels.fields.verificationCode.helper.action}
                            onClick={onHelperClick}
                            inProgress={helpActionInProgress}
                        />
                    </div>
                </FormFieldHelper>
            )}
            {...rest}
        />
    )
}