const PAPER_SIZE_MULTIPLE = 105
const EXTRA_MARGIN = 10
export const SMALL_ITEMS_ZOOM_THRESHOLD = 5

const layoutPadding = 1.15
const RATIO = 1.41

export default function getFitCalendarZoom() {
    const height = window.innerHeight
    const width = window.innerWidth
    const normalizedHeight = height / RATIO
    const min = Math.min(normalizedHeight, width)
    return min / (PAPER_SIZE_MULTIPLE * layoutPadding)
}

export function getMaxCalendarZoom() {
    const width = window.innerWidth
    return width / (PAPER_SIZE_MULTIPLE * layoutPadding)
}

export function getDefaultZoom() {
    const max = getMaxCalendarZoom()
    const fit = getFitCalendarZoom()

    return (max + fit) / 2
}

export {PAPER_SIZE_MULTIPLE, EXTRA_MARGIN}