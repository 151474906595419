import Emoji from "../../../components/ui/Emoji";
import LinkButton from "../../../components/ui/buttons/LinkButton";
import { AuthLayout } from "../../../features/authentication";
import { useGetUserQuery } from "../../../redux/features/user/userSlice";

export default function SignUpSuccess() {
    const { data: user, isSuccess } = useGetUserQuery();

    console.log(user, isSuccess);

    return (
        <AuthLayout
            title={
                <>
                    Welcome to Life Calendar <Emoji label="success" symbol="🎉" />
                </>
            }
            subTitle="We're thrilled to have you join our community of individuals who are committed to embracing and organizing life's moments."
        >
            <div className="flex justify-center py-4">
                <div className="basis-full sm:basis-1/2 md:basis-1/3">
                    {user ? (
                        <LinkButton fullWidth size={"large"} variant={"contained"} label="Get Started" to="/" />
                    ) : (
                        <LinkButton fullWidth size={"large"} variant={"contained"} label="Login" to="/login" />
                    )}
                </div>
            </div>
        </AuthLayout>
    );
}
