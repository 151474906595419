import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../store";

export type Toast = {
    id: string,
    isOpen: boolean,
    color: 'success' | 'warning' | 'error',
    title?: string,
    message: string,
}

type ToastState = {
    toasts: Toast[],
}

// Define the initial state using that type
const initialState: ToastState = {
    toasts: [],
}

export const toastSlice = createSlice({
    name: 'toast',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        pushToast: (state, action: PayloadAction<Omit<Toast, 'id' | 'isOpen'>>) => {
            state.toasts.push({
                id: new Date().getTime().toString(),
                isOpen: true,
                ...action.payload
            })
        },
        hideToast: (state, action: PayloadAction<string>) => {
            const index = state.toasts.findIndex((toast) => toast.id === action.payload)
            if (index !== -1) {
                state.toasts[index].isOpen = false
            }
        },
        removeToast: (state, action: PayloadAction<string>) => {
            state.toasts = state.toasts.filter((toast) => toast.id !== action.payload)
        }
    }
})

export const { pushToast, hideToast, removeToast } = toastSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectToasts = (state: RootState) => state.toast.toasts

export default toastSlice.reducer