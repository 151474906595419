import classNames from "classnames";
import {forwardRef} from "react";
import {Link, LinkProps} from "react-router-dom";
import IconButtonBase, {IconButtonBaseProps} from "./IconButtonBase";

type Props = {
} & Omit<IconButtonBaseProps, 'disabled'> & LinkProps

const IconLinkButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
        to,
        ...rest
    } = props;

    return (
        <Link
            className={`rounded-full leading-none ${classNames({
            })}`}
            to={to}
        >
            <IconButtonBase {...rest} />
        </Link>
    )
})

export default IconLinkButton;