import {Popover} from '@headlessui/react'
import {Fragment} from "react";
import DatePicker from "./DatePicker";
import StyledPopoverPanel from "../../ui/StyledPopoverPanel";
import PopoverTransition from "../../ui/transitions/PopoverTransition";
import DatePickerField, {DatePickerFieldProps} from "./DatePickerField";


const PopoverDatePickerField = (props: DatePickerFieldProps) => {
    const {
        onChange,
        ...rest
    } = props;

    return (
        <Popover className="relative">
            {({ close, open }) => (
                <>
                    <Popover.Button className="focus:outline-none w-full">
                        <DatePickerField
                            onChange={onChange}
                            onClick={() => {}}
                            {...rest}
                        />
                    </Popover.Button>
                    {
                        open && (
                            <PopoverTransition>
                                <StyledPopoverPanel isStatic>
                                    <div className='p-3'>
                                        <DatePicker
                                            size='small'
                                            value={rest.value as string}
                                            onChange={date => {
                                                onChange(date)
                                                close()
                                            }}
                                        />
                                    </div>
                                </StyledPopoverPanel>
                            </PopoverTransition>
                        )
                    }
                </>
            )}
        </Popover>
    )
}

export default PopoverDatePickerField;