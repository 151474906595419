/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "3355ca56d0ef444090a31414bc88f779",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3355ca56d0ef444090a31414bc88f779",
            "region": "us-east-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "apilifecalendar",
            "endpoint": "https://3xch4ouav6.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:1932ce54-7124-481c-bd58-eef3e47a17b6",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Hywyj2PJa",
    "aws_user_pools_web_client_id": "3ls73ckvnq9n571ggtrgq2an5m",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "LifeCalendarEvent-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "lifecalendarlayer-prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "lifecalendar-media153620-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
