import {ReactNode} from "react";

type Props = {
    width: number,
    children: ReactNode,
}

export default function LifeCalendarContainer(props: Props) {
    const {
        width,
        children,
    } = props;

    return (
        <div className='flex flex-col items-center justify-center relative overflow-hidden aspect-[1/1.4142]' style={{
            width,
        }}>
            {children}
        </div>
    )
}