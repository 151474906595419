type Props = {
    children: string,
}

export default function SectionTitle(props: Props) {
    const {
        children,
    } = props

    return (
        <>
            <div className={`h-4 font-bold leading-none`}>{children}</div>
        </>
    )
}