import {labels} from "../../data/labels";
import FormField, {FormFieldProps} from "./components/FormField";

type PasswordFieldProps = {
    name?: string,
} & Omit<FormFieldProps, 'name'>

export default function PasswordField(props : PasswordFieldProps) {
    const {
        name = 'password',
        label = labels.fields.password.label,
        minLength = 8,
        maxLength = 256,
        ...rest
    } = props;

    return (
        <FormField
            name={name}
            label={label}
            type='password'
            minLength={minLength}
            maxLength={maxLength}
            errorMessages={{
                valueMissing: labels.fields.password.errors.valueMissing,
                tooShort: labels.fields.password.errors.tooShort,
                tooLong: labels.fields.password.errors.tooLong,
            }}
            {...rest}
        />
    )
}