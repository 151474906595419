import {CalendarColor} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";

interface Props {
    zoom: number,
    index: number,
    major: boolean,
    color?: CalendarColor,
}

export default function Tick(props: Props) {
    const {
        zoom,
        index,
        major,
        color = 'stone',
    } = props;

    return (
        <div className='flex items-center justify-center'
             style={{
                 width: zoom,
                 height: zoom,
             }}
        >
            <div
                className={`text-${color}-700 leading-none font-bold`}
                style={{fontSize: major ? zoom : zoom / 2}}
            >
                {zoom < 6 && !major ? '-' : (index + 1)}
            </div>
        </div>
    )
}