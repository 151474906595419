import {DateTime} from "luxon";

function dateTime2String(date?: DateTime): string {
    return date ? date.toISODate() as string : '';
}

function dateTime2LocalizedString(data?: string): string {
    return data ? DateTime.fromISO(data).toLocaleString(DateTime.DATE_FULL) : '';
}

export {dateTime2String, dateTime2LocalizedString}