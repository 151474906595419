import {LayerOutput} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import Tick from "./components/Tick";


interface Props {
    columns: number,
    layer?: LayerOutput,
    zoom: number,
}

export default function WeekAxis({columns, layer, zoom}: Props) {
    return (
        <div style={{
            marginBottom: zoom,
        }}>
            <div className="flex" style={{columnGap: zoom * 3}}>
                {
                    [
                        ...Array(columns),
                    ].map((value, columnIndex) => (
                        <div key={columnIndex} className='flex' style={{columnGap: zoom / 3}}>
                            {
                                [...Array(52 / columns)].map((value, weekIndex) => (
                                    <Tick
                                        zoom={zoom}
                                        key={weekIndex}
                                        index={(columnIndex * (52 / columns)) + weekIndex}
                                        major={(weekIndex + 1) % (52 / columns) === 0}
                                        color={layer?.color}
                                    />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}