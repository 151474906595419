import * as Form from '@radix-ui/react-form';
import {ReactNode} from "react";
import classNames from "classnames";


type Props = {
    fullWidth?: boolean,
    children: ReactNode,
}

export default function StyledForm(props: Props) {
    const {
        fullWidth,
        children,
    } = props

    return (
        <Form.Root className={`${classNames({
            'w-full': fullWidth,
            'w-96': !fullWidth,
        })}`}>
            {children}
        </Form.Root>
    )
}