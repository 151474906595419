import * as Form from '@radix-ui/react-form';
import IconButton from "../../components/ui/buttons/IconButton";
import {CheckIcon, Link2Icon, Share2Icon} from "@radix-ui/react-icons";
import StyledDialog from "../../components/dialogs/StyledDialog";
import {useEffect, useState} from "react";
import Box from "../../components/layouts/Box";
import Button from "../../components/ui/buttons/Button";
import Stack from "../../components/layouts/Stack";
import Typography from "../../components/ui/typography/Typography";
import {LayerOutput} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import classNames from "classnames";
import DialogTitle from "../../components/dialogs/DialogTitle";
import {User} from "../../redux/features/user/userSlice";
import {Analytics} from "aws-amplify";

export type ShareData = {
    birthdate?: string,
    calendarID?: string,
    layer?: LayerOutput,
    user?: Pick<User, 'name' | 'picture'>
}

export default function Share(props: ShareData) {
    const {
        layer,
        user,
    } = props;

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [buttonText, setButtonText] = useState<string>('Copy to clipboard');
    const [buttonColor, setButtonColor] = useState<'primary' | 'success'>('primary');

    useEffect(() => {
        if (isDialogOpen) {
            resetButton()
        }
    }, [isDialogOpen]);

    function resetButton() {
        setButtonText('Copy to clipboard')
        setButtonColor('primary')
    }

    function getLink() {
        const jsonString = JSON.stringify({
            ...props,
            user: layer?.displayUserCard ? {
                name: user?.name,
                picture: user?.picture,
            } : undefined,
        })
        const encoded = btoa(jsonString)
        return `${window.location.origin}/share/${encoded}`
    }

    async function handleCopy() {
        const link = getLink()
        await navigator.clipboard.writeText(link)
        setButtonText('Copied to clipboard')
        setButtonColor('success')

        Analytics.record({
            name: 'share',
            attributes: {
                calendarID: props.calendarID || '',
                layer: layer?.layerID || '',
                birthdate: props.birthdate || '',
            },
        })
    }

    function handleDialogOpen() {
        setIsDialogOpen(true)
    }
    function handleDialogClose(value: boolean) {
        setIsDialogOpen(value)
    }

    return (
        <>
            <IconButton
                variant={'outlined'}
                icon={<Share2Icon/>}
                tooltip='Share'
                onClick={handleDialogOpen}
                size={'large'}
            />
            <StyledDialog
                isOpen={isDialogOpen}
                setIsOpen={handleDialogClose}
                title={<DialogTitle layer={layer} title='Share' />}
                size={'medium'}
            >
                <Stack spacing='medium'>
                    <Typography variant={"h3"}>
                        Copy the link below and share your life with friends and family!
                    </Typography>
                    <Form.Root className='flex flex-col gap-6 sm:gap-8'>
                        <Box className={classNames(
                            'grow px-3 sm:px-4 py-2 sm:py-3 rounded-2xl bg-white break-all text-xs sm:text-sm cursor-text',
                            'border border-stone-300 hover:border-stone-400 focus:border-stone-400',
                            'h-20 lg:h-24 overflow-scroll'
                        )}>
                            {getLink()}
                        </Box>
                        <Box className=''>
                            <Button
                                fullWidth
                                size={'large'}
                                onClick={handleCopy}
                                color={buttonColor}
                                variant={'contained'}
                            >
                                <div className='flex items-center gap-2'>
                                    {
                                        buttonColor === 'success' ? (
                                            <CheckIcon/>
                                        ) : (
                                            <Link2Icon/>
                                        )
                                    }
                                    <div>
                                        {buttonText}
                                    </div>
                                </div>
                            </Button>
                        </Box>
                    </Form.Root>
                </Stack>
            </StyledDialog>
        </>
    )
}
