import * as Form from '@radix-ui/react-form';
import React, {FormEvent, useEffect, useRef, useState} from "react";
import {CalendarColor, LayerOutput} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {useCreateLayerMutation, useUpdateLayerMutation} from "../../redux/features/layers/layersSlice";
import StyledDialog from "../../components/dialogs/StyledDialog";
import FormField from "../../components/form/components/FormField";
import {FormFieldset} from "../../components/form/FormFieldset";
import ColorPicker from "./components/ColorPicker";
import StyledSwitch from "../../components/form/StyledSwitch";
import {ButtonGroup} from "../../components/ui/buttons/ButtonGroup";
import {labels} from "../../data/labels";
import Button from "../../components/ui/buttons/Button";
import FormContent from "../../components/form/FormContent";
import {StyledFormMessage} from "../../components/form/components/StyledFormMessage";
import {LayersIcon} from "@radix-ui/react-icons";


type Props = {
    layer?: LayerOutput,
    open: boolean,
    setIsOpen: (open: boolean) => void,
    defaultLayer?: LayerOutput,
}

export default function EditLayerDialog(props: Props) {
    const {
        layer,
        open,
        setIsOpen,
        defaultLayer,
    } = props;

    const nameInputRef = useRef(null);
    const [name, setName] = useState('');
    const [color, setColor] = useState<CalendarColor>(layer ? layer.color : 'stone');
    const [isDefault, setIsDefault] = useState<boolean>(layer ? layer.isDefault : false);
    const [displayLabel, setDisplayLabel] = useState<boolean>(layer ? layer.displayLabel : false);
    const [displayUserCard, setDisplayUserCard] = useState<boolean>(layer ? layer.displayUserCard : false);
    const [displayLeftColumn, setDisplayLeftColumn] = useState<boolean>(layer ? layer.displayLeftColumn : false);

    const [createNewLayer] = useCreateLayerMutation()
    const [updateLayer] = useUpdateLayerMutation()

    useEffect(() => {
        if (open) {
            setName(layer ? layer?.name : '');
            setColor(layer ? layer.color : 'stone')
            setIsDefault(layer ? layer.isDefault : false);
            setDisplayLabel(layer ? layer.displayLabel : false);
            setDisplayUserCard(layer ? layer.displayUserCard : false);
            setDisplayLeftColumn(layer ? layer.displayLeftColumn : false);
        }
    }, [layer, open]);

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();

        if (defaultLayer && isDefault && defaultLayer.layerID !== layer?.layerID) {
            const {layerID, ...patch} = defaultLayer

            const params = {
                layerID: defaultLayer.layerID,
                patch: {
                    ...patch,
                    isDefault: false,
                }
            }

            updateLayer(params)
        }

        try {
            if (layer) {
                updateLayer({
                    layerID: layer.layerID,
                    patch: {
                        name,
                        isDefault,
                        isPublic: false,
                        displayLabel,
                        displayUserCard,
                        displayLeftColumn,
                        color,
                    }
                })
            } else {
                createNewLayer({
                    layer: {
                        layerID: Math.random().toString(36).substring(2, 9),
                        name,
                        isDefault,
                        isPublic: false,
                        displayLabel,
                        displayUserCard,
                        displayLeftColumn,
                        color,
                    }
                })
            }
        } catch (err) {
            console.error('Failed to save/update the layer: ', err);
            // setError('An error occurred');
        }

        setIsOpen(false);
    }

    function handleClearServerErrors() {
    }

    function handleNameChange(event: FormEvent<HTMLInputElement>) {
        setName(event.currentTarget.value)
    }

    function handleOnCancel() {
        setIsOpen(false)
    }

    return (
        <StyledDialog
            isOpen={open}
            setIsOpen={setIsOpen}
            title={(
                <div className='flex gap-2 items-center text-sm sm:text-base lg:text-lg'>
                    <LayersIcon />
                    {layer ? labels.modals.layer.update.title : labels.modals.layer.new.title}
                </div>
            )}
            initialFocus={nameInputRef}
        >
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    <FormFieldset>
                        <FormField
                            ref={nameInputRef}
                            label={labels.fields.layer.name.label}
                            name='name'
                            type='text'
                            value={name}
                            onChange={handleNameChange}
                            required
                            maxLength={256}
                        />
                        <Form.Field name='color'>
                            <ColorPicker
                                name='color'
                                label={labels.fields.layer.color.label}
                                value={color}
                                onValueChange={(value) => setColor(value)}
                            />
                        </Form.Field>
                        <StyledSwitch
                            name={'isDefault'}
                            label={labels.fields.layer.isDefault.label}
                            message={(defaultLayer && (defaultLayer.layerID !== layer?.layerID) && isDefault) && (
                                <StyledFormMessage forceMatch severity={'warning'}>
                                    {labels.modals.layer.new.defaultLayerWarning}
                                </StyledFormMessage>
                            )}
                            description={labels.fields.layer.isDefault.description}
                            isChecked={isDefault}
                            setIsChecked={(enabled) => setIsDefault(enabled)}
                        />
                        <StyledSwitch
                            name={'displayLabel'}
                            label={labels.fields.layer.displayLabel.label}
                            description={labels.fields.layer.displayLabel.description}
                            isChecked={displayLabel}
                            setIsChecked={(enabled) => setDisplayLabel(enabled)}
                        />
                        <StyledSwitch
                            name={'displayUserCard'}
                            label={labels.fields.layer.displayUserCard.label}
                            description={labels.fields.layer.displayUserCard.description}
                            isChecked={displayUserCard}
                            setIsChecked={(enabled) => setDisplayUserCard(enabled)}
                        />
                        <StyledSwitch
                            name={'displayLeftColumn'}
                            label={labels.fields.layer.displayLeftColumn.label}
                            description={labels.fields.layer.displayLeftColumn.description}
                            isChecked={displayLeftColumn}
                            setIsChecked={(enabled) => setDisplayLeftColumn(enabled)}
                        />
                    </FormFieldset>
                    <ButtonGroup>
                        <div className='basis-1/3'>
                            <Button
                                fullWidth
                                variant='text'
                                onClick={handleOnCancel}
                                label={labels.buttons.cancel}
                            />
                        </div>
                        <Form.Submit asChild>
                            <div className='basis-1/3'>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    label={ layer ? labels.buttons.update : labels.buttons.add }
                                />
                            </div>
                        </Form.Submit>
                    </ButtonGroup>
                </FormContent>
            </Form.Root>
        </StyledDialog>
    )
}