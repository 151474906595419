import {FormFieldProps} from "./FormField";
import classNames from "classnames";
import {ReactNode} from "react";

type Props = {
    children: ReactNode,
} & Pick<FormFieldProps, 'justify'>

export default function FormFieldHelper(props: Props) {
    const {
        children,
        justify = 'end',
    } = props

    return (
        <div
            data-component={'FormFieldHelper'}
            className={classNames(
                'text-xs mt-2 font-normal px-3',
                classNames({
                    'text-left': justify === 'start',
                    'text-center': justify === 'center',
                    'text-right': justify === 'end',
                })
            )}
        >
            {children}
        </div>
    )
}