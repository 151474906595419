import {Link, LinkProps} from "react-router-dom";

type Props = LinkProps & {
    variant?: 'text' | 'outlined'
    fullWidth?: boolean,
}

export default function StyledLink(props: Props) {
    const {
        children,
        ...rest
    } = props;

    return (
        <>
            <Link className='underline-offset-2 hover:underline' {...rest}>
                { children }
            </Link>
        </>
    )
}