import FormMessage from "./FormMessage";

type Props = {
    formError?: string,
    formSuccess?: string,
    onClose?: () => void,
}

export default function MessageBanner(props: Props) {
    const {
        formError,
        formSuccess,
        onClose,
    } = props

    if (!formError && !formSuccess) {
        return null;
    }

    return (
        <div className='flex flex-col gap-4'>
            {
                formError && (
                    <FormMessage severity={'error'} onClose={onClose}>{formError}</FormMessage>
                )
            }
            {
                formSuccess && (
                    <FormMessage severity={'success'} onClose={onClose}>{formSuccess}</FormMessage>
                )
            }
        </div>
    )
}