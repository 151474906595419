import {LayerOutput} from "../../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {ColorClasses} from "../../data/ColorClasses";
import classNames from "classnames";
import LayerCardContainer from "./LayerCardContainer";
import {AvatarIcon, DotsVerticalIcon, DownloadIcon, LayersIcon, Pencil1Icon, TextIcon, TrashIcon} from "@radix-ui/react-icons";
import {Popover} from "@headlessui/react";
import StyledPopoverPanel from "../../../../components/ui/StyledPopoverPanel";
import PopoverTransition from "../../../../components/ui/transitions/PopoverTransition";
import React, {MouseEvent, ReactNode} from "react";
import IconButtonBase from "../../../../components/ui/buttons/IconButtonBase";
import {labels} from "../../../../data/labels";
import Sync from "../../../../components/ui/Sync";
import StyledTooltip from "../../../../components/StyledTooltip";


type Props = {
    layer: LayerOutput
    onSelectedChange: (layer: LayerOutput) => void,
    selected?: boolean,
    onDeleteClick?: (e: MouseEvent<HTMLButtonElement>, layer: LayerOutput) => void;
    onEditClick?: (e: MouseEvent<HTMLButtonElement>, layer: LayerOutput) => void;
    onImportClick?: (e: MouseEvent<HTMLButtonElement>, layer: LayerOutput) => void;
    isPending?: boolean,
}

export default function LayerCard(props: Props) {
    const {
        layer,
        onSelectedChange,
        selected,
        onDeleteClick,
        onEditClick,
        onImportClick,
        isPending,
    } = props;

    return (
        <div
            className="cursor-pointer"
            onClick={() => onSelectedChange(layer)}
        >
            <LayerCardContainer extraClasses={`bg-white group shadow-md border border-stone-400 opacity-90 hover:opacity-100 ${classNames({
                'ring-2 ring-current ring-offset-4': selected,
            })}`}>
                <div className={`flex-none mr-2 w-4 h-4 rounded-full ${ColorClasses[layer.color + '-lived']}`}/>
                <div className='grow flex flex-col gap-1 sm:gap-2 justify-center items-start'>
                    <div className='text-xs xs:text-sm sm:text-xs md:text-sm lg:text-base line-clamp-2 leading-tight'>
                        <div className='flex items-center gap-4'>
                            <div className='flex items-baseline gap-1'>
                                {layer.name}
                                {
                                    layer.isDefault && (
                                        <div className='text-xxs sm:text-xs text-stone-500'>(default)</div>
                                    )
                                }
                            </div>
                            {
                                (isPending && selected) && (
                                    <Sync size='medium'/>
                                )
                            }
                        </div>
                    </div>
                    <div className='flex items-center gap-1'>
                        {
                            layer.displayLeftColumn && (
                                <StyledTooltip toolTip={'Layer name displayed'} side='bottom'>
                                    <div className='p-0.5 sm:p-1.5'><LayersIcon /></div>
                                </StyledTooltip>
                            )
                        }
                        {
                            layer.displayLabel && (
                                <StyledTooltip toolTip={'Label displayed'} side='bottom'>
                                    <div className='p-0.5 sm:p-1.5'><TextIcon /></div>
                                </StyledTooltip>
                            )
                        }
                        {
                            layer.displayUserCard && (
                                <StyledTooltip toolTip={'Profile card displayed'} side='bottom'>
                                    <div className='p-0.5 sm:p-1.5'><AvatarIcon /></div>
                                </StyledTooltip>
                            )
                        }
                    </div>
                </div>
                <div className='flex-none'>
                    <div className='lg:hidden lg:group-hover:flex flex-none gap-x-1'>
                        <Popover className='relative leading-none'>
                            <Popover.Button className='rounded-full'>
                                <IconButtonBase
                                    size={'small'}
                                    variant='text'
                                    icon={<DotsVerticalIcon/>}
                                />
                            </Popover.Button>
                            <PopoverTransition>
                                <StyledPopoverPanel size='small'>
                                    <div className='py-2 flex flex-col items-center justify-start gap-x-4 text-sm'>
                                        {
                                            onEditClick && (
                                                <MenuButton
                                                    onClick={e => onEditClick(e, layer)}
                                                    icon={<Pencil1Icon/>}
                                                >
                                                    {labels.buttons.edit}
                                                </MenuButton>
                                            )
                                        }
                                        {
                                            onDeleteClick && (
                                                <MenuButton
                                                    onClick={e => onDeleteClick(e, layer)}
                                                    icon={<TrashIcon/>}
                                                >
                                                    {labels.buttons.delete}
                                                </MenuButton>
                                            )
                                        }
                                        {
                                            onImportClick && (
                                                <MenuButton
                                                    onClick={e => onImportClick(e, layer)}
                                                    icon={<DownloadIcon/>}
                                                >
                                                    {labels.buttons.import}
                                                </MenuButton>
                                            )
                                        }
                                    </div>
                                </StyledPopoverPanel>
                            </PopoverTransition>
                        </Popover>
                    </div>
                </div>
            </LayerCardContainer>
        </div>
    )
}

type MenuButtonProps = {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void,
    children: string,
    icon: ReactNode,
}

const MenuButton = (props: MenuButtonProps) => {
    const {
        onClick,
        icon,
        children,
    } = props;

    return (
        <button
            className='flex justify-between gap-x-2 items-center px-4 py-3 w-full hover:bg-stone-100 text-xs text-left leading-none'
            onClick={onClick}
        >
            <div>
                {children}
            </div>
            <div className="w-3 aspect-square flex items-center">
                {icon}
            </div>
        </button>
    )
}