import * as Tooltip from '@radix-ui/react-tooltip';
import {PopperContentProps} from '@radix-ui/react-tooltip';
import React, {ReactNode, useState} from "react";

type Props = {
    children: ReactNode;
    toolTip?: ReactNode;
} & Pick<PopperContentProps, 'side'>

export default function StyledTooltip(props: Props) {
    const {
        children,
        toolTip,
        side,
    } = props;

    const [open, setOpen] = useState(false)

    if (!toolTip) {
        return (<>{children}</>)
    }

    return (
        <Tooltip.Root
            open={open}
            onOpenChange={setOpen}
        >
            <Tooltip.Trigger asChild>
                {children}
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    sideOffset={5}
                    avoidCollisions={true}
                    className='z-10 leading-none bg-stone-900 text-white px-3 py-2 rounded-full text-xs font-medium'
                    side={side}
                >
                    {toolTip}
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    )
}