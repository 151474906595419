import {useState} from "react";
import DatePicker from "./DatePicker";
import StyledDialog from "../../dialogs/StyledDialog";
import DatePickerField, {DatePickerFieldProps} from "./DatePickerField";


export default function DialogDatePickerField(props: DatePickerFieldProps) {
    const {
        onChange,
        maxDate,
        size = 'medium',
        ...rest
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    async function onChangeHandler(date: string) {
        if (!date) return;
        setIsOpen(false);
        onChange(date);
    }

    return (
        <>
            <DatePickerField
                onChange={onChange}
                size={size}
                onClick={() => setIsOpen(true)}
                {...rest}
            />

            <StyledDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                size={size}
                disableHeader={true}
                disablePadding={true}
            >
                <DatePicker
                    value={rest.value}
                    onChange={onChangeHandler}
                    size={size}
                    {...rest}
                />
            </StyledDialog>
        </>
    )
}