import * as Form from "@radix-ui/react-form";
import {StyledFormMessage} from "./StyledFormMessage";
import {labels} from "../../../data/labels";
import {ErrorMessages, ValidationFunction} from "./FormField";
import {ReactNode} from "react";
import {ComponentSize} from "../../types";
import classNames from "classnames";

const defaultErrorMessages : ErrorMessages = {
    valueMissing: labels.fields.validation.valueMissing,
    tooShort: labels.fields.validation.tooShort,
    tooLong: labels.fields.validation.tooLong,
    patternMismatch: labels.fields.validation.patternMismatch,
    typeMismatch: labels.fields.validation.typeMismatch,
    badInput: labels.fields.validation.badInput,
    rangeOverflow: labels.fields.validation.rangeOverflow,
    rangeUnderflow: labels.fields.validation.rangeUnderflow,
    stepMismatch: labels.fields.validation.stepMismatch,
    customError: labels.fields.validation.customError,
}

type Props = {
    label?: ReactNode,
    errorMessages?: ErrorMessages,
    serverError?: string,
    validate?: ValidationFunction,
    size?: ComponentSize,
}

export default function FormFieldHeader(props: Props) {
    const {
        label,
        errorMessages,
        serverError,
        validate,
        size = 'medium',
    } = props;

    return (
        <div className='flex items-center justify-between'>
            {
                label && (
                    <Form.Label className={`font-medium pl-3 ${classNames({
                        'text-xxs': size === 'small',
                        'text-xs': size === 'medium' || size === 'large',
                    })}`}>
                        { label }
                    </Form.Label>
                )
            }
            <StyledFormMessage match='valueMissing'>
                { errorMessages?.valueMissing || defaultErrorMessages.valueMissing }
            </StyledFormMessage>
            <StyledFormMessage match='tooShort'>
                { errorMessages?.tooShort || defaultErrorMessages.tooShort }
            </StyledFormMessage>
            <StyledFormMessage match='tooLong'>
                { errorMessages?.tooLong || defaultErrorMessages.tooLong }
            </StyledFormMessage>
            <StyledFormMessage match='patternMismatch'>
                { errorMessages?.patternMismatch || defaultErrorMessages.patternMismatch }
            </StyledFormMessage>
            <StyledFormMessage match='typeMismatch'>
                { errorMessages?.typeMismatch || defaultErrorMessages.typeMismatch }
            </StyledFormMessage>
            <StyledFormMessage match='badInput'>
                { errorMessages?.badInput || defaultErrorMessages.badInput }
            </StyledFormMessage>
            <StyledFormMessage match='rangeOverflow'>
                { errorMessages?.rangeOverflow || defaultErrorMessages.rangeOverflow }
            </StyledFormMessage>
            <StyledFormMessage match='rangeUnderflow'>
                { errorMessages?.rangeUnderflow || defaultErrorMessages.rangeUnderflow }
            </StyledFormMessage>
            <StyledFormMessage match='stepMismatch'>
                { errorMessages?.stepMismatch || defaultErrorMessages.stepMismatch }
            </StyledFormMessage>
            {
                validate && (
                    <StyledFormMessage match={validate}>
                        { errorMessages?.customError || defaultErrorMessages.customError }
                    </StyledFormMessage>
                )
            }
            {serverError && (<StyledFormMessage>{ serverError }</StyledFormMessage>)}
        </div>    )
}