import {forwardRef, ReactNode} from "react";
import {Popover} from "@headlessui/react";
import {ComponentProps} from "../types";
import classNames from "classnames";

type Props = {
    children: ReactNode;
    isStatic?: boolean;
    topClassName?: string;
} & ComponentProps

const StyledPopoverPanel = forwardRef<any, Props>((props, ref) => {
    const {
        children,
        isStatic = false,
        size = 'medium',
        topClassName = '',
    } = props;

    return (
        <Popover.Panel static={isStatic} ref={ref} className={`absolute z-10 transform right-0 ${topClassName}`}>
            <div className={`mt-2 ${classNames({
                'w-36': size === 'small',
                'w-68': size === 'medium',
                'w-72': size === 'large',
            })}`}>
                <div className='bg-white rounded shadow-xl border border-stone-200'>
                    {children}
                </div>
            </div>
        </Popover.Panel>
    )
})

export default StyledPopoverPanel;