import FormField, {FormFieldProps, getFormInputClasses} from "../components/FormField";
import {labels} from "../../../data/labels";
import classNames from "classnames";
import {DateTime} from "luxon";
import Sync from "../../ui/Sync";

export type DatePickerFieldProps = {
    name?: string,
    onChange: (date: string) => void,
    onClick?: () => void,
    maxDate?: Date;
    value?: string;
} & Omit<FormFieldProps, 'name' | 'onChange' | 'onClick' | 'value'>

const DatePickerField = (props: DatePickerFieldProps) => {
    const {
        name = 'date',
        size = 'medium',
        onChange,
        onClick,
        value,
        ...rest
    } = props;

    const localizedDate = value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL) : ''

    return (
        <div className="relative">
            <FormField
                name={name}
                size={size}
                onChange={(event) => onChange(event.target.value)}
                maxLength={10}
                pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                errorMessages={{
                    patternMismatch: labels.fields.date.validation.patternMismatch,
                }}
                value={value}
                {...rest}
            />
            {
                onClick && (
                    <div className={classNames(
                        'absolute inset-x-0 bottom-0',
                    )}>
                        <div
                            className={`cursor-pointer ${getFormInputClasses({
                                size: size,
                            }, !rest.serverError)}`}
                            onClick={onClick}
                        >
                            <div className={`flex h-full items-center ${classNames({
                                'justify-center': rest.justify === 'center',
                            })}`}>
                                {
                                    localizedDate ? (
                                        <span className="text-sm">{localizedDate}</span>
                                    ) : (
                                        <span className="text-xs text-stone-400">{rest.placeholder}</span>
                                    )
                                }
                                {
                                    rest.inProgress && (
                                        <div className="flex items-center justify-center absolute right-0 w-12">
                                            <Sync size='small'/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {rest.helper}
                    </div>
                )
            }
        </div>
    )
}

export default DatePickerField