import classNames from "classnames";
import {Cross1Icon} from "@radix-ui/react-icons";
import IconButton from "../ui/buttons/IconButton";
import Paper from "../layouts/Paper";
import {Severity} from "../types";
import {ReactNode} from "react";

type Props = {
    severity: Severity,
    children: ReactNode,
    onClose?: () => void,
}

export default function FormMessage(props : Props) {
    const {
        severity,
        children,
        onClose,
    } = props

    return (
        <div className={`rounded border-l-4 text-xs sm:text-sm ${classNames({
            'border-rose-600 text-rose-600': severity === 'error',
            'border-emerald-600 text-emerald-600': severity === 'success',
            'border-amber-600 text-amber-600': severity === 'warning',
        })}`}>
            <Paper>
                <div className='px-3 py-2.5 flex justify-between items-center gap-4'>
                    <div className=''>{children}</div>
                    {
                        onClose && (
                            <IconButton
                                size='small'
                                variant='text'
                                onClick={onClose}
                                color={severity}
                                icon={<Cross1Icon className={classNames({
                                    'text-rose-700': severity === 'error',
                                    'text-emerald-700': severity === 'success',
                                    'text-amber-700': severity === 'warning'
                                })}/>}
                            />
                        )
                    }
                </div>
            </Paper>
        </div>
    )
}