import * as Form from '@radix-ui/react-form';
import {ComponentPropsWithoutRef, forwardRef, ReactNode} from "react";
import classNames from "classnames";
import FormFieldHeader from "./FormFieldHeader";
import {ComponentProps} from "../../types";
import Sync from "../../ui/Sync";

export type ErrorMessages = {
    valueMissing?: string,
    tooShort?: string,
    tooLong?: string,
    patternMismatch?: string,
    typeMismatch?: string,
    badInput?: string,
    rangeOverflow?: string,
    rangeUnderflow?: string,
    stepMismatch?: string,
    customError?: string,
}

export type ValidationFunction = (value: string, formData: FormData) => boolean

export type FormFieldProps = {
    name: string,
    label?: ReactNode,
    helper?: ReactNode,
    errorMessages?: ErrorMessages,
    serverError?: string,
    validate?: ValidationFunction,
    endAdornment?: ReactNode,
    buttonGroup?: ReactNode,
    headerGap?: 'sm' | 'md',
    inProgress?: boolean,
    justify?: 'start' | 'center' | 'end',
} & ComponentProps & Omit<ComponentPropsWithoutRef<'input'>, 'size'>

export function getFormInputClasses(props: Pick<FormFieldProps, 'size' | 'disabled'>, isValid: boolean = true): string {
    const {
        size = 'medium',
        disabled,
    } = props;
    return `px-3 w-full rounded-2xl appearance-none focus:outline-none placeholder:text-stone-400 ${classNames({
        'text-sm h-8': size === 'small',
        'text-base h-10': size === 'medium',
        'text-base h-12': size === 'large',
        'text-base h-14': size === 'x-large',
        'bg-white text-stone-950 border border-stone-300 hover:border-stone-400 focus:border-stone-400': isValid && !disabled,
        'bg-rose-50 border border-rose-300 hover:border-rose-400 focus:border-rose-400': !isValid,
        'bg-stone-50 text-stone-500 border border-stone-300 cursor-not-allowed': disabled,
    })}`
}

const FormField = forwardRef<HTMLInputElement, FormFieldProps>((props, ref) => {
    const {
        name,
        label,
        helper,
        errorMessages,
        serverError,
        validate,
        endAdornment,
        headerGap = 'sm',
        size = 'medium',
        inProgress,
        ...rest
    } = props;

    return (
        <Form.Field name={name} serverInvalid={!!serverError} data-component='FormField' data-size={size}>
            <div className='flex flex-col gap-y-2 sm:flex-row items-end justify-between gap-x-4'>
                <div className='w-full'>
                    <div className={classNames({
                        'mb-2': headerGap === 'sm' && label,
                        'mb-4': headerGap === 'md' && label,
                    })}>
                        <FormFieldHeader
                            label={label}
                            errorMessages={errorMessages}
                            serverError={serverError}
                            validate={validate}
                            size={size}
                        />
                    </div>
                    <Form.ValidityState>
                        {(validity) => (
                            <div className='transition-colors duration-100 relative'>
                                <Form.Control asChild>
                                    <input
                                        ref={ref}
                                        className={getFormInputClasses(props, (!((validity && !validity.valid) || !!serverError)))}
                                        {...rest}
                                    />
                                </Form.Control>
                                {
                                    endAdornment && (
                                        <div className='absolute w-12 inset-y-0 right-0 flex items-center justify-center'>
                                            {endAdornment}
                                        </div>
                                    )
                                }
                                {
                                    inProgress && (
                                        <div className='absolute w-12 inset-y-0 right-8 flex items-center justify-center'>
                                            <Sync />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </Form.ValidityState>
                </div>
            </div>
            {helper}
        </Form.Field>
    )
})

export default FormField;
