import { Buffer } from "buffer";

type Payload = {
    email: string;
    userName: string;
};

export function getPayloadData(payload: string): Payload | undefined {
    if (payload) {
        try {
            const decodedPayload = Buffer.from(payload, "base64").toString("utf8");

            if (decodedPayload) {
                return JSON.parse(decodedPayload);
            }
        } catch (error) {
            console.error("Error parsing payload: ", error);
        }
    }
}
