import {Dialog, Transition} from '@headlessui/react'
import {Fragment, RefObject} from "react";
import StyledDialogPanel, {StyledDialogPanelProps} from "./StyledDialogPanel";

type Props = {
    isOpen: boolean,
    initialFocus?: RefObject<any>,
} & StyledDialogPanelProps

export default function StyledDialog(props: Props) {
    const {
        isOpen,
        setIsOpen,
        initialFocus,
        children,
        ...rest
    } = props;

    return <Transition appear show={isOpen} as={Fragment}>
        <Dialog
            as='div'
            onClose={() => setIsOpen(false)}
            className='relative z-50'
            initialFocus={initialFocus}
        >
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-stone-950 bg-opacity-40"/>
            </Transition.Child>
            <div className='fixed inset-0 overflow-y-auto'>
                <div className="flex min-h-full items-center justify-center text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-75"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className='max-h-screen my-4 sm:my-6 md:my-8 lg:my-10'>
                            <StyledDialogPanel
                                setIsOpen={setIsOpen}
                                {...rest}
                            >
                                {children}
                            </StyledDialogPanel>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
}