import * as Form from "@radix-ui/react-form";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FormContent from "../../../components/form/FormContent";
import { FormFieldset } from "../../../components/form/FormFieldset";
import FormMessage from "../../../components/form/FormMessage";
import VerificationCodeField from "../../../components/form/VerificationCodeField";
import Button from "../../../components/ui/buttons/Button";
import { ButtonGroup } from "../../../components/ui/buttons/ButtonGroup";
import { labels } from "../../../data/labels";
import { AuthLayout } from "../../../features/authentication";
import { AlreadyAMemberLink } from "../../../features/authentication/components/AlreadyMemberLink";
import { extractServerError } from "../../../redux/features/api/serverErrorsHelper";
import { useCreateLayerMutation } from "../../../redux/features/layers/layersSlice";
import { useConfirmSignUpMutation, useResendSignUpMutation } from "../../../redux/features/user/userSlice";
import { getPayloadData } from "../../../utils/searchParamsUtils";

export default function SignUpConfirm() {
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [resendVerificationCodeInProgress, setResendVerificationCodeInProgress] = useState(false);
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationCodeServerError, setVerificationCodeServerError] = useState("");
    const [searchParams] = useSearchParams();

    const [confirmSignUp] = useConfirmSignUpMutation();
    const [resendSignUp] = useResendSignUpMutation();
    const [createNewLayer] = useCreateLayerMutation();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state || !location.state.email) {
            const searchParamPayload = searchParams.get("payload");
            const searchParamCode = searchParams.get("code");

            if (searchParamPayload && searchParamCode) {
                try {
                    const payload = getPayloadData(searchParamPayload);

                    if (payload) {
                        confirmAccount(payload.email, searchParamCode, payload.userName);
                    }
                } catch (error) {
                    console.error("Error parsing payload: ", error);
                }
            }
        } else {
            setEmail(location.state.email);
        }
    }, [location.state, navigate, searchParams]);

    async function handleSubmit(e: FormEvent) {
        console.log("handleSubmit", email, verificationCode, location.state.calendarID);
        e.preventDefault();

        await confirmAccount(email, verificationCode, location.state.calendarID);
    }

    async function confirmAccount(email: string, verificationCode: string, calendarID: string) {
        console.log("confirmAccount", email, verificationCode, calendarID);
        setInProgress(true);
        try {
            await confirmSignUp({ email, verificationCode }).unwrap();

            if (calendarID) {
                await createNewLayer({
                    layer: {
                        layerID: Math.random().toString(36).substring(2, 9),
                        name: "Your life in weeks",
                        isDefault: true,
                        isPublic: false,
                        displayLabel: false,
                        displayUserCard: false,
                        displayLeftColumn: false,
                        color: "stone",
                    },
                    calendarID,
                }).unwrap();
            }

            return navigate("../success");
        } catch (error: any) {
            const myError = extractServerError(error);
            console.error("Error confirming account: ", myError);

            switch (myError.code) {
                case "CodeMismatchException":
                    setVerificationCodeServerError(labels.fields.serverErrors.CodeMismatchException);
                    break;
                case "NotAuthorizedException":
                    setFormError(myError.reason || labels.errors.unknown);
                    break;
                default:
                    setFormError(labels.errors.unknown);
                    break;
            }
        } finally {
            setInProgress(false);
        }
    }

    async function handleResendVerificationCode() {
        try {
            setResendVerificationCodeInProgress(true);
            await resendSignUp({ email }).unwrap();
            setFormSuccess(`We sent a verification code to your email: ${email}`);
        } catch (error: any) {
            const myError = extractServerError(error);

            if (myError.reason) {
                setFormError(myError.reason);
            } else {
                setFormError(labels.errors.unknown);
            }
        }

        setResendVerificationCodeInProgress(false);
    }

    function handleClearServerErrors() {
        setVerificationCodeServerError("");
        setFormError("");
        setFormSuccess("");
    }

    function handleVerificationCodeChange(event: ChangeEvent<HTMLInputElement>) {
        setVerificationCode(event.currentTarget.value);
    }

    return (
        <AuthLayout
            headerLink={<AlreadyAMemberLink />}
            title={labels.pages.auth.signUpConfirm.title}
            subTitle={labels.pages.auth.signUpConfirm.subtitle}
            banner={
                <div className="flex flex-col gap-4">
                    {formError && (
                        <FormMessage severity={"error"} onClose={handleClearServerErrors}>
                            {formError}
                        </FormMessage>
                    )}
                    {formSuccess && (
                        <FormMessage severity={"success"} onClose={handleClearServerErrors}>
                            {formSuccess}
                        </FormMessage>
                    )}
                </div>
            }
        >
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    <FormFieldset>
                        <VerificationCodeField
                            value={verificationCode}
                            onChange={handleVerificationCodeChange}
                            serverError={verificationCodeServerError}
                            onHelperClick={handleResendVerificationCode}
                            helpActionInProgress={resendVerificationCodeInProgress}
                            size={"large"}
                        />
                    </FormFieldset>
                    <ButtonGroup>
                        <Form.Submit asChild>
                            <div className="flex basis-full sm:basis-1/2 justify-end">
                                <Button
                                    fullWidth
                                    size={"large"}
                                    type="submit"
                                    variant="contained"
                                    inProgress={inProgress}
                                    label={labels.pages.auth.signUpConfirm.button}
                                />
                            </div>
                        </Form.Submit>
                    </ButtonGroup>
                </FormContent>
            </Form.Root>
        </AuthLayout>
    );
}
