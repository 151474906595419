import * as Form from '@radix-ui/react-form';
import {useParams} from "react-router-dom";
import LifeCalendar from "../features/life-calendar/LifeCalendar";
import Share, {ShareData} from "./home/Share";
import {useGetEventsByCalendarIDAndLayerIDQuery} from "../redux/features/events/eventsSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import Container from "../features/home/components/Container";
import CalendarZoomSettings from "./home/CalendarZoomSettings";
import LifeCalendarSkeleton from "../features/life-calendar/components/LifeCalendarSkeleton";
import {useState, useTransition} from "react";
import {getDefaultZoom} from "../utils/getFitCalendarZoom";
import PrintSection from "./home/PrintSection";
import LifeCalendarWrapper from "../features/life-calendar/LifeCalendarWrapper";
import DialogDatePickerField from "../components/form/date-picker/DialogDatePickerField";


const DEFAULT_ZOOM = getDefaultZoom()

export default function ShareRoute() {
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM)
    const { data = '' } = useParams()
    const decoded = atob(data)
    const shareData = JSON.parse(decoded) as ShareData
    const [isPending, startTransition] = useTransition()

    const {
        birthdate: sharedBirthdate,
        calendarID,
        layer,
        user,
    } = shareData

    const [birthdate, setBirthdate] = useState<string>(sharedBirthdate || '')

    const {
        data: eventsResponse,
        isLoading: isEventsLoading,
        isFetching: isEventsFetching,
    } = useGetEventsByCalendarIDAndLayerIDQuery((calendarID && layer) ? {
        calendarID, layerID: layer.layerID,
    } : skipToken);

    function handleOnBirthdateChanged(birthdate: string) {
        setTimeout(() => {
            startTransition(() => {
                setBirthdate(birthdate)
            })
        }, 10)
    }


    return (
        <div className='my-12 flex flex-col'>
            <Container className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between max-w-prose">
                <Form.Root className='grow w-full'>
                    <DialogDatePickerField
                        value={birthdate}
                        onChange={handleOnBirthdateChanged}
                        placeholder={'Select your birthdate'}
                        maxDate={new Date()}
                        justify={'center'}
                        inProgress={isPending}
                        size='large'
                    />
                </Form.Root>

                <div className='flex gap-2 justify-center'>
                    <CalendarZoomSettings zoom={zoom} setZoom={setZoom}/>
                    <PrintSection layer={layer} birthdate={birthdate}/>
                    <Share
                        layer={layer}
                        birthdate={birthdate}
                        calendarID={calendarID}
                    />
                </div>
            </Container>
            <LifeCalendarWrapper>
                {
                    (isEventsFetching || isEventsLoading) ? (
                        <LifeCalendarSkeleton zoom={zoom}/>
                    ) : (
                        <LifeCalendar
                            zoom={zoom}
                            displayCurrentWeek
                            layer={layer}
                            birthdate={birthdate}
                            events={eventsResponse?.data}
                            readonly
                            userName={user?.name}
                            avatarS3Key={user?.picture}
                        />
                    )
                }
            </LifeCalendarWrapper>
        </div>
    )
}