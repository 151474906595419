import SectionTitle from "./components/SectionTitle";
import Button from "../../components/ui/buttons/Button";
import SectionPanel from "./components/SectionPanel";
import Section from "./components/Section";
import SectionGroup from "./components/SectionGroup";
import SectionPanelTitle from "./components/SectionPanelTitle";

export default function ButtonsDemo() {
    return (
        <SectionGroup>
            <SectionTitle>Buttons</SectionTitle>
            <Section>
                <SectionPanelTitle>Color Primary</SectionPanelTitle>
                <SectionPanel>
                    <Button
                        color='primary'
                        variant='text'
                        label='text'
                        size='small'
                    />
                    <Button
                        color='primary'
                        variant='outlined'
                        label='outlined'
                        size='small'
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        label='contained'
                        size='small'
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color='primary'
                        variant='text'
                        label='text'
                        size='small'
                        inProgress={true}
                    />
                    <Button
                        color='primary'
                        variant='outlined'
                        label='outlined'
                        size='small'
                        inProgress={true}
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        label='contained'
                        size='small'
                        inProgress={true}
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color={'primary'}
                        variant='text'
                        label='text'
                        type='button'
                        hidden={false}
                    />
                    <Button
                        color={'primary'}
                        variant='outlined'
                        label='outlined'
                    />
                    <Button
                        color={'primary'}
                        variant='contained'
                        label='contained'
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color={'primary'}
                        variant='text'
                        label='text'
                        type='button'
                        hidden={false}
                        inProgress={true}
                    />
                    <Button
                        color={'primary'}
                        variant='outlined'
                        label='outlined'
                        inProgress={true}
                    />
                    <Button
                        color={'primary'}
                        variant='contained'
                        label='contained'
                        inProgress={true}
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color='primary'
                        variant='text'
                        label='text'
                        disabled
                    />
                    <Button
                        color='primary'
                        variant='outlined'
                        label='outlined'
                        disabled
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        label='contained'
                        disabled
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color='primary'
                        variant='text'
                        label='text'
                        size='large'
                    />
                    <Button
                        color='primary'
                        variant='outlined'
                        label='outlined'
                        size='large'
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        label='contained'
                        size='large'
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color='primary'
                        variant='text'
                        label='text'
                        size='large'
                        inProgress={true}
                    />
                    <Button
                        color='primary'
                        variant='outlined'
                        label='outlined'
                        size='large'
                        inProgress={true}
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        label='contained'
                        size='large'
                        inProgress={true}
                    />
                </SectionPanel>
            </Section>
            <Section>
                <SectionTitle>Color Error</SectionTitle>
                <SectionPanel>
                    <Button
                        color='error'
                        variant='text'
                        label='text'
                    />
                    <Button
                        color='error'
                        variant='outlined'
                        label='outlined'
                    />
                    <Button
                        color='error'
                        variant='contained'
                        label='contained'
                    />
                </SectionPanel>
                <SectionPanel>
                    <Button
                        color='error'
                        variant='text'
                        label='text'
                        disabled
                    />
                    <Button
                        color='error'
                        variant='outlined'
                        label='outlined'
                        disabled
                    />
                    <Button
                        color='error'
                        variant='contained'
                        label='contained'
                        disabled
                    />
                </SectionPanel>
            </Section>
        </SectionGroup>
    )
}