import SectionTitle from "./components/SectionTitle";
import SectionPanel from "./components/SectionPanel";
import ButtonLink from "../../components/ui/buttons/LinkButton";
import SectionGroup from "./components/SectionGroup";

export default function ButtonLinkDemo() {
    return (
        <SectionGroup>
            <SectionTitle>Link Buttons</SectionTitle>
            <SectionPanel>
                <ButtonLink
                    to='/'
                    color={'primary'}
                    variant='text'
                    label='text'
                />
                <ButtonLink
                    to='/'
                    color={'primary'}
                    variant='outlined'
                    label='outlined'
                />
                <ButtonLink
                    to='/'
                    color={'primary'}
                    variant='contained'
                    label='contained'
                />
            </SectionPanel>
            <SectionPanel>
                <ButtonLink
                    to='/'
                    color={'error'}
                    variant='text'
                    label='text'
                />
                <ButtonLink
                    to='/'
                    color={'error'}
                    variant='outlined'
                    label='outlined'
                />
                <ButtonLink
                    to='/'
                    color={'error'}
                    variant='contained'
                    label='contained'
                />
            </SectionPanel>
        </SectionGroup>
    )
}