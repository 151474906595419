import {ComponentProps} from "react";

type Props = {

} & ComponentProps<'div'>

const Box = (props: Props) => {
    const {
        children,
        ...rest
    } = props

    return (
        <div
            {...rest}
        >
            {children}
        </div>
    )
}

export default Box
