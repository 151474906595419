import classNames from "classnames";
import {DateTime} from "luxon";
import React, {useRef, useState} from "react";
import EditEvent from "./event/EditEvent";
import {LayerOutput, LifeEvent} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {ColorClasses} from "./data/ColorClasses";
import StyledDialog from "../../components/dialogs/StyledDialog";
import {User} from "../../redux/features/user/userSlice";
import DialogTitle from "../../components/dialogs/DialogTitle";


type Props = {
    zoom: number,
    events?: LifeEvent[],
    layer?: LayerOutput,
    displayCurrentWeek: boolean,
    user?: User,
    weekStart?: DateTime,
    weekEnd?: DateTime,
    weekLength?: number,
    readonly?: boolean,
}

function findEventsInWeek(weekStart: string, weekEnd: string, events?: LifeEvent[]) {
    return events?.filter((event: LifeEvent) => {
        const firstWeek = weekStart <= event.startDate && weekEnd >= event.startDate;
        const middleWeek = weekStart > event.startDate && weekEnd < event.endDate;
        const lastWeek = weekStart <= event.endDate && weekEnd >= event.endDate;

        return firstWeek || middleWeek || lastWeek;
    })
}

export default function Week(props: Props) {
    const {
        zoom,
        events,
        layer,
        displayCurrentWeek,
        user,
        weekStart,
        weekEnd,
        weekLength = 7,
        readonly,
    } = props

    const [event, setEvent] = useState<LifeEvent | undefined>(undefined);
    const [eventStartDate, setEventStartDate] = useState<DateTime | undefined>(weekStart);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const initialFocus = useRef(null);

    let pastWeek = false;
    let currentWeek = false;

    let entireWeekEventColorClasses

    let isPartialWeekEvent;
    let eventsInWeek: LifeEvent[] | undefined;

    if (weekStart && weekEnd) {
        const ws = weekStart.toISODate() as string
        const we = weekEnd.toISODate() as string
        const n = DateTime.now().toISODate() as string

        pastWeek = we < n;
        currentWeek = we >= n && ws <= n

        eventsInWeek = findEventsInWeek(ws, we, events);

        if (eventsInWeek && eventsInWeek.length > 1) {
            isPartialWeekEvent = true;
        } else if (eventsInWeek && eventsInWeek.length === 1) {
            entireWeekEventColorClasses = ColorClasses[eventsInWeek[0].color + (pastWeek || (displayCurrentWeek && currentWeek) ? '-lived' : '')]
            isPartialWeekEvent = eventsInWeek[0].startDate > ws || eventsInWeek[0].endDate < we;
        }
    }

    const layerColorClasses = ColorClasses[layer?.color + (pastWeek || (displayCurrentWeek && currentWeek) ? '-lived' : '')];

    function handleOnWeekClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>, event?: LifeEvent, index?:number) {
        e.stopPropagation()
        setEventStartDate(weekStart)

        if (index) {
            setEventStartDate(weekStart?.plus({days: index}))
        }

        setEvent(event)
        setIsDialogOpen(true)
    }

    return (
        <>
            <div
                className={classNames(
                    'focus:outline-none',
                    classNames({
                        'animate-pulse': displayCurrentWeek && currentWeek,
                        [`ring-2 ring-current ring-offset-2`]: isDialogOpen,
                        'cursor-pointer': user && !isPartialWeekEvent && !readonly,
                        'pointer-events-none': !user || readonly,
                        'grid grid-cols-7': isPartialWeekEvent && weekLength === 7,
                        'grid grid-cols-8': isPartialWeekEvent && weekLength === 8,
                        'grid grid-cols-9': isPartialWeekEvent && weekLength === 9,
                        [entireWeekEventColorClasses ? entireWeekEventColorClasses : layerColorClasses]: !isPartialWeekEvent,
                    }),
                )}
                onClick={(e) => handleOnWeekClicked(e, eventsInWeek?.length === 1 ? eventsInWeek[0] : undefined)}
                style={{
                    width: zoom,
                    height: zoom,
                }}
            >
                {
                    (isPartialWeekEvent && weekStart) && (
                        <>
                            {
                                [...Array(weekLength)].map((_, i) => {
                                    const day = weekStart.plus({days: i}).toISODate() as string

                                    const foundEvent = eventsInWeek?.find((event: LifeEvent) => (
                                        day >= event.startDate && day <= event.endDate
                                    ))

                                    let colorClasses;

                                    if (foundEvent) {
                                        colorClasses = ColorClasses[foundEvent.color + (pastWeek || currentWeek ? '-lived' : '')].split(' ')
                                    } else {
                                        colorClasses = layerColorClasses.split(' ')
                                    }

                                    return (
                                        <div
                                            key={i}
                                            onClick={(e) => handleOnWeekClicked(e, foundEvent, i)}
                                            className={classNames(
                                                [colorClasses[0]],
                                                [colorClasses[4]],
                                                classNames({
                                                    [`border-l border-t border-b ${colorClasses[2]}`]: i === 0,
                                                    [`border-r border-t border-b ${colorClasses[2]}`]: i === weekLength - 1,
                                                    [`border-t border-b ${colorClasses[2]}`]: i > 0 || i < weekLength - 1,
                                                    'cursor-pointer': !readonly,
                                                })
                                            )}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }
            </div>
            {
                layer && (
                    <StyledDialog
                        isOpen={isDialogOpen}
                        setIsOpen={setIsDialogOpen}
                        title={<DialogTitle layer={layer} title={event ? 'Edit Event' : 'Add Event'} />}
                        initialFocus={initialFocus}
                    >
                        <EditEvent
                            initialFocus={initialFocus}
                            event={event}
                            weekStart={eventStartDate}
                            weekEnd={weekEnd}
                            layer={layer}
                            setIsDialogOpen={setIsDialogOpen}
                            missingBirthdateWarning={!weekStart}
                        />
                    </StyledDialog>
                )
            }
        </>
    );
}