export default function LogoIcon() {
    return (
        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" width="35" height="35" fill="currentColor">
            <rect x="50" y="50" width="50" height="50" fill="#0ea5e9" rx="5" ry="5"/>
            <rect x="125" y="50" width="50" height="50" fill="#f43f5e" rx="5" ry="5"/>
            <rect x="200" y="50" width="50" height="50" fill="#22c55e" rx="5" ry="5"/>

            <rect x="50" y="125" width="50" height="50" fill="#f59e0b" rx="5" ry="5"/>
            <rect x="125" y="125" width="50" height="50" fill="#a855f7" rx="5" ry="5"/>
            <rect x="200" y="125" width="50" height="50" fill="#14b8a6" rx="5" ry="5"/>

            <rect x="50" y="200" width="50" height="50" fill="#f97316" rx="5" ry="5"/>
            <rect x="125" y="200" width="50" height="50" fill="#475569" rx="5" ry="5"/>
            <rect x="200" y="200" width="50" height="50" fill="#ecf0f1" rx="5" ry="5"/>
        </svg>
    )
}