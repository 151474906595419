import getFitCalendarZoom, {getMaxCalendarZoom} from "../../utils/getFitCalendarZoom";
import {Popover} from "@headlessui/react";
import IconButtonBase from "../../components/ui/buttons/IconButtonBase";
import PopoverTransition from "../../components/ui/transitions/PopoverTransition";
import Paper from "../../components/layouts/Paper";
import IconButton from "../../components/ui/buttons/IconButton";
import {EnterFullScreenIcon, ExitFullScreenIcon, RulerSquareIcon, ZoomInIcon, ZoomOutIcon} from "@radix-ui/react-icons";
import React, {useEffect, useTransition} from "react";


const MIN_ZOOM = 2.5
const ZOOM_STEP = 0.25

type Props = {
    zoom: number,
    setZoom: (zoom: number) => void,
}

export default function CalendarZoomSettings(props: Props) {
    const {
        zoom,
        setZoom,
    } = props

    const [isPending, startTransition] = useTransition()
    const [buttonIndex, setButtonIndex] = React.useState<number>(-1)

    const [fitZoom, setFitZoom] = React.useState<number>(getFitCalendarZoom())
    const [maxZoom, setMaxZoom] = React.useState<number>(getMaxCalendarZoom())


    useEffect(() => {
        const handleResize = () => {
            setFitZoom(getFitCalendarZoom())
            setMaxZoom(getMaxCalendarZoom())
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleResetZoom(index: number) {
        setZoomWithTransition(fitZoom)
        setButtonIndex(index)
    }

    function handleZoomIn(index: number) {
        setZoomWithTransition(zoom + (zoom * ZOOM_STEP))
        setButtonIndex(index)
    }

    function handleZoomOut(index: number) {
        setZoomWithTransition(zoom - (zoom * ZOOM_STEP))
        setButtonIndex(index)
    }

    function handleOnExpandClick(index: number) {
        setZoomWithTransition(maxZoom)
        setButtonIndex(index)
    }

    function setZoomWithTransition(zoom: number) {
        startTransition(() => {
            setZoom(zoom)
        })
    }

    const buttons = [
        {
            label: 'Zoom out',
            disabled: zoom < MIN_ZOOM,
            onClick: handleZoomOut,
            icon: <ZoomOutIcon className='w-5 aspect-square'/>,
        },
        {
            label: 'Zoom in',
            onClick: handleZoomIn,
            icon: <ZoomInIcon className='w-5 aspect-square'/>,
        },
        {
            label: 'Fit to screen width and height',
            disabled: zoom === fitZoom,
            onClick: handleResetZoom,
            icon: <ExitFullScreenIcon className="w-5 aspect-square"/>,
        },
        {
            label: 'Expand to screen width',
            disabled: zoom === maxZoom,
            onClick: handleOnExpandClick,
            icon: <EnterFullScreenIcon className="w-5 aspect-square"/>,
        },
    ]

    return (
        <div className='flex gap-2'>
            <Popover className='relative'>
                <Popover.Button className='rounded-full'>
                    <IconButtonBase
                        tooltip="Size Settings"
                        size={'large'}
                        variant={"outlined"}
                        icon={<RulerSquareIcon />}
                        side='top'
                    />
                </Popover.Button>
                <PopoverTransition>
                    <Popover.Panel className={`absolute z-10 transform right-0 top-14`}>
                        <div className='flex flex-col gap-2'>
                            {
                                buttons.map((button, index) => (
                                    <Paper key={index}
                                           className='rounded-full'
                                           shadow='lg'>
                                        <IconButton
                                            tooltip={button.label}
                                            disabled={button.disabled}
                                            onClick={() => button.onClick(index)}
                                            variant={'outlined'}
                                            size='large'
                                            icon={button.icon}
                                            side='right'
                                            inProgress={isPending && buttonIndex === index}
                                        />
                                    </Paper>
                                ))
                            }
                        </div>
                    </Popover.Panel>
                </PopoverTransition>
            </Popover>
        </div>
    )
}