import {DateTime} from "luxon";
import {LifeEvent} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {ColorClasses} from "./data/ColorClasses";

type Props = {
    events: LifeEvent[],
    zoom: number,
    birthdate: string,
}

export default function EventLabels(props: Props) {
    const {
        events,
        zoom,
        birthdate,
    } = props

    const birthdateDT = DateTime.fromISO(birthdate);

    const Label = ({year}: { year: number }) => {
        const yearStart = birthdateDT.plus({years: year});
        const nextYearStart = birthdateDT.plus({years: year + 1});

        const filteredEvents = events?.filter((event: LifeEvent) => {
            const eventStart = DateTime.fromISO(event.startDate);
            return eventStart >= yearStart && eventStart < nextYearStart;
        });

        const maxEvents = 2;

        if (filteredEvents?.length > 0) {
            return (
                <div
                    className='absolute flex gap-x-1 transform rotate-45 uppercase leading-tight origin-top-left whitespace-nowrap tracking-widest'
                    style={{fontSize: zoom}}
                >
                    {filteredEvents?.slice(0, maxEvents).map((event: LifeEvent, index: number) => {
                        return (
                            <span key={index} className={ColorClasses[event.color]?.split(' ')[3]}>
                                    {event.name}
                                {
                                    (index === maxEvents-1) && filteredEvents.length > maxEvents && (
                                        <span className='ml-1 text-stone-400'>+{filteredEvents.length - maxEvents}</span>
                                    )
                                }
                            </span>
                        )
                    })}
                </div>
            )
        }

        return null;
    }

    return (
        <div className='absolute' style={{right: -2.5 * zoom}}>
            {
                <div className='flex flex-col' style={{
                    rowGap: zoom * 1.5,
                }}>
                    {
                        [...Array(9),].map((value, decadeIndex) => (
                            <div key={decadeIndex} className='flex flex-col' style={{
                                rowGap: zoom / 3,
                            }}>
                                {
                                    [...Array(10),].map((value, yearIndex) => (
                                        <div key={yearIndex}>
                                            <div
                                                className='relative'
                                                style={{
                                                    width: zoom,
                                                    height: zoom,
                                                }}
                                            >
                                                <Label year={decadeIndex * 10 + yearIndex} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}