import * as Form from "@radix-ui/react-form";
import { LayersIcon } from "@radix-ui/react-icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState, useTransition } from "react";
import { LayerOutput } from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import DialogDatePickerField from "../../../components/form/date-picker/DialogDatePickerField";
import { labels } from "../../../data/labels";
import Container from "../../../features/home/components/Container";
import LifeCalendar from "../../../features/life-calendar/LifeCalendar";
import LifeCalendarWrapper from "../../../features/life-calendar/LifeCalendarWrapper";
import LifeCalendarSkeleton from "../../../features/life-calendar/components/LifeCalendarSkeleton";
import Layers from "../../../features/life-calendar/features/view/Layers";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useGetEventsByLayerIDQuery } from "../../../redux/features/events/eventsSlice";
import { useGetLayersQuery } from "../../../redux/features/layers/layersSlice";
import { useGetUserQuery, useUpdateUserMutation } from "../../../redux/features/user/userSlice";
import { getDefaultZoom } from "../../../utils/getFitCalendarZoom";
import CalendarSettings from "../CalendarSettings";
import CalendarZoomSettings from "../CalendarZoomSettings";
import PrintSection from "../PrintSection";
import Share from "../Share";

export default function UserHome() {
    const [birthdate, setBirthdate] = useState<string>("");
    const [zoom, setZoom] = useLocalStorage("zoom", getDefaultZoom());
    const [currentLayer, setCurrentLayer] = useState<LayerOutput | undefined>(undefined);
    const [defaultLayer, setDefaultLayer] = useState<LayerOutput | undefined>(undefined);
    const [updateUser] = useUpdateUserMutation();
    const [isPending, startTransition] = useTransition();

    const { data: user } = useGetUserQuery();

    useEffect(() => {
        if (user && user.birthdate) {
            setBirthdate(user.birthdate);
        }
    }, [user]);

    const { data: layersResponse, isLoading: isLayersLoading, isFetching: isLayersFetching } = useGetLayersQuery();

    useEffect(() => {
        if (layersResponse?.data) {
            const d = layersResponse.data.find((layer) => layer.isDefault) as LayerOutput;
            setDefaultLayer(d);

            if (currentLayer === undefined) {
                setCurrentLayer(d || layersResponse.data[0]);
            } else {
                const f = layersResponse.data.find((l) => l.layerID === currentLayer.layerID) as LayerOutput;
                setCurrentLayer(f);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layersResponse]);

    const {
        data: eventsResponse,
        isLoading: isEventsLoading,
        isFetching: isEventsFetching,
    } = useGetEventsByLayerIDQuery(currentLayer ? currentLayer.layerID : skipToken);

    function handleOnBirthdateChanged(birthdate: string) {
        setTimeout(() => {
            startTransition(() => {
                setBirthdate(birthdate);
                updateUser({
                    birthdate,
                });
            });
        }, 10);
    }

    return (
        <div className="flex flex-col">
            <Container className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between max-w-prose">
                <Form.Root className="grow w-full">
                    <DialogDatePickerField
                        value={birthdate}
                        onChange={handleOnBirthdateChanged}
                        placeholder={"Select your birthdate"}
                        maxDate={new Date()}
                        justify={"center"}
                        inProgress={isPending}
                        size="large"
                    />
                </Form.Root>

                <div className="flex gap-1 justify-center">
                    <CalendarZoomSettings zoom={zoom} setZoom={setZoom} />
                    <CalendarSettings layer={currentLayer} birthdate={user?.birthdate} defaultLayer={defaultLayer} />
                    <PrintSection
                        birthdate={user?.birthdate || birthdate}
                        layer={currentLayer}
                        events={eventsResponse?.data}
                    />
                    <Share
                        layer={currentLayer}
                        birthdate={user?.birthdate ? user.birthdate : birthdate}
                        calendarID={user?.calendarID}
                        user={user}
                    />
                </div>
            </Container>

            <LifeCalendarWrapper>
                {isLayersLoading || isLayersFetching ? (
                    <LifeCalendarSkeleton zoom={zoom} />
                ) : (
                    <LifeCalendar
                        zoom={zoom}
                        displayCurrentWeek
                        layer={currentLayer}
                        birthdate={birthdate}
                        events={eventsResponse?.data}
                        readonly={false}
                        userName={user?.name}
                    />
                )}
            </LifeCalendarWrapper>

            <Container className="mt-6 lg:mt-10 max-w-7xl flex flex-col gap-6">
                <div className="flex items-center gap-2">
                    <LayersIcon />
                    <div className="uppercase text-xxs font-bold tracking-wider">{labels.pages.home.layers.title}</div>
                </div>
                <Layers
                    onChange={setCurrentLayer}
                    currentLayer={currentLayer}
                    defaultLayer={defaultLayer}
                    eventsIsPending={isEventsFetching || isEventsLoading}
                />
            </Container>
        </div>
    );
}
