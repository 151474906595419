const DEFAULT_COLOR_THEME = 'stone'

const fillColors: { [key: string]: string} = {
    'stone': 'fill-stone-700',
    'slate': 'fill-slate-700',
    'zinc': 'fill-zinc-700',
    'green': 'fill-green-700',
    'emerald': 'fill-emerald-700',
    'teal': 'fill-teal-700',
    'cyan': 'fill-cyan-700',
    'sky': 'fill-sky-700',
    'pink': 'fill-pink-700',
    'orange': 'fill-orange-700',
    'amber': 'fill-amber-700',
    'yellow': 'fill-yellow-700',
}

const textColors: { [key: string]: string} = {
    'stone': 'text-stone-700',
    'slate': 'text-slate-700',
    'zinc': 'text-zinc-700',
    'green': 'text-green-700',
    'emerald': 'text-emerald-700',
    'teal': 'text-teal-700',
    'cyan': 'text-cyan-700',
    'sky': 'text-sky-700',
    'pink': 'text-pink-700',
    'orange': 'text-orange-700',
    'amber': 'text-amber-700',
    'yellow': 'text-yellow-700',
}

export function getTextColor(color?: string): string {
    return color ? textColors[color] : textColors[DEFAULT_COLOR_THEME]
}

export function getFillColor(color?: string): string {
    return color ? fillColors[color] : fillColors[DEFAULT_COLOR_THEME]
}

export const ColorClasses: { [key: string]: string } = {
    'stone': 'bg-stone-100 border border-stone-300 text-stone-700 hover:bg-stone-400',
    'stone-lived': 'bg-stone-500 border border-stone-700 text-stone-700 hover:bg-stone-200',
    'slate': 'bg-slate-100 border border-slate-300 text-slate-700 hover:bg-slate-400',
    'slate-lived': 'bg-slate-500 border border-slate-700 text-slate-700 hover:bg-slate-200',
    'zinc': 'bg-zinc-100 border border-zinc-300 text-zinc-700 hover:bg-zinc-400',
    'zinc-lived': 'bg-zinc-500 border border-zinc-700 text-zinc-700 hover:bg-zinc-200',
    'green': 'bg-green-100 border border-green-300 text-green-700 hover:bg-green-400',
    'green-lived': 'bg-green-500 border border-green-700 text-green-700 hover:bg-green-200',
    'emerald': 'bg-emerald-100 border border-emerald-300 text-emerald-700 hover:bg-emerald-400',
    'emerald-lived': 'bg-emerald-500 border border-emerald-700 text-emerald-700 hover:bg-emerald-200',
    'teal': 'bg-teal-100 border border-teal-300 text-teal-700 hover:bg-teal-400',
    'teal-lived': 'bg-teal-500 border border-teal-700 text-teal-700 hover:bg-teal-200',
    'cyan': 'bg-cyan-100 border border-cyan-300 text-cyan-700 hover:bg-cyan-400',
    'cyan-lived': 'bg-cyan-500 border border-cyan-700 text-cyan-700 hover:bg-cyan-200',
    'sky': 'bg-sky-100 border border-sky-300 text-sky-700 hover:bg-sky-400',
    'sky-lived': 'bg-sky-500 border border-sky-700 text-sky-700 hover:bg-sky-200',
    'pink': 'bg-pink-100 border border-pink-300 text-pink-700 hover:bg-pink-400',
    'pink-lived': 'bg-pink-500 border border-pink-700 text-pink-700 hover:bg-pink-200',
    'orange': 'bg-orange-100 border border-orange-300 text-orange-700 hover:bg-orange-400',
    'orange-lived': 'bg-orange-500 border border-orange-700 text-orange-700 hover:bg-orange-200',
    'amber': 'bg-amber-100 border border-amber-300 text-amber-700 hover:bg-amber-400',
    'amber-lived': 'bg-amber-500 border border-amber-700 text-amber-700 hover:bg-amber-200',
    'yellow': 'bg-yellow-100 border border-yellow-300 text-yellow-700 hover:bg-yellow-400',
    'yellow-lived': 'bg-yellow-500 border border-yellow-700 text-yellow-700 hover:bg-yellow-200',
}
