import {ReactNode} from "react";
import classNames from "classnames";

type LayersContainerProps = {
    children: ReactNode,
}

export default function LayersContainer(props: LayersContainerProps) {
    const {
        children,
    } = props;

    return (
        <div className={classNames(
            'w-full',
            'grid grid-cols-1 gap-y-3.5 ',
            'xs:gap-4',
            'sm:grid-cols-3 sm:gap-4 md:gap-6 lg:gap-8',
        )}>
            {children}
        </div>
    )
}