import React from 'react';
import WeekAxis from "./WeekAxis";
import LeftColumn from "./LeftColumn";
import Year from "./Year";
import EventLabels from "./EventLabels";
import {LayerOutput, LifeEvent} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import AxisLabel from "./components/AxisLabel";
import LifeCalendarContainer from "./components/life-calendar-containers/LifeCalendarContainer";
import defaultLayer from "./data/defaultLayer";
import {EXTRA_MARGIN, PAPER_SIZE_MULTIPLE, SMALL_ITEMS_ZOOM_THRESHOLD} from "../../utils/getFitCalendarZoom";
import UserCard from "./user-card/UserCard";
import {getFillColor} from "./data/ColorClasses";
import ArrowLongRightIcon from "../../components/ui/icons/ArrowLongRightIcon";

export type CalendarProps = {
    yearInterval?: number,
    displayCurrentWeek?: boolean,
    numberOfYears?: number,
    layer?: LayerOutput,
    events?: LifeEvent[],
    columns?: number,
    zoom: number,
    birthdate?: string,
    readonly?: boolean,
    userName?: string,
    avatarS3Key?: string,
}

export default function LifeCalendar(props: CalendarProps) {
    const {
        yearInterval = 10,
        displayCurrentWeek = false,
        numberOfYears = 90,
        layer = defaultLayer,
        events,
        columns = 2,
        zoom = 5,
        birthdate,
        readonly = false,
        userName,
        avatarS3Key
    } = props;

    let adjustedZoom = zoom;
    let calendarWidth = PAPER_SIZE_MULTIPLE * zoom

    if (layer?.displayLabel || layer?.displayUserCard || layer?.displayLeftColumn) {
        const zoomAdjustment = (EXTRA_MARGIN) / PAPER_SIZE_MULTIPLE
        adjustedZoom -= (zoom * zoomAdjustment)
    }

    return (
        <LifeCalendarContainer width={calendarWidth}>
            <div className="flex h-full items-center">
                {
                    layer.displayLeftColumn && (
                        <LeftColumn zoom={zoom} adjustedZoom={adjustedZoom} layer={layer} />
                    )
                }
                <div className='flex flex-col gap-8'>
                    <div>
                        <div className='flex flex-col' style={{
                            gap: adjustedZoom * 5,
                        }}>
                            {
                                layer.displayUserCard && (
                                    <UserCard zoom={adjustedZoom} layer={layer} birthdate={birthdate} userName={userName} avatarS3Key={avatarS3Key} />
                                )
                            }
                            <div className='flex items-center' style={{
                                gap: adjustedZoom * 1.25,
                            }}>
                                <AxisLabel zoom={adjustedZoom} label="Weeks" layer={layer}/>
                                {
                                    adjustedZoom > SMALL_ITEMS_ZOOM_THRESHOLD && (
                                        <div className={getFillColor(layer.color)} style={{
                                            width: adjustedZoom * 2,
                                        }}>
                                            <ArrowLongRightIcon />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <WeekAxis zoom={adjustedZoom} columns={columns} layer={layer} />
                        <div className="flex relative">
                            <div>
                                {
                                    [...Array(numberOfYears)].map((value, yearIndex) => {
                                        return (
                                            <Year
                                                zoom={adjustedZoom}
                                                key={yearIndex}
                                                yearIndex={yearIndex}
                                                yearInterval={yearInterval}
                                                displayCurrentWeek={displayCurrentWeek}
                                                events={events}
                                                layer={layer}
                                                columns={columns}
                                                birthdate={birthdate}
                                                readonly={readonly}
                                            />
                                        )
                                    })
                                }
                            </div>
                            {
                                (layer?.displayLabel && birthdate) && (
                                    <EventLabels zoom={adjustedZoom} events={events as LifeEvent[]} birthdate={birthdate} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </LifeCalendarContainer>
    );
};
