import {useRouteError} from "react-router-dom";
import LinkButton from "../components/ui/buttons/LinkButton";
import getRouteError from "../utils/getRouteError";

export default function ErrorPage() {
    const error = useRouteError();
    const {errorTitle, errorMessage} = getRouteError(error);

    return (
        <main className='flex flex-col gap-8 justify-center items-center h-screen'>
            <div>
                <h1 className="text-4xl font-bold">{errorTitle}</h1>
            </div>
            <div>
                <i>{errorMessage}</i>
            </div>
            <LinkButton size="large" variant="contained" to={'/'} label="Go to home page" />
        </main>
    );
}