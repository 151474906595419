import {ComponentProps} from "../types";
import classNames from "classnames";
import IconButton from "../ui/buttons/IconButton";
import {forwardRef, ReactNode} from "react";
import {Cross1Icon} from "@radix-ui/react-icons";
import Sync from "../ui/Sync";

export type StyledDialogPanelProps = {
    title?: ReactNode,
    footer?: ReactNode,
    setIsOpen: (isOpen: boolean) => void,
    disableHeader?: boolean,
    disablePadding?: boolean,
    loading?: boolean,
    children?: ReactNode,
} & ComponentProps

const StyledDialogPanel = forwardRef<any, StyledDialogPanelProps>((props, ref) => {
    const {
        size = 'medium',
        children,
        title,
        footer,
        disableHeader,
        disablePadding,
        loading = false,
        setIsOpen,
    } = props;

    return (
        <div className={`flex flex-col transform rounded bg-stone-100 text-left align-middle shadow-xl transition-all relative gap-6 ${classNames({
            'w-90vw max-w-xs': size === 'small',
            'w-90vw max-w-lg': size === 'medium',
            'w-90vw  max-w-3xl': size === 'large' || size === 'x-large',
            'p-5 md:p-6 lg:p-7': !disablePadding,
        })}`}>
            {
                loading && (
                    <div className='absolute inset-0 bg-stone-100 bg-opacity-50 flex items-center justify-center z-20'>
                        <Sync size='large' />
                    </div>
                )
            }
            {
                !disableHeader && (
                    <div className={classNames(
                        "flex justify-between items-center gap-4",
                        {
                            'xt:-mt-4': size === 'large',
                        }
                    )}>
                        <div className='flex items-center font-bold text-stone-950'>
                            {title}
                        </div>
                        <div className=''>
                            <IconButton
                                size='small'
                                variant='text'
                                onClick={() => setIsOpen(false)}
                                icon={<Cross1Icon className='w-5'/>}
                            />
                        </div>
                    </div>
                )
            }
            {
                children && (
                    <div className=''>
                        {children}
                    </div>
                )
            }
            {
                footer &&
                <div className=''>
                    {footer}
                </div>
            }
        </div>
    )
})

export default StyledDialogPanel