import LifeCalendarContainer from "./life-calendar-containers/LifeCalendarContainer";
import {EXTRA_MARGIN, PAPER_SIZE_MULTIPLE} from "../../../utils/getFitCalendarZoom";

type Props = {
    zoom: number,
    extraMargin?: boolean,
}

export default function LifeCalendarSkeleton(props: Props) {
    const {
        zoom,
        extraMargin,
    } = props;

    let adjustedZoom = zoom;
    let calendarWidth = PAPER_SIZE_MULTIPLE * zoom

    if (extraMargin) {
        const zoomAdjustment = (EXTRA_MARGIN) / PAPER_SIZE_MULTIPLE
        adjustedZoom -= (zoom * zoomAdjustment)
    }

    const mainColumnGap = adjustedZoom * 3
    const weekColumnGap = adjustedZoom / 3
    const gridWidth = (adjustedZoom * 52) + (weekColumnGap * 50) + mainColumnGap

    return (
        <LifeCalendarContainer width={calendarWidth}>
            <div className='grid grid-cols-2' style={{
                width: gridWidth,
                rowGap: adjustedZoom * 1.5,
                columnGap: mainColumnGap,
            }}>
                {
                    [...Array(90 / 5)].map((value, yearIndex) => {
                        return (
                            <div
                                key={yearIndex}
                                className='w-full bg-stone-200 shadow animate-pulse aspect-[26/10]'
                            />
                        )
                    })
                }
            </div>
        </LifeCalendarContainer>
    )
}