import {ComponentProps, FC} from "react";
import classNames from "classnames";
import Box from "./Box";

type Props = {
    spacing?: 'none' | 'small' | 'medium' | 'large' | 'x-large',
    direction?: 'row' | 'column',
    center?: boolean,
} & ComponentProps<'div'>

const Stack: FC<Props> = (props) => {
    const {
        children,
        spacing = 'none',
        direction = 'column',
        center = false,
    } = props

    return (
        <Box className={`flex ${classNames({
            'flex-col': direction === 'column',
            'items-center': center,
            'flex-row': direction === 'row',
            'gap-0': spacing === 'none',
            'gap-2': spacing === 'small',
            'gap-4': spacing === 'medium',
            'gap-8': spacing === 'large',
            'gap-16': spacing === 'x-large',
        })}`}>
            {children}
        </Box>
    )
}

export default Stack