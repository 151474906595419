import {LayerOutput} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {DoubleArrowRightIcon, LayersIcon} from "@radix-ui/react-icons";
import {ColorClasses} from "../../features/life-calendar/data/ColorClasses";
import {ReactNode} from "react";

type Props = {
    layer?: LayerOutput,
    title?: string,
    children?: ReactNode,
}

export default function DialogTitle(props: Props) {
    const {
        layer,
        title,
        children
    } = props;

    return (
        <div className='flex items-center gap-2 sm:gap-3 text-sm sm:text-base lg:text-lg'>
            {
                layer && (
                    <>
                        <LayersIcon className={ColorClasses[layer.color].split(' ')[3]}/>
                        <div className={ColorClasses[layer.color].split(' ')[3]}>
                            {layer.name}
                        </div>
                        <div>
                            <DoubleArrowRightIcon/>
                        </div>
                    </>
                )
            }
            {
                title && (
                    <div>
                        {title}
                    </div>
                )
            }
            {children}
        </div>
    )
}