import classNames from "classnames";
import {ComponentProps, ReactNode} from "react";
import StyledTooltip from "../../StyledTooltip";
import {PopperContentProps} from "@radix-ui/react-tooltip";
import {ComponentSize} from "../../types";
import Sync from "../Sync";

type ButtonVariant = 'text' | 'outlined' | 'contained'

const SizeClasses: { [key: string]: string } = {
    'small': 'h-8',
    'medium': 'h-10',
    'large': 'h-12',
    'x-large': 'h-12',
}

export type IconButtonBaseProps = {
    icon: ReactNode,
    variant?: ButtonVariant,
    size?: ComponentSize,
    tooltip?: string,
    hidden?: boolean,
    color?: 'primary' | 'error' | 'success' | 'warning',
    inProgress?: boolean,
} & Pick<ComponentProps<'button'>, 'disabled'> & Pick<PopperContentProps, 'side'>

/**
 * Provides basic styling for icon buttons.
 * @param props
 * @constructor
 */
const IconButtonBase = (props: IconButtonBaseProps) => {
    let {
        icon,
        variant = 'contained',
        size = 'medium',
        disabled,
        tooltip,
        hidden,
        side = 'top',
        color = 'primary',
        inProgress,
    } = props;

    return (
        <StyledTooltip toolTip={tooltip} side={side}>
            <div className={`items-center justify-center rounded-full transition-colors duration-100 text-center aspect-square ${SizeClasses[size]} ${classNames({
                'hidden': hidden,
                'flex': !hidden,
                
                // color: primary
                
                // variant: text
                'text-stone-950 hover:bg-stone-200': variant === 'text' && !disabled && color === 'primary',
                'text-stone-400 bg-white': variant === 'text' && disabled && color === 'primary',

                // variant: outlined
                'text-stone-950 bg-white hover:bg-stone-100 border border-stone-300 hover:border-stone-400 focus:border-stone-400': variant === 'outlined' && !disabled && color === 'primary',
                'text-stone-400 bg-white border border-stone-200': variant === 'outlined' && disabled && color === 'primary',

                // variant: contained
                'text-white bg-stone-950 hover:bg-stone-600': variant === 'contained' && !disabled && color === 'primary',
                'text-white bg-stone-300': variant === 'contained' && disabled && color === 'primary',


                // color: error

                // variant: text
                'text-rose-950 hover:bg-rose-50': variant === 'text' && !disabled && color === 'error',
                'text-rose-400 bg-white': variant === 'text' && disabled && color === 'error',

                // variant: outlined
                'text-rose-950 bg-white hover:bg-rose-100 border border-rose-300 hover:border-rose-400 focus:border-rose-400': variant === 'outlined' && !disabled && color === 'error',
                'text-rose-400 bg-white border border-rose-200': variant === 'outlined' && disabled && color === 'error',

                // variant: contained
                'text-white bg-rose-950 hover:bg-rose-600': variant === 'contained' && !disabled && color === 'error',
                'text-white bg-rose-300': variant === 'contained' && disabled && color === 'error',

                
                // color: success

                // variant: text
                'text-emerald-950 hover:bg-emerald-50': variant === 'text' && !disabled && color === 'success',
                'text-emerald-400 bg-white': variant === 'text' && disabled && color === 'success',
            })}`}>
                <div className='flex items-center'>
                    {
                        inProgress ? (
                            <Sync size={size}/>
                        ) : (
                            <div>{icon}</div>
                        )
                    }
                </div>
            </div>
        </StyledTooltip>
    )
}

export default IconButtonBase;