export default function PrintIcon() {
    return (
        <div style={{
            width: 18,
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                <path fill="none" d="M0 0h256v256H0z"/>
                <path fill="none" className='stroke-stone-950' strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" d="M64 80V40h128v40M64 152h128v68H64z"/>
                <path fill="none" className='stroke-stone-950' strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" d="M64 176H28V96c0-8.8 7.8-16 17.3-16h165.4c9.5 0 17.3 7.2 17.3 16v80h-36"/>
                <circle cx="188" cy="116" r="10" className='fill-stone-950'/>
            </svg>
        </div>
    )
}