import {ReactNode} from "react";
import Container from "../home/components/Container";


type Props = {
    children: ReactNode,
}

export default function LifeCalendarWrapper(props: Props) {
    const {
        children,
    } = props;

    return (
        <Container className="pt-6 lg:pt-8 pb-10 lg:pb-16 overflow-x-scroll">
            <div className='shadow-xl lg:shadow-2xl w-fit mx-auto bg-white'>
                {children}
            </div>
        </Container>
    )
}