import Container from "../../features/home/components/Container";

const __html = require('./terms.html');

export default function Terms() {
    return (
        <Container>
            <div className="text-left mx-auto max-w-prose mt-12 lg:mt-20 mb-32">
                <div dangerouslySetInnerHTML={{__html: __html}}/>
            </div>
        </Container>
    )
}