import {NavLink, Outlet, useLocation} from "react-router-dom";
import {labels} from "../../../data/labels";
import Typography from "../../../components/ui/typography/Typography";
import Container from "../../home/components/Container";
import classNames from "classnames";
import Paper from "../../../components/layouts/Paper";
import {ArrowRightIcon} from "@radix-ui/react-icons";


const menu = [
    {
        to: 'profile',
        label: labels.menus.userMenu.profile,
    },
    {
        to: 'password',
        label: labels.menus.userMenu.password,
    },
]

export default function AccountRoot() {
    const location = useLocation()

    return (
        <Container className='max-w-5xl'>
            <div className={classNames(
                'flex flex-col gap-y-8 lg:flex-row gap-x-10',
                'mt-8 mb-24 lg:mt-12 sm:mb-32',
            )}>
                <div className='w-full lg:w-60'>
                    <div className='flex flex-col gap-4'>
                        {
                            menu.map(item => {
                                return (
                                    <Paper key={item.to}>
                                        <NavLink
                                            to={item.to}
                                            className={({isActive}) => classNames(
                                                'group rounded text-sm block font-medium px-4 py-2 sm:py-3 border-l-4',
                                                'hover:border-sky-700 transition-colors duration-100',
                                                classNames({
                                                    'border-white': !isActive,
                                                    'border-sky-700': isActive,
                                                }),
                                            )}
                                        >
                                            <div className='flex items-center justify-between'>
                                                {item.label}
                                                <ArrowRightIcon className={classNames(
                                                    'w-4 h-4 inline-block ml-2',
                                                    'transition-transform duration-100 group-hover:translate-x-1',
                                                )}/>
                                            </div>
                                        </NavLink>
                                    </Paper>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='grow flex flex-col gap-8'>
                    <Typography variant={'h3'}>
                        {`${menu.find(item => item.to === location.pathname.split('/')[2])?.label}`}
                    </Typography>
                    <Outlet/>
                </div>
            </div>
        </Container>
    )
}