import {SerializedError} from "@reduxjs/toolkit";

type ErrorCodes =
    'NotAuthorizedException' |
    'UserNotConfirmedException' |
    'CodeMismatchException' |
    'UserNotFoundException' |
    'UnknownError' |
    'InvalidParameterException' |
    'LimitExceededException' |
    'UsernameExistsException' |
    'UnsupportedOperationException' |
    'ErrorCodeNotPresent'
;

export interface MyError {
    code: ErrorCodes,
    reason?: string,
}

function isMyError(
    error: unknown
): error is MyError {
    return (
        typeof error === 'object' &&
        error != null &&
        'code' in error
    )
}

function isErrorWithMessage(
    error: unknown
): error is {
    message: string
} {
    return (
        typeof error === 'object' &&
        error != null &&
        'message' in error &&
        typeof (error as any).message === 'string'
    )
}

export function extractServerError(error: MyError | SerializedError): MyError {
    if (isMyError(error)) {
        return error
    } else if (isErrorWithMessage(error)) {
        return {
            code: 'UnknownError',
            reason: error.message,
        }
    }

    return {
        code: 'UnknownError',
    }
}
