import * as Form from "@radix-ui/react-form";
import {FormMessageProps} from "@radix-ui/react-form";
import {Severity} from "../../types";
import classNames from "classnames";


type Props = {
    severity?: Severity,
} & FormMessageProps

export const StyledFormMessage = (props : Props) => {
    const {
        children,
        match,
        severity = 'error',
    } = props;

    return (
        <Form.Message className={classNames(
            'flex text-xs font-light leading-tight',
            {
                'text-rose-600': severity === 'error',
                'text-emerald-600': severity === 'success',
                'text-amber-600': severity === 'warning',
            }
        )} match={match}>
            {children}
        </Form.Message>
    )
}