import {ReactNode} from "react";

type Props = {
    children: ReactNode,
}

export default function Section(props: Props) {
    const {
        children,
    } = props

    return (
        <div className='grid gap-y-10'>
            {children}
        </div>
    )
}