import {useDeleteLayerMutation, useGetLayersQuery} from "../../../../redux/features/layers/layersSlice";
import LayersSkeleton from "./LayersSkeleton";
import LayerCard from "./LayerCard";
import {LayerOutput} from "../../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import LayersContainer from "./LayersContainer";
import {PlusIcon} from "@radix-ui/react-icons";
import React, {MouseEvent, useEffect, useState, useTransition} from "react";
import EditLayerDialog from "../../EditLayerDialog";
import {labels} from "../../../../data/labels";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";
import IconButtonBase from "../../../../components/ui/buttons/IconButtonBase";
import Typography from "../../../../components/ui/typography/Typography";
import classNames from "classnames";

type Props = {
    onChange: (layer: LayerOutput) => void,
    currentLayer?: LayerOutput,
    defaultLayer?: LayerOutput,
    eventsIsPending?: boolean,
}

export default function Layers(props: Props) {
    const {
        onChange,
        currentLayer,
        defaultLayer,
        eventsIsPending,
    } = props;
    const [value, setValue] = useState<LayerOutput | undefined>(undefined)

    useEffect(() => {
        if (currentLayer) {
            setValue(currentLayer);
        }
    }, [currentLayer]);

    const [createUpdateOpen, setCreateUpdateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [layer, setLayer] = useState<LayerOutput>();
    const [deleteLayer] = useDeleteLayerMutation()
    const [isPending, startTransition] = useTransition();

    const {
        data: layers,
        isLoading,
        isFetching,
    } = useGetLayersQuery()

    function handleOnLayerCardClick(layer: LayerOutput) {
        if (value?.layerID === layer.layerID) {
            return;
        }

        setValue(layer);

        startTransition(() => {
            onChange(layer);
        })
    }

    function handleEditClick(e: MouseEvent<HTMLButtonElement>, layer: LayerOutput) {
        e.stopPropagation();

        setLayer(layer);
        setCreateUpdateOpen(true);
    }


    async function handleDeleteConfirm(e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();

        if (!layer) {
            return;
        }

        try {
            deleteLayer(layer.layerID)
        } catch (err) {
        } finally {
            setDeleteOpen(false)
        }
    }


    function handleNewLayerClick() {
        setLayer(undefined)
        setCreateUpdateOpen(true);
    }

    function handleDeleteClick(e: MouseEvent<HTMLButtonElement>, layer: LayerOutput) {
        e.stopPropagation()
        setDeleteOpen(true);
        setLayer(layer);
    }

    return (
        <>
            {
                (isLoading || isFetching) ? (
                    <LayersSkeleton />
                ) : (
                    <LayersContainer>
                        {
                            layers?.data.map((layer: LayerOutput) => {
                                return (
                                    <LayerCard
                                        key={layer.layerID}
                                        layer={layer}
                                        onSelectedChange={handleOnLayerCardClick}
                                        selected={value?.layerID === layer.layerID}
                                        onDeleteClick={handleDeleteClick}
                                        onEditClick={handleEditClick}
                                        isPending={isPending || eventsIsPending}
                                    />
                                )
                            })
                        }
                        <button
                            className={classNames(
                                'bg-white group flex justify-between items-center rounded-xl py-2 aspect-[7/1] sm:aspect-[3/1] gap-x-2 px-4 border border-dashed border-stone-500',
                                'opacity-90 hover:opacity-100'
                            )}
                            onClick={handleNewLayerClick}
                        >
                            <div className="flex gap-x-2">
                                <div className={`flex-none mr-2 w-4 h-4 rounded-full border border-dashed border-stone-400`}/>
                                <div className="text-xs">Add new layer</div>
                            </div>
                            <div className='lg:hidden lg:group-hover:flex'>
                                <IconButtonBase icon={<PlusIcon />} size='small' variant='text' />
                            </div>
                        </button>
                    </LayersContainer>
                )
            }
            <EditLayerDialog
                open={createUpdateOpen}
                setIsOpen={setCreateUpdateOpen}
                layer={layer}
                defaultLayer={defaultLayer}
            />
            <DeleteDialog
                isOpen={deleteOpen}
                setIsOpen={setDeleteOpen}
                handleSubmit={handleDeleteConfirm}
                title={labels.modals.layer.delete.title}
            >
                <Typography variant='subtitle2'>
                    Are you sure you want to delete
                    <strong>{` ${layer?.name} `}</strong>
                    the layer and all its events?
                </Typography>
            </DeleteDialog>
        </>
    )
}