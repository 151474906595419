import StyledDialog from "./StyledDialog";
import {MouseEvent, ReactNode, useState} from "react";
import Button from "../ui/buttons/Button";
import {labels} from "../../data/labels";
import {ButtonGroup} from "../ui/buttons/ButtonGroup";
import {StyledDialogPanelProps} from "./StyledDialogPanel";

type Props = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    handleSubmit: (e: MouseEvent<HTMLButtonElement>) => void,
    children?: ReactNode,
} & Pick<StyledDialogPanelProps, 'loading' | 'title'>

export default function DeleteDialog(props: Props) {
    const {
        isOpen,
        setIsOpen,
        handleSubmit,
        title = labels.titles.confirm,
        children,
        ...rest
    } = props;

    const [inProgress, setInProgress] = useState<boolean>(false);

    async function handleSubmitWrapper(e: MouseEvent<HTMLButtonElement>) {
        setInProgress(true);
        e.preventDefault();
        await handleSubmit(e);
        setInProgress(false);
    }

    return (
        <StyledDialog
            size='small'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={title}
            footer={
                <ButtonGroup>
                    <div className='basis-1/2'>
                        <Button
                            fullWidth
                            size='small'
                            variant='text'
                            onClick={() => setIsOpen(false)}
                            label={labels.buttons.cancel}
                        />
                    </div>
                    <div className='basis-1/2'>
                        <Button
                            fullWidth
                            size='small'
                            type='submit'
                            color='error'
                            variant='contained'
                            onClick={handleSubmitWrapper}
                            label={labels.buttons.delete}
                            inProgress={inProgress}
                        />
                    </div>
                </ButtonGroup>
            }
            {...rest}
        >
            {children}
        </StyledDialog>
    )
}