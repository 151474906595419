import {Link} from "react-router-dom";
import LogoIcon from "./ui/icons/LogoIcon";

export default function Logo() {
    return (
        <Link to="/" className='no-underline flex gap-2 sm:gap-3 items-center'>
            <div><LogoIcon /></div>
            <div className='text-lg lg:text-2xl font-bold font-sans tracking-wide'>Life Calendar</div>
        </Link>
    );
}