import * as Form from '@radix-ui/react-form';
import SectionTitle from "./components/SectionTitle";
import SectionPanel from "./components/SectionPanel";
import SectionGroup from "./components/SectionGroup";
import StyledDialogPanel from "../../components/dialogs/StyledDialogPanel";
import {ButtonGroup} from "../../components/ui/buttons/ButtonGroup";
import Button from "../../components/ui/buttons/Button";
import {FormFieldset} from "../../components/form/FormFieldset";
import FormField from "../../components/form/components/FormField";
import {ComponentProps} from "../../components/types";

export default function ModalsDemo() {
    const Content = (
        <div>
            <Form.Root className='w-full'>
                <FormFieldset>
                    <FormField
                        label='Text Field 1'
                        name='name1'
                        type='text'
                        size='medium'
                        defaultValue='Field 1'
                    />
                    <FormField
                        label='Text Field 2'
                        name='name2'
                        type='text'
                        size='medium'
                        defaultValue='Field 2'
                    />
                    <FormField
                        label='Text Field 3'
                        name='name3'
                        type='text'
                        size='medium'
                        defaultValue='Field 3'
                    />
                </FormFieldset>
            </Form.Root>
        </div>
    )
    const Footer = (props: ComponentProps) => (
        <ButtonGroup>
            <Button size={props.size} label='Cancel' variant='text' />
            <Button size={props.size} label='Save' />
        </ButtonGroup>
    )

    return (
        <SectionGroup>
            <SectionTitle>Modals</SectionTitle>
            <SectionPanel>
                <StyledDialogPanel
                    size='small'
                    setIsOpen={() => {}}
                    title='Small Modal (448px)'
                    footer={<Footer size='small' />}
                >
                    {Content}
                </StyledDialogPanel>
            </SectionPanel>
            <SectionPanel>
                <StyledDialogPanel
                    size='medium'
                    setIsOpen={() => {}}
                    title='Medium Modal (576px)'
                    footer={<Footer size='medium' />}
                >
                    {Content}
                </StyledDialogPanel>
            </SectionPanel>
            <SectionPanel>
                <StyledDialogPanel
                    size='large'
                    setIsOpen={() => {}}
                    title='Large Modal (768px)'
                    footer={<Footer size='large' />}
                >
                    {Content}
                </StyledDialogPanel>
            </SectionPanel>
            <SectionPanel>
                <StyledDialogPanel
                    size='small'
                    setIsOpen={() => {}}
                    title='Small Modal (448px) without content'
                    footer={<Footer size='small' />}
                />
            </SectionPanel>
        </SectionGroup>
    )
}