import classNames from "classnames";
import {ComponentProps} from "react";
import Sync from "../Sync";
import {ButtonVariant, ComponentSize} from "../../types";


export type ButtonBaseProps = {
    color?: 'primary' | 'secondary' | 'error' | 'success',
    variant?: ButtonVariant,
    label?: string,
    size?: ComponentSize,
    inProgress?: boolean,
    fullWidth?: boolean,
    fullHeight?: boolean,
    selected?: boolean,
    extraClasses?: string,
    fontSize?: string,
} & Pick<ComponentProps<'button'>, 'disabled' | 'children'>

/**
 * Provides basic styling for buttons.
 * @param props
 * @constructor
 */
const ButtonBase = (props: ButtonBaseProps) => {
    let {
        color = 'primary',
        variant = 'contained',
        label,
        size = 'medium',
        disabled,
        inProgress,
        selected,
        fullWidth,
        fullHeight,
        extraClasses = '',
        fontSize = '',
        children,
    } = props;

    if (inProgress) {
        disabled = true
    }

    return (
        <div className={`flex items-center justify-center capitalize font-medium leading-none rounded-full transition-colors duration-100 text-center ${extraClasses} ${classNames({
            'w-full': fullWidth,
            'h-full': fullHeight,
            
            'w-24': !fullWidth && size === 'small',
            'w-32': !fullWidth && size === 'medium',
            'w-40': !fullWidth && (size === 'large' || size === 'x-large'),
            
            'h-8': size === 'small' && !fullHeight,
            'h-10': size === 'medium' && !fullHeight,
            'h-12': (size === 'large' || size === 'x-large') && !fullHeight,

            'text-xs': size === 'small' && !fontSize,
            'text-sm': size === 'medium' && !fontSize,
            'text-base': (size === 'large' || size === 'x-large') && !fontSize,
            
            [fontSize]: fontSize,
            
            // Color: primary
            
            // variant: text
            'text-stone-950 hover:bg-stone-200': variant === 'text' && color === 'primary' && !disabled && !selected,
            'text-stone-950 bg-stone-200': variant === 'text' && color === 'primary' && !disabled && selected,
            'text-stone-400 bg-white': variant === 'text' && color === 'primary' && disabled,
            
            // variant: outlined
            'text-stone-950 bg-white hover:bg-stone-100 border border-stone-500': variant === 'outlined' && color === 'primary' && !disabled,
            'text-stone-400 bg-white border border-stone-200': variant === 'outlined' && color === 'primary' && disabled,
            
            // variant: contained
            'text-white bg-stone-950 hover:bg-stone-600': variant === 'contained' && color === 'primary' && !disabled,
            'text-white bg-stone-300': variant === 'contained' && color === 'primary' && disabled,
            
            
            // Color: secondary
            
            // variant: text
            // variant: outlined
            // variant: contained
            'text-stone-950 bg-stone-100 hover:bg-stone-200': variant === 'contained' && color === 'secondary' && !disabled,
            'text-stone-400 bg-stone-100': variant === 'contained' && color === 'secondary' && disabled,
            
            
            // Color: error

            // variant: text
            'text-rose-500 bg-stone-100 hover:bg-rose-100': variant === 'text' && color === 'error' && !disabled,
            'text-rose-300 bg-stone-100': variant === 'text' && color === 'error' && disabled,
            
            // variant: outlined
            'text-rose-500 bg-white hover:bg-rose-100 border border-rose-500': variant === 'outlined' && color === 'error' && !disabled,
            'text-rose-200 bg-white border border-rose-200': variant === 'outlined' && color === 'error' && disabled,
            
            // variant: contained
            'text-white bg-rose-600 hover:bg-rose-500': variant === 'contained' && color === 'error' && !disabled,
            'text-white bg-rose-200': variant === 'contained' && color === 'error' && disabled,
            
            
            // Color: success
            // variant: text
            // variant: outlined
            // variant: contained
            'text-white bg-emerald-500 hover:bg-emerald-600': variant === 'contained' && color === 'success' && !disabled,
            'text-white bg-emerald-400': variant === 'contained' && color === 'success' && disabled,
            
        })}`}>
            <div className='flex items-center'>
                {
                    inProgress ? (
                        <Sync size={size}/>
                    ) : (
                        <div>{label ? label : children}</div>
                    )
                }
            </div>
        </div>
    )
}

export default ButtonBase;