import Container from "../../../features/home/components/Container";
import Features from "./features/Features";
import Examples from "./examples/Examples";
import CallToAction from "./CallToAction";
import Hero from "./Hero";
import LifeCalendarDemo from "./LifeCalendarDemo";

export default function GuestHome() {
    return (
        <div className='flex flex-col gap-20 sm:gap-24 md:gap-28 lg:gap-32'>
            <div className="flex flex-col gap-12">
                <Container>
                    <Hero />
                </Container>

                <LifeCalendarDemo />
            </div>

            <div className="-mt-20 sm:-mt-24">
                <Container className='max-w-7xl mx-auto flex flex-col gap-20 sm:gap-24 md:gap-28 lg:gap-32'>
                    <Features />
                    <Examples />
                    <div className='py-12'>
                        <CallToAction />
                    </div>
                </Container>
            </div>
        </div>
    )
}