import React from "react";
import {useGetUserQuery} from "../../redux/features/user/userSlice";
import UserHome from "./user/UserHome";
import GuestHome from "./guest/GuestHome";


export default function Home() {
    const {
        data: user,
    } = useGetUserQuery()


    return (
        <>
            <div className="mt-10 lg:mt-16 mb-32 lg:mb-44">
                {
                    !user && (<GuestHome />)
                }
                {
                    user && (<UserHome />)
                }
            </div>
        </>
    )
}