import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

type ReduxState = {
    avatarBlob: string | null,
}

// Define the initial state using that type
const initialState: ReduxState = {
    avatarBlob: null,
}

export const reduxSlice = createSlice({
    name: 'redux',
    initialState,
    reducers: {
        addAvatarBlob: (state, action: PayloadAction<string | null>) => {
            state.avatarBlob = action.payload
        },
        removeAvatarBlob: (state) => {
            state.avatarBlob = null
        },
    }
})

export const { addAvatarBlob, removeAvatarBlob } = reduxSlice.actions

export const selectAvatarBlob = (state: RootState) => state.redux.avatarBlob

export default reduxSlice.reducer