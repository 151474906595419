import {ReactNode} from "react";
import SectionTitle from "./SectionTitle";
import Paper from "../../../components/layouts/Paper";
import Box from "../../../components/layouts/Box";

type Props = {
    title: string;
    actions?: any[];
    children: ReactNode,
}


export default function Section(props: Props) {
    const {
        title,
        children,
    } = props;

    return (
        <section className='flex flex-col gap-2'>
            <SectionTitle>{title}</SectionTitle>
            <Paper>
                <Box className='px-4 py-6 flex flex-col gap-6'>
                    {children}
                </Box>
            </Paper>
        </section>
    )
}