import {ReactNode} from "react";

type Props = {
    children: ReactNode,
}
export default function FormContent(props: Props) {
    const {
        children,
    } = props;

    return (
        <div className='flex flex-col gap-y-8'>
            {children}
        </div>
    )
}