import * as Form from "@radix-ui/react-form";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FormContent from "../../../components/form/FormContent";
import { FormFieldset } from "../../../components/form/FormFieldset";
import FormMessage from "../../../components/form/FormMessage";
import PasswordField from "../../../components/form/PasswordField";
import VerificationCodeField from "../../../components/form/VerificationCodeField";
import Button from "../../../components/ui/buttons/Button";
import { ButtonGroup } from "../../../components/ui/buttons/ButtonGroup";
import { labels } from "../../../data/labels";
import { AuthLayout } from "../../../features/authentication";
import { NotAMemberLink } from "../../../features/authentication/components/NotAMemberLink";
import { extractServerError } from "../../../redux/features/api/serverErrorsHelper";
import { useForgotPasswordMutation, useForgotPasswordSubmitMutation } from "../../../redux/features/user/userSlice";
import { getPayloadData } from "../../../utils/searchParamsUtils";

export default function ResetPasswordConfirm() {
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [resendVerificationCodeInProgress, setResendVerificationCodeInProgress] = useState(false);
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationCodeServerError, setVerificationCodeServerError] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    let [searchParams] = useSearchParams();

    const [forgotPassword] = useForgotPasswordMutation();
    const [forgotPasswordSubmit] = useForgotPasswordSubmitMutation();

    useEffect(() => {
        if (!location.state) {
            const searchParamPayload = searchParams.get("payload");
            const searchParamCode = searchParams.get("code");

            if (searchParamPayload && searchParamCode) {
                try {
                    const payload = getPayloadData(searchParamPayload);

                    if (payload) {
                        setEmail(payload.email);
                    } else {
                        setFormError(labels.errors.verificationLinkCorrupted);
                    }

                    setVerificationCode(searchParamCode);
                } catch (error) {
                    console.error("Error parsing payload: ", error);
                }
            }
        } else {
            setEmail(location.state.email);
        }
    }, [navigate, location.state, searchParams]);

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setInProgress(true);

        try {
            await forgotPasswordSubmit({ email, verificationCode, password }).unwrap();
            navigate("../success");
        } catch (error: any) {
            const myError = extractServerError(error);

            switch (myError.code) {
                case "CodeMismatchException":
                    setVerificationCodeServerError(labels.fields.serverErrors.CodeMismatchException);
                    break;
                default:
                    setFormError(labels.errors.unknown);
                    break;
            }
        }

        setInProgress(false);
    }

    async function handleResendVerificationCode() {
        try {
            setResendVerificationCodeInProgress(true);
            await forgotPassword({ email }).unwrap();
            setFormSuccess(`We sent a verification code to your email: ${email}`);
            setResendVerificationCodeInProgress(false);
        } catch (error: any) {
            setFormError(labels.errors.unknown);
        }
    }

    function handleClearServerErrors() {
        setVerificationCodeServerError("");
        setFormError("");
    }

    function handleVerificationCodeChange(event: ChangeEvent<HTMLInputElement>) {
        setVerificationCode(event.currentTarget.value);
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        setPassword(event.currentTarget.value);
    }

    return (
        <AuthLayout
            title={labels.pages.auth.resetPasswordConfirm.title}
            subTitle={labels.pages.auth.resetPasswordConfirm.subTitle}
            headerLink={<NotAMemberLink />}
            banner={
                <div className="flex flex-col gap-4">
                    {formError && (
                        <FormMessage severity={"error"} onClose={handleClearServerErrors}>
                            {formError}
                        </FormMessage>
                    )}
                    {formSuccess && (
                        <FormMessage severity={"success"} onClose={handleClearServerErrors}>
                            {formSuccess}
                        </FormMessage>
                    )}
                </div>
            }
        >
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    <FormFieldset>
                        <VerificationCodeField
                            value={verificationCode}
                            onChange={handleVerificationCodeChange}
                            serverError={verificationCodeServerError}
                            onHelperClick={handleResendVerificationCode}
                            helpActionInProgress={resendVerificationCodeInProgress}
                            size={"large"}
                        />
                        <PasswordField
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            size={"large"}
                            label={labels.fields.newPassword.label}
                        />
                    </FormFieldset>
                    <ButtonGroup>
                        <Form.Submit asChild>
                            <div className="flex basis-full sm:basis-1/2 justify-end">
                                <Button
                                    fullWidth
                                    type="submit"
                                    size={"large"}
                                    variant="contained"
                                    inProgress={inProgress}
                                    label={labels.pages.auth.resetPasswordConfirm.button}
                                />
                            </div>
                        </Form.Submit>
                    </ButtonGroup>
                </FormContent>
            </Form.Root>
        </AuthLayout>
    );
}
