import * as Form from '@radix-ui/react-form';
import SectionTitle from "./components/SectionTitle";
import SectionPanel from "./components/SectionPanel";
import Section from "./components/Section";
import SectionGroup from "./components/SectionGroup";
import {FormFieldset} from "../../components/form/FormFieldset";
import FormField from "../../components/form/components/FormField";
import React, {useState} from "react";
import PopoverDatePickerField from "../../components/form/date-picker/PopoverDatePickerField";
import ColorPicker from "../life-calendar/components/ColorPicker";
import {CalendarColor} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import DialogDatePickerField from "../../components/form/date-picker/DialogDatePickerField";
import SectionPanelTitle from "./components/SectionPanelTitle";
import StyledForm from "./components/StyledForm";

export default function InputsDemo() {
    const [startDate1, setStartDate1] = useState<string>('');
    const [startDate2, setStartDate2] = useState<string>('');
    const [startDate3, setStartDate3] = useState<string>('');

    const [birthdate1, setBirthdate1] = useState<string>('');
    const [birthdate2, setBirthdate2] = useState<string>('');
    const [birthdate3, setBirthdate3] = useState<string>('');

    const [color, setColor] = useState<CalendarColor>('sky');

    return (
        <>
            <SectionGroup>
                <SectionTitle>Inputs</SectionTitle>
                <Section>
                    <SectionPanelTitle>Text Fields</SectionPanelTitle>
                    <SectionPanel>
                        <Form.Root className='w-96'>
                            <FormFieldset>
                                <FormField
                                    label='Text Field 1 (small)'
                                    name='name1'
                                    type='text'
                                    size='small'
                                    defaultValue='Field 1'
                                    required
                                />
                                <FormField
                                    label='Text Field 2 (medium)'
                                    name='name2'
                                    type='text'
                                    size='medium'
                                    defaultValue='Field 2'
                                    required
                                />
                                <FormField
                                    label='Text Field 3 (large)'
                                    name='name3'
                                    type='text'
                                    size='large'
                                    defaultValue='Field 3'
                                    required
                                />
                            </FormFieldset>
                        </Form.Root>
                    </SectionPanel>
                    <SectionPanelTitle>Disabled Text Fields</SectionPanelTitle>
                    <SectionPanel>
                        <StyledForm>
                            <FormFieldset>
                                <FormField
                                    label='Text Field 4 (small)'
                                    size='small'
                                    name='name4'
                                    type='text'
                                    defaultValue='Field 4'
                                    required
                                    disabled
                                />
                                <FormField
                                    label='Text Field 5 (medium)'
                                    size='medium'
                                    name='name5'
                                    type='text'
                                    defaultValue='Field 5'
                                    required
                                    disabled
                                />
                                <FormField
                                    label='Text Field 6 (disabled)'
                                    size='large'
                                    name='name6'
                                    type='text'
                                    defaultValue='Field 6'
                                    required
                                    disabled
                                />
                            </FormFieldset>
                        </StyledForm>
                    </SectionPanel>
                    <SectionPanelTitle>Popover Date Picker</SectionPanelTitle>
                    <SectionPanel>
                        <StyledForm>
                            <FormFieldset>
                                <PopoverDatePickerField
                                    name='date1'
                                    size='small'
                                    label='Date Field (small)'
                                    value={startDate1}
                                    onChange={setStartDate1}
                                    placeholder={'Select a date'}
                                />
                                <PopoverDatePickerField
                                    name='date2'
                                    size='medium'
                                    label='Date Field (medium)'
                                    value={startDate2}
                                    onChange={setStartDate2}
                                    placeholder={'Select a date'}
                                />
                                <PopoverDatePickerField
                                    name='date3'
                                    size='large'
                                    label='Date Field (large)'
                                    value={startDate3}
                                    onChange={setStartDate3}
                                    placeholder={'Select a date'}
                                />
                            </FormFieldset>
                        </StyledForm>
                    </SectionPanel>
                    <SectionPanelTitle>Dialog Date Picker</SectionPanelTitle>
                    <SectionPanel>
                        <Form.Root className='w-96'>
                            <FormFieldset>
                                <DialogDatePickerField
                                    value={birthdate1}
                                    onChange={setBirthdate1}
                                    label={'Dialog date picker (small)'}
                                    placeholder='Select your birthdate'
                                    size='small'
                                    maxDate={new Date()}
                                />
                                <DialogDatePickerField
                                    value={birthdate2}
                                    onChange={setBirthdate2}
                                    label={'Dialog date picker (medium)'}
                                    placeholder='Select your birthdate'
                                    size='medium'
                                    maxDate={new Date()}
                                />
                                <DialogDatePickerField
                                    value={birthdate3}
                                    onChange={setBirthdate3}
                                    label={'Dialog date picker (large)'}
                                    placeholder='Select your birthdate'
                                    size='large'
                                    maxDate={new Date()}
                                    inProgress
                                />
                            </FormFieldset>
                        </Form.Root>
                    </SectionPanel>
                    <SectionPanelTitle>Color Picker</SectionPanelTitle>
                    <SectionPanel>
                        <Form.Root className='w-96'>
                            <FormFieldset>
                                <Form.Field name='color'>
                                    <ColorPicker
                                        name='color1'
                                        label='Color Field 1'
                                        value={color}
                                        onValueChange={setColor}
                                    />
                                </Form.Field>
                            </FormFieldset>
                        </Form.Root>
                    </SectionPanel>
                </Section>
            </SectionGroup>
        </>
    )
}