import * as Toast from '@radix-ui/react-toast';
import React, {ReactNode} from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, useLocation} from "react-router-dom";
import Privacy from "./pages/legal/privacy";
import Terms from "./pages/legal/terms";
import Home from "./pages/home/Home";
import Profile from "./pages/settings/Profile";
import ErrorPage from "./pages/ErrorPage";
import SignIn from "./pages/authentication/signIn/SignIn";
import {AuthRoot} from './features/authentication'
import SignUp from "./pages/authentication/signUp/SignUp";
import ResetPassword from "./pages/authentication/signIn/ResetPassword";
import SignUpConfirm from "./pages/authentication/signUp/SignUpConfirm";
import ChangePassword from "./pages/settings/ChangePassword";
import Root from './pages/Root';
import ResetPasswordConfirm from './pages/authentication/signIn/ResetPasswordConfirm';
import SignUpSuccess from "./pages/authentication/signUp/SignUpSuccess";
import ResetPasswordSuccess from "./pages/authentication/signIn/ResetPasswordSuccess";
import {useGetUserQuery} from "./redux/features/user/userSlice";
import Sync from "./components/ui/Sync";
import ComponentDemoRoute from "./pages/demo/ComponentDemoRoute";
import AccountRoot from "./features/settings/layouts/AccountRoot";
import {hideToast, removeToast, selectToasts} from "./redux/features/toast/toastSlice";
import {useSelector} from "react-redux";
import {useAppDispatch} from "./redux/hooks";
import classNames from "classnames";
import IconButton from "./components/ui/buttons/IconButton";
import {CheckCircledIcon, Cross1Icon, CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon} from "@radix-ui/react-icons";
import ShareRoute from "./pages/ShareRoute";

export default function App() {
    const routerFromElements = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<Root/>} errorElement={<ErrorPage/>}>
                    <Route index element={<Home/>}/>
                    <Route path="privacy" element={<Privacy/>}/>
                    <Route path="terms-of-service" element={<Terms/>}/>
                    <Route path="account" element={<RequireAuth><AccountRoot/></RequireAuth>}>
                        <Route path='profile' element={<Profile/>}/>
                        <Route path='password' element={<ChangePassword/>}/>
                    </Route>
                    <Route path='share/:data' element={<ShareRoute />}/>
                </Route>
                <Route path="components" element={<ComponentDemoRoute />} />
                <Route path='/' element={<AuthRoot/>} errorElement={<ErrorPage/>}>
                    <Route path="join" errorElement={<ErrorPage />}>
                        <Route index element={<SignUp/>}/>
                        <Route path="confirm" element={<SignUpConfirm/>} />
                        <Route path='success' element={<SignUpSuccess/>} />
                    </Route>
                    <Route path='login' element={<SignIn/>} errorElement={<ErrorPage />} />
                    <Route path="reset-password" errorElement={<ErrorPage />}>
                        <Route index element={<ResetPassword/>} />
                        <Route path='confirm' element={<ResetPasswordConfirm/>} />
                        <Route path='success' element={<ResetPasswordSuccess/>} />
                    </Route>
                </Route>
            </>
        )
    )

    return (
        <>
            <RouterProvider router={routerFromElements}/>
            <Toaster/>
        </>
    );
}

function Toaster() {
    const dispatch = useAppDispatch();
    const toasts = useSelector(selectToasts)

    const sizeClasses = 'w-6 h-6'
    const icons = {
        error: <CrossCircledIcon className={`${sizeClasses} text-rose-600`} />,
        warning: <ExclamationTriangleIcon className={`${sizeClasses} text-amber-600`} />,
        success: <CheckCircledIcon className={`${sizeClasses} text-emerald-600`} />,
        info: <InfoCircledIcon className={`${sizeClasses} text-sky-600`} />,
    }

    return (
        <>
            {toasts.map(toast => (
                <Toast.Root
                    key={toast.id}
                    className={`${classNames(
                        "border-l-4 bg-white w-11/12 sm:w-96 rounded shadow-2xl px-4 py-2 sm:py-4 flex justify-between gap-4 items-center relative",
                        "radix-state-open:animate-toast-slide-in-bottom md:radix-state-open:animate-toast-slide-in-right",
                        "radix-state-closed:animate-toast-hide",
                    )} ${classNames({
                        'border-rose-600': toast.color === 'error',
                        'border-amber-600': toast.color === 'warning',
                        'border-emerald-600': toast.color === 'success',
                    })}`}
                    open={toast.isOpen}
                    onOpenChange={(open) => {
                        if (!open) {
                            dispatch(hideToast(toast.id))
                            window.setTimeout(() => {
                                dispatch(removeToast(toast.id))
                            }, 1000)
                        }
                    }}
                >
                    <div className='flex items-center gap-x-4'>
                        <div>
                            {icons[toast.color]}
                        </div>
                        <div className="flex flex-col gap-1">
                            {
                                toast.title &&
                                <div>
                                    <Toast.Title className="text-sm font-semibold">
                                        {toast.title}
                                    </Toast.Title>
                                </div>
                            }
                            <Toast.Description className="text-xs">
                                {toast.message}
                            </Toast.Description>
                        </div>
                    </div>
                    <Toast.Close className="" asChild>
                        <IconButton size="small" variant="text" icon={<Cross1Icon />} />
                    </Toast.Close>
                </Toast.Root>
            ))}
            <Toast.Viewport className="flex flex-col items-center gap-2.5 sm:gap-4 w-screen sm:w-96 fixed z-50 right-0 sm:right-8 bottom-4 sm:bottom-6" />
        </>
    )
}

function RequireAuth({children}: { children: ReactNode }) {
    const {
        data,
        isLoading,
        isFetching,
        isError,
    } = useGetUserQuery()
    const location = useLocation();

    if (isLoading || isFetching) {
        return <Sync/>;
    } else if (isError) {
        return <Navigate to='/login' state={{from: location}} replace/>;
    } else if (!data) {
        return <Navigate to='/login' state={{from: location}} replace/>;
    }

    return (
        <>
            {children}
        </>
    );
}
