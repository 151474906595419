import Container from "../../features/home/components/Container";

const __html = require('./privacy.html');

export default function Privacy() {
    return (
        <Container>
            <div className="text-left mx-auto max-w-prose mt-12 lg:mt-20 mb-32">
                <div dangerouslySetInnerHTML={{__html: __html}}/>
            </div>
        </Container>
    )
}