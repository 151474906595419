import {AuthLayout} from '../../../features/authentication'
import {labels} from "../../../data/labels";
import LinkButton from "../../../components/ui/buttons/LinkButton";


export default function ResetPasswordSuccess() {
    return (
        <AuthLayout
            title={labels.pages.auth.resetPasswordSuccess.title}
            subTitle={labels.pages.auth.resetPasswordSuccess.subtitle}
        >
            <div className='flex justify-center'>
                <div className='basis-full sm:basis-1/2 md:basis-1/3'>
                    <LinkButton
                        fullWidth
                        size={'large'}
                        variant={'contained'}
                        label={labels.pages.auth.resetPasswordSuccess.button}
                        to='/login'/>
                </div>
            </div>
        </AuthLayout>
    )
}