import {useGetFileQuery} from "../../../redux/features/user/storageSlice";
import {dateTime2LocalizedString} from "../../../utils/dateUtils";
import {LayerOutput} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {getTextColor} from "../data/ColorClasses";
import {SMALL_ITEMS_ZOOM_THRESHOLD} from "../../../utils/getFitCalendarZoom";
import CakeIcon from "../../../components/ui/icons/CakeIcon";

type Props = {
    zoom: number,
    layer?: LayerOutput,
    birthdate?: string,
    userName?: string,
    avatarS3Key?: string,
}

export default function UserCard(props: Props) {
    const {
        zoom,
        layer,
        birthdate,
        userName,
        avatarS3Key,
    } = props

    const {
        data: url,
        isFetching,
        isLoading,
    } = useGetFileQuery({userID: avatarS3Key})

    const localizedBirthdate = dateTime2LocalizedString(birthdate)

    return (
        <>
            <div className='flex items-center' style={{
                gap: zoom * 2,
            }}>
                {
                    (isFetching || isLoading) ? (
                        <div className='aspect-square bg-stone-200 rounded-full animate-pulse' style={{
                            width: zoom * 8,
                        }}/>
                    ) : (
                        <>
                            {
                                url && (
                                    <div className='aspect-square bg-stone-200 rounded-full' style={{
                                        width: zoom * 8,
                                    }}>
                                        <img
                                            src={url}
                                            alt={userName}
                                            className='rounded-full aspect-square'
                                            style={{
                                                width: zoom * 8,
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </>
                    )
                }
                <div className={`${getTextColor(layer?.color)} flex flex-col leading-none`} style={{
                    gap: zoom,
                }}>
                    <div
                        className='font-normal uppercase tracking-widest'
                        style={{
                            fontSize: zoom * 3,
                        }}
                    >
                        {`${userName ? `${userName}'s` : 'My'} life in weeks`}
                    </div>
                    <div
                        className='opacity-60'
                    >
                        <div className='flex items-end uppercase font-light tracking-widest' style={{
                            gap: zoom * 1.05,
                        }}>
                            {
                                zoom > SMALL_ITEMS_ZOOM_THRESHOLD && (
                                    <div>
                                        <CakeIcon style={{
                                            width: zoom * 1.75,
                                        }}/>
                                    </div>
                                )
                            }
                            <div style={{
                                fontSize: zoom * 1.25,
                            }}>
                                {localizedBirthdate}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}