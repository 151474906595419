import SectionTitle from "./components/SectionTitle";
import SectionPanel from "./components/SectionPanel";
import SectionGroup from "./components/SectionGroup";
import DatePicker from "../../components/form/date-picker/DatePicker";
import {useState} from "react";

export default function DatePickersDemo() {
    const [date, setDate] = useState<string>('')

    return (
        <SectionGroup>
            <SectionTitle>Date pickers</SectionTitle>
            <SectionPanel>
                <div className='w-screen max-w-xs p-4 bg-white'>
                    <DatePicker
                        size='small'
                        value={date}
                        onChange={setDate}
                    />
                </div>
            </SectionPanel>
            <SectionPanel>
                <div className='w-screen max-w-md p-6 bg-white'>
                    <DatePicker
                        size='medium'
                        value={date}
                        onChange={setDate}
                    />
                </div>
            </SectionPanel>
            <SectionPanel>
                <div className='w-screen max-w-xl p-8 bg-white'>
                    <DatePicker
                        size='large'
                        value={date}
                        onChange={setDate}
                    />
                </div>
            </SectionPanel>
        </SectionGroup>
    )
}