import {labels} from "../../data/labels";
import Print from "../../features/life-calendar/features/print/Print";
import StyledDialog from "../../components/dialogs/StyledDialog";
import {useState} from "react";
import {LayerOutput, LifeEvent} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import IconButton from "../../components/ui/buttons/IconButton";
import PrintIcon from "../../components/ui/icons/PrintIcon";
import DialogTitle from "../../components/dialogs/DialogTitle";

type Props = {
    layer?: LayerOutput,
    birthdate?: string,
    events?: LifeEvent[],
}

export default function PrintSection(props: Props) {
    const {
        layer,
        birthdate,
        events,
    } = props;

    const [isPrintShowing, setIsPrintShowing] = useState(false);

    return (
        <>
            <IconButton
                onClick={() => setIsPrintShowing(true)}
                variant='outlined'
                icon={<PrintIcon />}
                size='large'
                tooltip='Print'
            />
            <StyledDialog
                isOpen={isPrintShowing}
                setIsOpen={setIsPrintShowing}
                title={(
                    <DialogTitle layer={layer} title={labels.buttons.print} />
                )}
                size='large'
            >
                <Print
                    birthdate={birthdate}
                    layer={layer}
                    events={events}
                />
            </StyledDialog>
        </>
    )
}