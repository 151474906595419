import React, {useState} from 'react';
import Logo from "../Logo";
import {useGetUserQuery, useSignOutMutation} from "../../redux/features/user/userSlice";
import {Popover} from "@headlessui/react";
import {MyAvatar} from "./menu-bar/MenuBarAvatar";
import {Link, useNavigate} from "react-router-dom";
import Sync from "../ui/Sync";
import StyledTooltip from "../StyledTooltip";
import StyledPopoverPanel from "../ui/StyledPopoverPanel";
import {labels} from "../../data/labels";
import ButtonLink from "../ui/buttons/LinkButton";
import PopoverTransition from "../ui/transitions/PopoverTransition";
import Divider from "../ui/Divider";


const UserMenuButtons = [
    {
        to: '/account/profile',
        label: labels.menus.userMenu.profile,
    },
    {
        to: '/account/password',
        label: labels.menus.userMenu.password,
    },
]

export default function Header() {
    const {
        data: user,
        isSuccess,
    } = useGetUserQuery()

    return (
        <header className=''>
            <div className='px-4 lg:px-10 h-20 flex items-center justify-between'>
                <div>
                    <Logo />
                </div>
                <div className=''>
                    <div className='flex items-center gap-x-3 sm:gap-x-4'>
                        {
                            user && isSuccess ?
                                <>
                                    <UserAvatar />
                                </>
                                :
                                <>
                                    <div
                                        className='hidden xs:block'
                                    >
                                        <ButtonLink
                                            to='/login'
                                            variant='outlined'
                                            size={'small'}
                                            label={labels.buttons.signIn}
                                        />
                                    </div>
                                    <div className=''>
                                        <ButtonLink
                                            to='/join'
                                            size={'small'}
                                            variant='contained'
                                            label={labels.buttons.signUp}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}

const UserAvatar = () => {
    const [isSignOutInProgress, setIsSignOutInProgress] = useState(false);
    const navigate = useNavigate();
    const {data: user} = useGetUserQuery()
    const [signOut] = useSignOutMutation()

    async function onSignOutClick() {
        setIsSignOutInProgress(true)

        try {
            await signOut().unwrap()
            navigate('/')
        } catch (error: any) {
            // setError(Labels.unexpectedError)
        }

        setIsSignOutInProgress(false)
    }

    return (
        <Popover className='relative leading-none flex items-center'>
            {({ close }) => (
                <>
                    <Popover.Button className='rounded-full'>
                        <StyledTooltip side='left' toolTip={user?.email}>
                            <div><MyAvatar size='small'/></div>
                        </StyledTooltip>
                    </Popover.Button>
                    <PopoverTransition>
                        <StyledPopoverPanel size={'large'} topClassName='top-10 lg:top-12'>
                            <div className='p-4 flex items-center justify-start gap-x-4 text-sm bg-stone-50'>
                                {/*<MenuBarAvatar size='medium'/>*/}
                                <div className='grid gap-y-1'>
                                    <div className='text-lg font-bold'>{user?.name}</div>
                                    <div className='text-xs break-all'>{user?.email}</div>
                                </div>
                            </div>
                            <div className='py-4'>
                                <div className='flex flex-col gap-y-0'>
                                    {
                                        UserMenuButtons.map((button) => (
                                            <div className='flex items-center justify-between' key={button.to}>
                                                <Link
                                                    to={button.to}
                                                    onClick={close}
                                                    className='px-4 py-3 w-full hover:bg-stone-100 text-sm'
                                                >
                                                    {button.label}
                                                </Link>
                                            </div>
                                        ))
                                    }
                                    <Divider />
                                    <div className='flex items-center justify-between'>
                                        <button
                                            onClick={onSignOutClick}
                                            className='px-4 py-3 w-full hover:bg-stone-100 text-sm text-left'
                                        >
                                            <div className='flex items-center justify-start'>
                                                <div>{labels.buttons.signOut}</div>
                                                {
                                                    isSignOutInProgress && (
                                                        <div className='ml-2 inline-flex items-center justify-center'>
                                                            <Sync size='small' />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </StyledPopoverPanel>
                    </PopoverTransition>
                </>
            )}
        </Popover>
    )
}