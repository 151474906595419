import {LayerOutput} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";

const defaultLayer: LayerOutput = {
  layerID: '',
  name: '',
  displayLabel: false,
  displayUserCard: false,
  displayLeftColumn: false,
  isDefault: false,
  isPublic: false,
  color: 'stone'
}

export default defaultLayer