import {apiSlice} from "../api/apiSlice";
import {LifeEvent, ListResponse} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: build => ({
        // calendarID defaults to user email
        getEventsByLayerID: build.query<ListResponse<LifeEvent>, string>({
            query: (layerID) => ({
                url: `layers/${layerID}/events`,
                method: 'GET'
            }),
            providesTags: ['Events'],
        }),
        getEventsByCalendarIDAndLayerID: build.query<ListResponse<LifeEvent>, { calendarID: string, layerID: string }>({
            query: ({calendarID, layerID}) => ({
                url: `layers/${layerID}/events`,
                method: 'GET',
                params: {
                    calendarID
                }
            }),
            providesTags: ['Events'],
        }),
        getDemoEvents: build.query<ListResponse<LifeEvent>, string>({
            query: (layerID) => ({
                url: `layers/${layerID}/events`,
                method: 'GET'
            }),
            extraOptions: {
                isDemoRequest: true
            },
        }),
        createEvent: build.mutation<LifeEvent, { layerID: string, patch: LifeEvent }>({
            query: ({layerID, patch}) => ({
                url: `layers/${layerID}/events`,
                method: 'POST',
                data: patch,
            }),
            onQueryStarted({layerID, patch}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    eventsApiSlice.util.updateQueryData('getEventsByLayerID', layerID, (draft) => {
                        draft.data.push(patch)
                    })
                )

                queryFulfilled.catch(patchResult.undo)
            }
        }),
        updateEvent: build.mutation<LifeEvent, { layerID: string, startDate: string, patch: LifeEvent }>({
            query: ({layerID, startDate, patch}) => ({
                url: `layers/${layerID}/events/${startDate}`,
                method: 'POST',
                data: patch,
            }),
            onQueryStarted({ layerID, startDate, patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    eventsApiSlice.util.updateQueryData('getEventsByLayerID', layerID, (draft) => {
                        const index = draft.data.findIndex((event) => event.startDate === startDate)
                        Object.assign(draft.data[index], patch)
                    })
                )

                queryFulfilled.catch(patchResult.undo)
            },
        }),
        deleteEvent: build.mutation<LifeEvent, {layerID: string, startDate: string}>({
            query: ({layerID, startDate}) => ({
                url: `layers/${layerID}/events/${startDate}`,
                method: 'DELETE',
            }),
            onQueryStarted({layerID, startDate}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    eventsApiSlice.util.updateQueryData('getEventsByLayerID', layerID, (draft) => {
                        const index = draft.data.findIndex((event) => event.startDate === startDate)
                        draft.data.splice(index, 1)
                    })
                )

                queryFulfilled.catch(patchResult.undo)
            },
        })
    })
})

export const {
    useGetEventsByLayerIDQuery,
    useGetEventsByCalendarIDAndLayerIDQuery,
    useGetDemoEventsQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation
} = eventsApiSlice