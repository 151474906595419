import classNames from "classnames";
import layersImage from "./data/layers.jpg";
import eventsImage from "./data/events.jpg";
import printImage from "./data/print.jpg";
import shareImage from "./data/share.jpg";
import Stack from "../../../../components/layouts/Stack";
import Typography from "../../../../components/ui/typography/Typography";
import {labels} from "../../../../data/labels";

const features = [
    {
        title: 'Layers',
        subtitle: 'Organize and view your life from various perspectives by creating personalized layers such as career, travel, and relationships, allowing you to track and reflect on different aspects of your journey.',
        image: {
            src: layersImage,
            alt: 'Layers',
        },
    },
    {
        title: 'Events',
        subtitle: 'Capture significant moments in your life by adding events to your calendar, detailing milestones, achievements, and memorable experiences.',
        image: {
            src: eventsImage,
            alt: 'Events',
        },
    },
    {
        title: 'Print',
        subtitle: 'Transform your digital life calendar into a tangible keepsake by easily generating and printing a visually appealing timeline of your chosen layers and events, providing a tangible representation of your life\'s narrative.',
        image: {
            src: printImage,
            alt: 'Print',
        },
    },
    {
        title: 'Share',
        subtitle: 'Connect with others by sharing glimpses of your life journey. sharing allows you to celebrate, reminisce, and create connections based on your unique experiences.',
        image: {
            src: shareImage,
            alt: 'Share',
        },
    },
]


export default function Features() {
    return (
        <>
            <Stack spacing='large'>
                <Typography variant='h1' center>{labels.pages.home.features.title}</Typography>
                <div className="flex flex-col w-full gap-16">
                    {
                        features.map((feature, index) => {
                            return (
                                <div key={feature.title} className='flex flex-col md:flex-row gap-y-8 gap-x-12 items-center'>
                                    <div className='basis-1/2 flex w-full aspect-video rounded-xl shadow-xl border border-stone-300 p-4 items-center'>
                                        <div>
                                            <img src={feature.image.src} alt={feature.image.alt}/>
                                        </div>
                                    </div>
                                    <div className={`text-center flex flex-col gap-2 basis-1/2 md:text-left ${classNames({
                                        'md:order-first': index % 2 === 0,
                                    })}`}>
                                        <Typography variant={'h2'}>{feature.title}</Typography>
                                        <Typography variant={'subtitle1'}>{feature.subtitle}</Typography>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Stack>
        </>
    )
}