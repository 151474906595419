import {ColorClasses} from "../data/ColorClasses";
import * as RadioGroup from '@radix-ui/react-radio-group';
import {CalendarColor} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {forwardRef} from "react";
import classNames from "classnames";


export type Theme = {
    id: string;
    name: string;
    color: CalendarColor;
}

export const options: Theme[] = [
    {
        id: 'stone',
        name: 'Stone',
        color: 'stone',
    },
    {
        id: 'slate',
        name: 'Slate',
        color: 'slate',
    },
    {
        id: 'zinc',
        name: 'Zinc',
        color: 'zinc',
    },
    {
        id: 'green',
        name: 'Green',
        color: 'green',
    },
    {
        id: 'emerald',
        name: 'Emerald',
        color: 'emerald',
    },
    {
        id: 'teal',
        name: 'Teal',
        color: 'teal',
    },
    {
        id: 'cyan',
        name: 'Cyan',
        color: 'cyan',
    },
    {
        id: 'sky',
        name: 'Sky',
        color: 'sky',
    },
    {
        id: 'pink',
        name: 'Pink',
        color: 'pink',
    },
    {
        id: 'orange',
        name: 'Orange',
        color: 'orange',
    },
    {
        id: 'amber',
        name: 'Amber',
        color: 'amber',
    },
    {
        id: 'yellow',
        name: 'Yellow',
        color: 'yellow',
    },
]

type Props = {
    name?: string,
    label?: string,
    value: CalendarColor,
    onValueChange: (value: CalendarColor) => void,
}

const ColorPicker = forwardRef((props: Props, ref) => {
    const {
        name = 'theme',
        label = 'Theme',
        value,
        onValueChange,
    } = props;

    return (
        <div>
            <div className='mb-2'>
                <div className='text-xs font-medium px-3'>{label}</div>
            </div>
            <RadioGroup.Root
                name={name}
                value={value}
                onValueChange={onValueChange}
                className='grid grid-cols-6 gap-4 border rounded-lg px-3 lg:px-4 py-4 lg:py-6 bg-white transition-colors duration-100 border-stone-300 hover:border-stone-400'
            >
                {
                    options.map((option) => (
                        <div
                            key={option.id}
                            className='flex items-center justify-center'
                        >
                            <RadioGroup.Item
                                id={option.id}
                                value={option.id}
                                className={classNames(
                                    'group',
                                    'flex flex-col gap-2 items-center',
                                    'rounded-full focus:outline-none',
                                    'hover:opacity-100 transition-opacity duration-100',
                                    'opacity-75 data-[state=checked]:opacity-100',
                                )}
                            >
                                <div
                                    className={classNames(
                                        `border ${ColorClasses[`${option.color}-lived`].split(' ')[0]}`,
                                        `${ColorClasses[`${option.color}-lived`].split(' ')[2]}`,
                                        `${ColorClasses[`${option.color}-lived`].split(' ')[3]}`,
                                        'w-5 lg:w-6 aspect-square rounded-full',
                                        'group-data-[state=checked]:ring-2',
                                        'group-data-[state=checked]:ring-current',
                                        'group-data-[state=checked]:ring-offset-4',
                                    )}
                                >

                                </div>
                                <div className={`text-xxs ${ColorClasses[`${option.color}-lived`].split(' ')[3]}`}>
                                    {option.name}
                                </div>
                            </RadioGroup.Item>
                        </div>
                    ))
                }
            </RadioGroup.Root>
        </div>
    )
})

export default ColorPicker;