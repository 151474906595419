import Stack from "../../../components/layouts/Stack";
import Typography from "../../../components/ui/typography/Typography";
import {labels} from "../../../data/labels";

export default function Hero() {
    return (
        <Stack spacing='medium' center>
            <Typography variant='h1' center>{labels.pages.home.intro.title}</Typography>
            <div className='text-lg max-w-prose text-center font-medium opacity-75'>{labels.pages.home.intro.subtitle}</div>
        </Stack>
    )
}