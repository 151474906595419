import Week from "./Week";
import {DateTime} from "luxon";
import {LayerOutput, LifeEvent} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {useGetUserQuery} from "../../redux/features/user/userSlice";
import Tick from "./components/Tick";


type Props = {
    zoom: number,
    yearIndex: number,
    yearInterval: number,
    columns: number,
    displayCurrentWeek: boolean,
    events?: LifeEvent[],
    layer?: LayerOutput,
    birthdate?: string,
    readonly?: boolean,
}

const WEEKS_IN_YEAR = 52;

export default function Year(props: Props) {
    const {
        zoom,
        yearIndex,
        yearInterval,
        columns,
        displayCurrentWeek,
        events,
        layer,
        birthdate,
        readonly,
    } = props

    const {
        data: user,
    } = useGetUserQuery()

    let lastBirthdate: DateTime | undefined;

    if (birthdate) {
        const birthDate: DateTime | undefined = DateTime.fromISO(birthdate);
        lastBirthdate = birthDate.plus({years: yearIndex});
    }

    return (
        <div
            style={{
                marginBottom: (yearIndex + 1) % yearInterval === 0 ? zoom * 1.5 : zoom / 3,
            }}
        >
            <div className="flex items-center relative">
                <div className='absolute' style={{
                    left: -zoom * 2
                }}>
                    <Tick
                        zoom={zoom}
                        index={yearIndex}
                        major={(yearIndex + 1) % yearInterval === 0}
                        color={layer?.color}
                    />
                </div>

                <div className="flex" style={{columnGap: zoom * 3}}>
                    {
                        [...Array(columns),].map((value, weekGroupIndex) => (
                            <div key={weekGroupIndex} className='flex' style={{columnGap: zoom / 3}}>
                                {
                                    [...Array(WEEKS_IN_YEAR / columns),].map((value, weekIndex) => {
                                        let weekStart
                                        let weekEnd;
                                        let weekLength;

                                        if (lastBirthdate) {
                                            const weekOfYearIndex = (weekGroupIndex * (WEEKS_IN_YEAR / columns)) + weekIndex;
                                            weekStart = lastBirthdate.plus({weeks: weekOfYearIndex});

                                            if (weekOfYearIndex < (WEEKS_IN_YEAR - 1)) {
                                                weekEnd = weekStart.plus({days: 6})
                                            } else {
                                                // last week of the year
                                                weekEnd = lastBirthdate.plus({years: 1}).minus({days: 1})
                                                weekLength = weekEnd.diff(weekStart, 'days').days + 1
                                            }
                                        }

                                        return (
                                            <Week
                                                zoom={zoom}
                                                key={weekIndex}
                                                displayCurrentWeek={displayCurrentWeek}
                                                events={events}
                                                layer={layer}
                                                user={user}
                                                weekStart={weekStart}
                                                weekEnd={weekEnd}
                                                weekLength={weekLength}
                                                readonly={readonly}
                                            />
                                        )
                                    })
                                }
                            </div>))}
                </div>
            </div>
        </div>
    )

}