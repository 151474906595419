import {ComponentProps, forwardRef, MouseEvent} from "react";
import IconButtonBase, {IconButtonBaseProps} from "./IconButtonBase";

type Props = {
} & IconButtonBaseProps & Pick<ComponentProps<'button'>, 'onClick' | 'type'>


const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
        onClick,
        type = 'button',
        inProgress,
        ...rest
    } = props;

    async function handleOnClick(e: MouseEvent<HTMLButtonElement>) {
        if (!onClick) {
            return;
        }

        onClick(e);
    }

    return (
        <button
            ref={ref}
            className='rounded-full leading-none'
            disabled={rest.disabled}
            onClick={handleOnClick}
            type={type}
            {...rest}
        >
            <IconButtonBase inProgress={inProgress} {...rest} />
        </button>
    )
})

export default IconButton;