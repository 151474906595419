import classNames from "classnames";
import {ComponentProps, ReactNode} from "react";

type Props = {
    disableGutters?: boolean,
    children: ReactNode,
} & Pick<ComponentProps<'div'>, 'className'>

export default function Container(props: Props) {
    const {
        disableGutters,
        children,
        className = '',
    } = props;

    return (
        <div className={classNames(
            'w-full mx-auto',
            className,
            {
                'px-4 xs:px-6 sm:px-8 md:px-10 lg:px-14 xl:px-20': !disableGutters,
            }
        )}>
            {children}
        </div>
    )
}