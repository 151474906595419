import ButtonBase, {ButtonBaseProps} from "./ButtonBase";
import classNames from "classnames";
import {forwardRef} from "react";
import {Link, LinkProps} from "react-router-dom";

type Props = {
} & Omit<ButtonBaseProps, 'disabled'> & LinkProps

const LinkButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
        to,
        ...rest
    } = props;

    return (
        <Link
            className={`rounded-full leading-none ${classNames({
            })}`}
            to={to}
        >
            <ButtonBase {...rest} />
        </Link>
    )
})

export default LinkButton;