import * as Form from "@radix-ui/react-form";
import { skipToken } from "@reduxjs/toolkit/query";
import classNames from "classnames";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LayerOutput } from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import MessageBanner from "../../components/form/MessageBanner";
import FormField from "../../components/form/components/FormField";
import Stack from "../../components/layouts/Stack";
import MenuBarAvatar from "../../components/layouts/menu-bar/MenuBarAvatar";
import Button from "../../components/ui/buttons/Button";
import ButtonBase from "../../components/ui/buttons/ButtonBase";
import { ButtonGroup } from "../../components/ui/buttons/ButtonGroup";
import { labels } from "../../data/labels";
import Section from "../../features/settings/components/Section";
import { useDeleteLayerMutation, useGetLayersQuery } from "../../redux/features/layers/layersSlice";
import { removeAvatarBlob } from "../../redux/features/reduxSlice";
import { pushToast } from "../../redux/features/toast/toastSlice";
import { useGetFileQuery, useRemoveFileMutation } from "../../redux/features/user/storageSlice";
import {
    useConfirmEmailUpdateMutation,
    useDeleteUserMutation,
    useGetUserQuery,
    useUpdateUserMutation,
} from "../../redux/features/user/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { getPayloadData } from "../../utils/searchParamsUtils";

export default function Profile() {
    const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(false);
    const [isRemoveAvatarOpen, setIsRemoveAvatarOpen] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [isEmailEditing, setIsEmailEditing] = useState(false);
    const [inProgressEmailUpdate, setInProgressEmailUpdate] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { data: user } = useGetUserQuery();
    const [deleteUser] = useDeleteUserMutation();
    const [removeFile] = useRemoveFileMutation();
    const [updateUser] = useUpdateUserMutation();
    const [confirmEmailUpdate] = useConfirmEmailUpdateMutation();
    const [searchParams] = useSearchParams();

    const { data: url } = useGetFileQuery();

    useEffect(() => {
        async function confirmEmail(code: string) {
            try {
                await confirmEmailUpdate({ code }).unwrap();
                navigate("/account/profile", { replace: true, state: { status: "success" } });
            } catch (error: any) {
                console.error("Error confirming email: ", error);
                switch (error.code) {
                    case "CodeMismatchException":
                        setError(labels.errors.codeMismatch);
                        break;
                    case "ExpiredCodeException":
                        setError(labels.errors.codeExpired);
                        break;
                    default:
                        setError(labels.errors.unknown);
                        break;
                }
            }
        }

        const searchParamPayload = searchParams.get("payload");
        const searchParamCode = searchParams.get("code");

        if (searchParamPayload && searchParamCode) {
            try {
                const payload = getPayloadData(searchParamPayload);
                const searchParamCode = searchParams.get("code");

                if (payload && searchParamCode) {
                    confirmEmail(searchParamCode);
                }
            } catch (error) {
                console.error("Error parsing payload: ", error);
            }
        }
    }, [searchParams, confirmEmailUpdate, navigate]);

    useEffect(() => {
        if (location.state?.status === "success") {
            setSuccess("Email successfully updated");
        }
    }, [location, dispatch]);

    // load layers when user clicks on the delete account button
    const {
        data: layers,
        isLoading,
        isFetching,
    } = useGetLayersQuery(isDeleteAccountDialogOpen ? undefined : skipToken);

    const [deleteLayer] = useDeleteLayerMutation();

    const nameInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setName(user?.name || "");
        setEmail(user?.email || "");
    }, [user]);

    useEffect(() => {
        nameInputRef.current?.focus();
    }, [isNameEditing]);

    useEffect(() => {
        emailInputRef.current?.focus();
    }, [isEmailEditing]);

    function handleDeleteAccountButtonClicked() {
        setIsDeleteAccountDialogOpen(true);
    }

    function handleDeleteAvatarButtonClicked() {
        setIsRemoveAvatarOpen(true);
    }

    function clearError() {
        setError("");
    }

    async function handleConfirmDeleteAccount() {
        try {
            if (layers?.data) {
                for (let i = 0; i < layers.data.length; i++) {
                    await deleteLayer(layers.data[i].layerID).unwrap();
                }
            }

            await removeFile().unwrap();
            await deleteUser().unwrap();

            dispatch(
                pushToast({
                    message: labels.pages.account.profile.sections.deleteAccount.success,
                    color: "success",
                }),
            );

            navigate("/");
        } catch (error) {
            setError(labels.errors.unknown);
        }
    }

    async function handleDeleteAvatar() {
        try {
            await removeFile().unwrap();
            dispatch(removeAvatarBlob());
        } catch (error) {
            setError(labels.errors.unknown);
        }

        setIsRemoveAvatarOpen(false);
    }

    function onEditNameClickHandler() {
        setIsNameEditing(true);
    }

    function onCancelEditNameClickHandler() {
        setIsNameEditing(false);
        setName(user?.name || "");
    }

    async function onNameChangeHandler(e: FormEvent) {
        e.preventDefault();

        try {
            updateUser({ name });
            setIsNameEditing(false);
        } catch (error) {
            setError(labels.errors.unknown);
        }
    }

    function onEditEmailClickHandler() {
        setIsEmailEditing(true);
    }

    function onCancelEditEmailClickHandler() {
        setIsEmailEditing(false);
        setEmail(user?.email || "");
    }

    async function onEmailChangeHandler(e: FormEvent) {
        setInProgressEmailUpdate(true);
        e.preventDefault();

        try {
            await updateUser({ email }).unwrap();
            setSuccess("We sent a confirmation email to your new email address. Please check your inbox.");
            setIsEmailEditing(false);
        } catch (error: any) {
            switch (error.code) {
                case "TooManyRequestsException":
                    setError(labels.fields.serverErrors.TooManyRequestsException);
                    break;
                default:
                    setError(labels.errors.unknown);
                    break;
            }
        } finally {
            setInProgressEmailUpdate(false);
        }
    }

    return (
        <>
            <div className="flex flex-col gap-6 lg:gap-8">
                <MessageBanner formError={error} onClose={clearError} />
                <MessageBanner formSuccess={success} onClose={() => setSuccess("")} />
                <Section title={labels.pages.account.profile.sections.photo.title}>
                    <div
                        className={classNames(
                            "flex flex-col items-center gap-y-6",
                            "sm:flex-row sm:gap-x-6 sm:justify-between",
                        )}
                    >
                        <MenuBarAvatar size="large" />
                        <Stack direction="row" spacing="small">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDeleteAvatarButtonClicked}
                                disabled={!url}
                                label={labels.pages.account.profile.sections.photo.actions.remove}
                            />
                            <div>
                                <label className="cursor-pointer" htmlFor="avatar">
                                    <ButtonBase
                                        variant="contained"
                                        color="secondary"
                                        label={labels.pages.account.profile.sections.photo.actions.upload}
                                    />
                                </label>
                            </div>
                        </Stack>
                    </div>
                </Section>

                <Section title={labels.pages.account.profile.sections.name.title}>
                    <Form.Root
                        onSubmit={onNameChangeHandler}
                        className={classNames(
                            "flex flex-col gap-y-4",
                            "sm:flex-row sm:gap-x-8 sm:items-center sm:justify-between",
                        )}
                    >
                        <div className="grow">
                            <FormField
                                ref={nameInputRef}
                                name="name"
                                size="medium"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={!isNameEditing}
                                headerGap="md"
                            />
                        </div>
                        <ButtonGroup>
                            <Button
                                type="button"
                                size="medium"
                                variant="contained"
                                color="secondary"
                                onClick={onEditNameClickHandler}
                                label={labels.buttons.edit}
                                hidden={isNameEditing}
                            />
                            <Button
                                type="button"
                                onClick={onCancelEditNameClickHandler}
                                variant="text"
                                label={labels.buttons.cancel}
                                hidden={!isNameEditing}
                            />
                            <Form.Submit asChild>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    label={labels.buttons.save}
                                    hidden={!isNameEditing}
                                />
                            </Form.Submit>
                        </ButtonGroup>
                    </Form.Root>
                </Section>

                <Section title={labels.pages.account.profile.sections.email.title}>
                    <Form.Root
                        onSubmit={onEmailChangeHandler}
                        className={classNames(
                            "flex flex-col gap-y-4",
                            "sm:flex-row sm:gap-x-8 sm:items-center sm:justify-between",
                        )}
                    >
                        <div className="grow">
                            <FormField
                                ref={emailInputRef}
                                name="name"
                                size="medium"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={!isEmailEditing}
                                headerGap="md"
                            />
                        </div>
                        <ButtonGroup>
                            <Button
                                type="button"
                                size="medium"
                                variant="contained"
                                color="secondary"
                                onClick={onEditEmailClickHandler}
                                label={labels.buttons.edit}
                                hidden={isEmailEditing}
                            />
                            <Button
                                type="button"
                                onClick={onCancelEditEmailClickHandler}
                                variant="text"
                                label={labels.buttons.cancel}
                                hidden={!isEmailEditing}
                            />
                            <Form.Submit asChild>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    label={labels.buttons.save}
                                    hidden={!isEmailEditing}
                                    inProgress={inProgressEmailUpdate}
                                    disabled={inProgressEmailUpdate || email === user?.email}
                                />
                            </Form.Submit>
                        </ButtonGroup>
                    </Form.Root>
                </Section>
                <Section title={labels.pages.account.profile.sections.deleteAccount.title}>
                    <div
                        className={classNames(
                            "flex flex-col gap-y-4",
                            "sm:flex-row sm:gap-x-6 sm:items-center sm:justify-between",
                        )}
                    >
                        <span className="text-sm">{labels.pages.account.profile.sections.deleteAccount.subtitle}</span>
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDeleteAccountButtonClicked}
                                label={labels.pages.account.profile.sections.deleteAccount.button}
                            />
                        </ButtonGroup>
                    </div>
                </Section>
            </div>
            <DeleteDialog
                isOpen={isDeleteAccountDialogOpen}
                setIsOpen={setIsDeleteAccountDialogOpen}
                handleSubmit={handleConfirmDeleteAccount}
                title={labels.pages.account.profile.sections.deleteAccount.dialog.title}
                loading={isLoading || isFetching}
            >
                <Stack spacing="medium">
                    <div className="text-base">
                        {labels.pages.account.profile.sections.deleteAccount.dialog.subtitle}
                    </div>
                    {layers && layers.data.length > 0 && (
                        <>
                            <div className="">Deleting your account will also delete the layers listed below:</div>
                            <ul className="list-disc list-inside text-sm">
                                {layers?.data.map((layer: LayerOutput) => {
                                    return <li key={layer.layerID}>{layer.name}</li>;
                                })}
                            </ul>
                        </>
                    )}
                </Stack>
            </DeleteDialog>
            <DeleteDialog
                isOpen={isRemoveAvatarOpen}
                setIsOpen={setIsRemoveAvatarOpen}
                handleSubmit={handleDeleteAvatar}
                title={labels.pages.account.profile.sections.photo.dialog.title}
            >
                {labels.pages.account.profile.sections.photo.dialog.subtitle}
            </DeleteDialog>
        </>
    );
}
