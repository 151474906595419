import {useEffect, useState} from 'react';


function getSavedValue(key: string, initialValue: any) {
    const item = localStorage.getItem(key)

    if (item) return JSON.parse(item)

    if (initialValue instanceof Function) return initialValue()

    return initialValue
}

export default function useLocalStorage(key: string, initialValue: any) {
    const [value, setValue] = useState(() => {
        return getSavedValue(key, initialValue)
    })

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}