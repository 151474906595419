import {ReactNode} from "react";

type Props = {
    children: ReactNode,
}

export default function SectionGroup(props: Props) {
    const {
        children,
    } = props

    return (
        <div className='grid gap-y-12'>
            {children}
        </div>
    )
}