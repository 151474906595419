import * as RadioGroup from '@radix-ui/react-radio-group';


export type PaperSizeOption = {
    id: string,
    name: string,
    width: number,
    height: number,
}

const options: PaperSizeOption[] = [
    {
        id: 'A0',
        name: '841 x 1189',
        width: 841,
        height: 1189,
    },
    {
        id: 'A1',
        name: '594 x 841',
        width: 594,
        height: 841,
    },
    {
        id: 'A2',
        name: '420 x 594',
        width: 420,
        height: 594,
    },
    {
        id: 'A3',
        name: '297 x 420',
        width: 297,
        height: 420,
    },
    {
        id: 'A4',
        name: '210 x 297',
        width: 210,
        height: 297,
    }
]

// 84 + 59 + 42 + 29 + 21 = 235

interface PaperSizesRadioGroupProps {
    onChange: (value: PaperSizeOption) => void,
    value?: PaperSizeOption,
}

export default function PaperSizePicker(props: PaperSizesRadioGroupProps) {
    const {
        onChange,
        value,
    } = props;

    function handleValueChange(value: any) {
        onChange(options.find((option: PaperSizeOption) => option.id === value) as PaperSizeOption)
    }

    let divisor = 3;
    const width = window.innerWidth

    if (width < 400) { // xs
        divisor = 6;
    } else if (width < 640) { // sm
        divisor = 5;
    } else {
        divisor = 3;
    }

    return (
        <>
            <RadioGroup.Root
                value={value?.id}
                onValueChange={handleValueChange}
                className='grid items-center'
                style={{
                    gridTemplateColumns: 'repeat(235, minmax(0, 1fr))',
                }}
            >
                {
                    options.map((option, index) => (
                        <div
                            key={option.id}
                            className='group relative'
                            style={{
                                gridColumnStart: `span ${Math.floor([84, 59, 42, 29, 21][index] / 1.05)}`,
                            }}
                        >
                            <RadioGroup.Item
                                id={option.id}
                                value={option.id}
                                className='
                                    pl-2 pb-1 w-full shadow-xl rounded bg-white focus:outline-none
                                    border border-stone-500
                                    hover:scale-105 transition-transform duration-300
                                    data-[state=checked]:ring-2
                                    data-[state=checked]:ring-current
                                    data-[state=checked]:ring-offset-4
                                    data-[state=checked]:scale-105
                                '
                                style={{
                                    width: `${option.width / divisor}px`,
                                    aspectRatio: `${option.width}/${option.height}`,
                                    zIndex: index + 1,
                                }}
                            >
                                <div className='relative w-full h-full flex flex-col items-start justify-end'>
                                    <div className='text-sm sm:text-base md:text-lg lg:text-xl font-bold'>{option.id}</div>
                                    <div className='hidden md:flex md:text-xxxs lg:text-xxs'>{option.name}</div>
                                </div>
                            </RadioGroup.Item>
                        </div>
                    ))
                }
            </RadioGroup.Root>
        </>
    )
}