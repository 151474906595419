import ButtonBase, {ButtonBaseProps} from "./ButtonBase";
import classNames from "classnames";
import {ComponentProps, forwardRef, MouseEvent} from "react";

type Props = {
    hidden?: boolean,
    type?: 'button' | 'submit',
} & ButtonBaseProps & Pick<ComponentProps<'button'>, 'onClick'>

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
        hidden,
        type = 'button',
        onClick,
        size = 'medium',
        ...rest
    } = props;

    async function handleOnClick(e: MouseEvent<HTMLButtonElement>) {
        if (!onClick) { return; }
        onClick(e);
    }

    return (
        <button
            ref={ref}
            className={`group leading-none rounded-full ${classNames({
                'hidden': hidden,
                'w-full': rest.fullWidth,
                'h-full': rest.fullHeight,
            })}`}
            type={type}
            onClick={handleOnClick}
            disabled={rest.disabled || rest.inProgress}
            data-size={size}
        >
            <ButtonBase size={size} {...rest} />
        </button>
    )
})

export default Button;