import {apiSlice} from "../api/apiSlice";
import {LayerInput, LayerOutput, ListResponse} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";


const layersApiSlice = apiSlice.injectEndpoints({
    endpoints: build => ({
        getLayers: build.query<ListResponse<LayerOutput>, void>({
            query: () => ({
                url: `layers`,
                method: 'GET'
            }),
            providesTags: ['Layers'],
        }),
        createLayer: build.mutation<LayerOutput, { layer: LayerOutput, calendarID?: string }>({
            query: ({layer, calendarID}) => ({
                url: `layers`,
                method: 'POST',
                data: layer,
                params: {
                    calendarID
                }
            }),
            onQueryStarted(patch, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    layersApiSlice.util.updateQueryData('getLayers', undefined, (draft) => {
                        draft.data.push(patch.layer)
                    })
                )

                queryFulfilled.catch(patchResult.undo)
            }
        }),
        updateLayer: build.mutation<LayerOutput, { layerID: string, patch: LayerInput }>({
            query: ({layerID, patch}) => ({
                url: `layers/${layerID}`,
                method: 'POST',
                data: patch,
            }),
            onQueryStarted({ layerID, patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    layersApiSlice.util.updateQueryData('getLayers', undefined, (draft) => {
                        const layerIndex = draft.data.findIndex((layer) => layer.layerID === layerID)
                        Object.assign(draft.data[layerIndex], patch)
                    })
                )

                queryFulfilled.catch(patchResult.undo)
            },
        }),
        deleteLayer: build.mutation<LayerOutput, string>({
            query: (layerID) => ({
                url: `layers/${layerID}`,
                method: 'DELETE',
            }),
            onQueryStarted(layerID, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    layersApiSlice.util.updateQueryData('getLayers', undefined, (draft) => {
                        const layerIndex = draft.data.findIndex((layer) => layer.layerID === layerID)
                        draft.data.splice(layerIndex, 1)
                    })
                )

                queryFulfilled.catch(patchResult.undo)
            },
        }),
    })
})

export const {
    useGetLayersQuery,
    useCreateLayerMutation,
    useUpdateLayerMutation,
    useDeleteLayerMutation
} = layersApiSlice