import * as Form from '@radix-ui/react-form';
import {useState, useTransition} from "react";
import {getDefaultZoom} from "../../../utils/getFitCalendarZoom";
import DialogDatePickerField from "../../../components/form/date-picker/DialogDatePickerField";
import LifeCalendar from "../../../features/life-calendar/LifeCalendar";
import LifeCalendarWrapper from "../../../features/life-calendar/LifeCalendarWrapper";
import CalendarZoomSettings from "../CalendarZoomSettings";
import Share from "../Share";
import PrintSection from "../PrintSection";
import Container from "../../../features/home/components/Container";

export default function LifeCalendarDemo() {
    const [zoom, setZoom] = useState<number>(getDefaultZoom())
    const [birthdate, setBirthdate] = useState<string>('')
    const [isPending, startTransition] = useTransition()

    function handleOnBirthdateChanged(birthdate: string) {
        setTimeout(() => {
            startTransition(() => {
                setBirthdate(birthdate)
            })
        }, 10)
    }

    return (
        <div>
            <Container>
                <Form.Root className='max-w-prose mx-auto flex flex-col xs:flex-row items-center gap-4'>
                    <div className="grow w-full">
                        <DialogDatePickerField
                            value={birthdate}
                            onChange={handleOnBirthdateChanged}
                            placeholder={'Select your birthdate'}
                            maxDate={new Date()}
                            justify={'center'}
                            size='large'
                            inProgress={isPending}
                        />
                    </div>
                    <div className="flex items-center gap-1">
                        <CalendarZoomSettings zoom={zoom} setZoom={setZoom} />
                        <Share birthdate={birthdate} />
                        <PrintSection birthdate={birthdate} />
                    </div>
                </Form.Root>
            </Container>
            <LifeCalendarWrapper>
                <LifeCalendar
                    zoom={zoom}
                    displayCurrentWeek
                    birthdate={birthdate}
                    readonly
                />
            </LifeCalendarWrapper>
        </div>
    )
}