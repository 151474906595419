import {LayerOutput} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import {ColorClasses} from "../data/ColorClasses";

type Props = {
    label: string,
    layer?: LayerOutput,
    zoom: number,
}

export default function AxisLabel(props : Props) {
    const {
        label,
        layer,
        zoom,
    } = props;

    let classes = ColorClasses['stone'];
    if (layer) {
        classes = ColorClasses[layer.color];
    }

    return (
        <div
            className={`tracking-wider leading-none flex items-center ${classes.split(' ')[3]} top-0`}
            style={{
                fontSize: zoom,
                height: zoom * 3,
                left: '50%',
            }}
        >
            {label}
        </div>
    )
}