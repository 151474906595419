import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { Analytics, Auth, Logger } from "aws-amplify";
import { getErrorToast } from "../../../utils/toastUtils";
import { apiSlice } from "../api/apiSlice";
import { MyError } from "../api/serverErrorsHelper";
import { pushToast } from "../toast/toastSlice";
import { storageApi } from "./storageSlice";

const logger = new Logger("userSlice");

export interface ChangePasswordInput {
    oldPassword: string;
    newPassword: string;
}

type UpdateUserInput = {
    birthdate?: string;
    name?: string;
    email?: string;
    picture?: string; // stores S3 key
};

type UpdateEmailInput = {
    code: string;
};

export interface SignInInput {
    email: string;
    password: string;
}

export interface User {
    email: string;
    emailVerified: boolean;
    birthdate?: string;
    name?: string;
    picture?: string;
    calendarID: string;
}

export interface ForgotPasswordInput {
    email: string;
}

export interface ForgotPasswordSubmitInput {
    email: string;
    verificationCode: string;
    password: string;
}

export interface SignUpInput {
    email: string;
    password: string;
}

export interface ConfirmSignUpInput {
    email: string;
    verificationCode: string;
}

type ResendSignUpInput = {
    email: string;
};

const cognitoBaseQuery: BaseQueryFn<
    {
        changePasswordData?: ChangePasswordInput;
        signInData?: SignInInput;
        forgotPasswordData?: ForgotPasswordInput;
        forgotPasswordSubmitData?: ForgotPasswordSubmitInput;
        signUpData?: SignUpInput;
        confirmSignUpData?: ConfirmSignUpInput;
        resendSignUpData?: ResendSignUpInput;
        updateUserData?: UpdateUserInput;
        updateEmailData?: UpdateEmailInput;
    }, // Args
    unknown, // Result
    MyError, // Error
    { shout?: boolean }, // DefinitionExtraOptions
    { timestamp: number } // Meta
> = async (arg, api) => {
    logger.debug(`${api.endpoint}(${JSON.stringify(arg)})`);

    Analytics.record({
        name: "cognito",
        attributes: {
            endpoint: api.endpoint,
        },
    });

    try {
        switch (api.endpoint) {
            case "updateUser": {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(user, Object.assign(user.attributes, arg.updateUserData));
                break;
            }
            case "confirmEmailUpdate": {
                console.log("confirmEmailUpdate", arg.updateEmailData);
                if (!arg.updateEmailData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                await Auth.verifyCurrentUserAttributeSubmit("email", arg.updateEmailData.code);
                break;
            }
            case "getUser": {
                try {
                    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

                    return {
                        data: {
                            name: user.attributes.name,
                            email: user.attributes.email,
                            emailVerified: user.attributes.email_verified,
                            birthdate: user.attributes["birthdate"],
                            picture: user.attributes.picture,
                            calendarID: user.attributes.sub,
                        },
                    };
                } catch (error) {
                    return {
                        data: null,
                    };
                }
            }
            case "changePassword": {
                if (!arg.changePasswordData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(
                    user,
                    arg.changePasswordData.oldPassword,
                    arg.changePasswordData?.newPassword,
                );

                api.dispatch(
                    pushToast({
                        message: "Password changed successfully.",
                        color: "success",
                    }),
                );

                break;
            }
            case "signOut":
                await Auth.signOut();
                break;
            case "deleteUser":
                await Auth.deleteUser();
                break;
            case "signIn":
                if (!arg.signInData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                await Auth.signIn(arg.signInData.email, arg.signInData.password);
                api.dispatch(apiSlice.util?.resetApiState());
                api.dispatch(storageApi.util?.resetApiState());

                break;
            case "forgotPassword":
                if (!arg.forgotPasswordData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                await Auth.forgotPassword(arg.forgotPasswordData.email);

                break;
            case "forgotPasswordSubmit":
                if (!arg.forgotPasswordSubmitData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                await Auth.forgotPasswordSubmit(
                    arg.forgotPasswordSubmitData.email,
                    arg.forgotPasswordSubmitData.verificationCode,
                    arg.forgotPasswordSubmitData.password,
                );

                break;
            case "signUp":
                if (!arg.signUpData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                const signUpResult = await Auth.signUp({
                    username: arg.signUpData.email,
                    password: arg.signUpData.password,
                    attributes: {
                        email: arg.signUpData.email,
                    },
                    autoSignIn: {
                        enabled: true,
                    },
                });

                return {
                    data: signUpResult.userSub,
                };
            case "confirmSignUp":
                if (!arg.confirmSignUpData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                await Auth.confirmSignUp(arg.confirmSignUpData.email, arg.confirmSignUpData.verificationCode);
                break;
            case "resendSignUp":
                if (!arg.resendSignUpData) {
                    return {
                        error: {
                            code: "InvalidParameterException",
                        },
                    };
                }

                await Auth.resendSignUp(arg.resendSignUpData.email);
                break;
            default:
                return {
                    error: {
                        code: "UnsupportedOperationException",
                        reason: `Unsupported operation: ${api.endpoint}`,
                    },
                };
        }
    } catch (error: any) {
        logger.error("cognitoBaseQuery -> error: ", error);

        const toast = getErrorToast(error);
        if (toast) {
            api.dispatch(pushToast(toast));
        }

        let errorPayload;

        if (typeof error === "string") {
            errorPayload = {
                code: "ErrorCodeNotPresent",
                reason: error,
            };
        } else {
            errorPayload = {
                code: error.name,
                reason: error.message,
            };
        }

        Analytics.record({
            name: "cognitoError",
            attributes: {
                endpoint: api.endpoint,
                error: errorPayload.reason,
            },
        });

        return {
            error: errorPayload,
        };
    }

    return { data: "success" };
};

export const cognitoApi = createApi({
    reducerPath: "cognitoApi",
    baseQuery: cognitoBaseQuery,
    tagTypes: ["User"],
    endpoints: (builder) => ({
        getUser: builder.query<User, void>({
            query: () => ({}),
            providesTags: ["User"],
        }),
        updateUser: builder.mutation<User, UpdateUserInput>({
            query: (updateUserData) => ({ updateUserData }),
            onQueryStarted(data, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    cognitoApi.util.updateQueryData("getUser", undefined, (draft) => {
                        Object.assign(draft, data);
                    }),
                );

                queryFulfilled.catch(patchResult.undo);
            },
        }),
        confirmEmailUpdate: builder.mutation<void, UpdateEmailInput>({
            query: (updateEmailData) => ({ updateEmailData }),
            invalidatesTags: ["User"],
        }),
        changePassword: builder.mutation<void, ChangePasswordInput>({
            query: (changePasswordData) => ({ changePasswordData }),
        }),
        signOut: builder.mutation<void, void>({
            query: () => ({}),
            invalidatesTags: ["User"],
        }),
        deleteUser: builder.mutation<void, void>({
            query: () => ({}),
            invalidatesTags: ["User"],
        }),
        signIn: builder.mutation<void, SignInInput>({
            query: (signInData) => ({ signInData }),
            invalidatesTags: ["User"],
        }),
        forgotPassword: builder.mutation<void, ForgotPasswordInput>({
            query: (forgotPasswordData) => ({ forgotPasswordData }),
        }),
        forgotPasswordSubmit: builder.mutation<void, ForgotPasswordSubmitInput>({
            query: (forgotPasswordSubmitData) => ({ forgotPasswordSubmitData }),
        }),
        signUp: builder.mutation<void, SignUpInput>({
            query: (signUpData) => ({ signUpData }),
        }),
        confirmSignUp: builder.mutation<void, ConfirmSignUpInput>({
            query: (confirmSignUpData) => ({ confirmSignUpData }),
            invalidatesTags: ["User"],
        }),
        resendSignUp: builder.mutation<void, ResendSignUpInput>({
            query: (resendSignUpData) => ({ resendSignUpData }),
        }),
    }),
});

export const {
    useGetUserQuery,
    useUpdateUserMutation,
    useChangePasswordMutation,
    useSignOutMutation,
    useDeleteUserMutation,
    useSignInMutation,
    useForgotPasswordMutation,
    useForgotPasswordSubmitMutation,
    useSignUpMutation,
    useConfirmSignUpMutation,
    useResendSignUpMutation,
    useConfirmEmailUpdateMutation,
} = cognitoApi;
