import {ChevronLeftIcon, ChevronRightIcon} from "@radix-ui/react-icons";
import {DPMonth, DPYear, useDatePicker} from '@rehookify/datepicker';
import {DateTime} from "luxon";
import {useState} from "react";
import classNames from "classnames";
import IconButton from "../../ui/buttons/IconButton";
import Button from "../../ui/buttons/Button";
import {DatePickerFieldProps} from "./DatePickerField";

type View = 'days' | 'months' | 'years';

export default function DatePicker (props: Pick<DatePickerFieldProps, 'value' | 'onChange' | 'size' | 'maxDate'>) {
    const {
        value,
        onChange,
        size = 'medium',
        maxDate,
    } = props;

    const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;

    function getInitialDates() {
        if (value && dateRegex.test(value)) {
            return [new Date(value)]
        }

        return []
    }

    const {
        data: {
            calendars, // {year, month, days}
            months,
            weekDays, // names: https://github.com/rehookify/datepicker/tree/main#locale-configuration
            years
        },
        propGetters: {
            dayButton,
            nextMonthButton,
            previousMonthButton,
            monthButton,
            nextYearsButton,
            previousYearsButton,
            yearButton,
        }
    } = useDatePicker({
        selectedDates: getInitialDates(),
        onDatesChange: (dates) => onChange(DateTime.fromJSDate(dates[0]).toISODate() as string),
        dates: {
            mode: 'single',
            maxDate,
        },
        calendar: {
            mode: 'static',
            startDay: 1,
        },
        years: {
            mode: 'exact',
            numberOfYears: 18,
            step: 18,
        },
        locale: {
            monthName: 'long'
        },
    });

    const {month, year, days} = calendars[0];
    const [view, setView] = useState<View>(value ? 'days' : 'years');

    interface HeaderProps {
        previous: ({step}: { step: number }) => any;
        next: ({step}: { step: number }) => any;
        step?: number;
    }

    const Header = (props: HeaderProps) => {
        const {
            previous,
            next,
            step = 1,
        } = props;

        return (
            <div className={`border-b border-stone-100 flex flex-col ${classNames({
                'mb-2.5 pb-2.5 gap-y-2.5': size === 'small',
                'mb-3 pb-3 gap-y-3 lg:mb-5 lg:pb-5 lg:gap-y-5': size === 'medium',
                'mb-4 pb-4 gap-y-3 lg:mb-5 lg:pb-5 lg:gap-y-8': size === 'large' || size === 'x-large',
            })}`}>
                <div className='flex items-center justify-between'>
                    <div className='basis-2/3 flex gap-x-2'>
                        <div className='basis-1/2'>
                            <Button fontSize='text-sm' fullWidth size={size} onClick={() => setView('years')} variant='text' selected={view === 'years'} label={year} />
                        </div>
                        <div className='basis-1/2'>
                            <Button fontSize='text-sm' fullWidth size={size} onClick={() => setView('months')} variant='text' selected={view === 'months'} label={month.substring(0, 3)} />
                        </div>
                    </div>
                    <div className='basis-1/3 flex justify-end items-center gap-x-2'>
                        <IconButton variant='text' size={size} {...previous({step})} icon={<ChevronLeftIcon/>} />
                        <IconButton variant='text' size={size} {...next({step})} icon={<ChevronRightIcon/>}/>
                    </div>
                </div>
                {
                    view === 'days' &&
                    <div className={`items-center grid grid-cols-7 gap-y-2 text-stone-500 font-medium text-xs`}>
                        {weekDays.map((d) => (
                            <p key={d} className="text-center">{d}</p>
                        ))}
                    </div>
                }
            </div>
        )
    }

    const Panels = {
        years: (
            <>
                <Header previous={previousYearsButton} next={nextYearsButton}/>
                <div className={`grow grid grid-cols-3 items-center gap-1 ${classNames({
                    // 'gap-1': size === 'small',
                    // 'gap-2': size === 'medium',
                    'xs:gap-2 sm:gap-3': size === 'large',
                })}`}>
                    {years.map((y: DPYear) => (
                        <Button
                            variant={parseInt(year) === y.year ? 'contained' : (y.now ? 'outlined' : 'text')}
                            size={'small'}
                            key={y.$date.toString()}
                            disabled={y.disabled}
                            {...yearButton(y, {
                                onClick: () => setView('months'),
                            })}
                            fullWidth
                            fullHeight
                            label={y.year.toString()}
                            fontSize='text-sm'
                        />
                    ))}
                </div>
            </>
        ),
        months: (
            <>
                <Header previous={previousMonthButton} next={nextMonthButton} step={12}/>
                <div className={`grow grid grid-cols-3 items-center gap-3 ${classNames({
                    // 'gap-4': size === 'small',
                    // 'gap-6': size === 'medium',
                    'xs:gap-4 sm:gap-10': size === 'large',
                })}
                })}`}>
                    {months.map((m: DPMonth) => (
                        <Button
                            variant={month === m.month ? 'contained' : (m.now ? 'outlined' : 'text')}
                            size={'small'}
                            key={m.month + year}
                            disabled={m.disabled}
                            {...monthButton(m, {
                                onClick: () => setView('days'),
                            })}
                            fullWidth
                            fullHeight
                            label={m.month.substring(0, 3)}
                            fontSize='text-sm'
                        />
                    ))}
                </div>
            </>
        ),
        days: (
            <>
                <Header previous={previousMonthButton} next={nextMonthButton}/>
                <div className={`grow grid grid-cols-7 items-center gap-1 ${classNames({
                    // 'gap-1': size === 'small',
                    // 'gap-2': size === 'medium',
                    'sm:gap-2': size === 'large',
                })}`}>
                    {days.map((d) => (
                        <Button
                            variant={d.selected ? 'contained' : (d.now ? 'outlined' : 'text')}
                            size={'small'}
                            key={d.$date.toString()}
                            disabled={d.disabled}
                            extraClasses={classNames({
                                'opacity-75': !d.inCurrentMonth,
                            })}
                            {...dayButton(d)}
                            fullWidth
                            fullHeight
                            label={d.day.toString()}
                            fontSize='text-sm'
                        />
                    ))}
                </div>
            </>
        ),
    }

    return (
        <div className={classNames(
            "bg-white aspect-square",
            'p-3 xs:p-4 sm:p-5 md:p-6 lg:p-7 xl-p-8 2xl:p-9',
        )}>
            <div className='flex flex-col h-full'>
                {Panels[view]}
            </div>
        </div>
    )
}
