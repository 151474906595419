import * as Form from "@radix-ui/react-form";
import { DateTime } from "luxon";
import { FormEvent, RefObject, useState } from "react";
import {
    CalendarColor,
    LayerOutput,
    LifeEvent,
} from "../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import FormContent from "../../../components/form/FormContent";
import { FormFieldset } from "../../../components/form/FormFieldset";
import FormMessage from "../../../components/form/FormMessage";
import FormField from "../../../components/form/components/FormField";
import DialogDatePickerField from "../../../components/form/date-picker/DialogDatePickerField";
import Button from "../../../components/ui/buttons/Button";
import { ButtonGroup } from "../../../components/ui/buttons/ButtonGroup";
import { labels } from "../../../data/labels";
import {
    useCreateEventMutation,
    useDeleteEventMutation,
    useUpdateEventMutation,
} from "../../../redux/features/events/eventsSlice";
import { dateTime2String } from "../../../utils/dateUtils";
import ColorPicker from "../components/ColorPicker";

type EventFormProps = {
    event?: LifeEvent;
    weekStart?: DateTime;
    weekEnd?: DateTime;
    layer?: LayerOutput;
    setIsDialogOpen: (isOpen: boolean) => void;
    initialFocus?: RefObject<any>;
    missingBirthdateWarning?: boolean;
};
export default function EditEvent(props: EventFormProps) {
    const { event, weekStart, weekEnd, layer, setIsDialogOpen, initialFocus, missingBirthdateWarning } = props;

    const [name, setName] = useState<string>(event?.name || "");
    const [color, setColor] = useState<CalendarColor>(event?.color || "sky");
    const [startDate, setStartDate] = useState<string>(event?.startDate || dateTime2String(weekStart));
    const [endDate, setEndDate] = useState<string>(event?.endDate || dateTime2String(weekEnd));

    const [createEvent] = useCreateEventMutation();
    const [updateEvent] = useUpdateEventMutation();
    const [deleteEvent] = useDeleteEventMutation();

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();

        if (!layer) {
            return;
        }

        if (event) {
            updateEvent({
                layerID: layer.layerID,
                startDate: event.startDate,
                patch: {
                    startDate: startDate,
                    endDate: endDate,
                    name: name,
                    color: color,
                },
            });
        } else {
            createEvent({
                layerID: layer.layerID,
                patch: {
                    startDate: startDate,
                    endDate: endDate,
                    name: name,
                    color: color,
                },
            });
        }

        setIsDialogOpen(false);
    }

    function handleOnDelete() {
        if (layer && event) {
            try {
                deleteEvent({ layerID: layer.layerID, startDate: event.startDate });
            } catch (error) {}

            setIsDialogOpen(false);
        }
    }

    function handleOnCancel() {
        setIsDialogOpen(false);
    }

    function handleClearServerErrors() {}

    function handleNameChange(event: FormEvent<HTMLInputElement>) {
        setName(event.currentTarget.value);
    }

    function handleStartDateChange(date: string) {
        setStartDate(date);
    }

    function handleEndDateChange(date: string) {
        setEndDate(date);
    }

    return (
        <>
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    {missingBirthdateWarning && (
                        <FormMessage severity={"warning"}>
                            The event you create won't be displayed in the calendar until you enter your birthdate
                        </FormMessage>
                    )}
                    <FormFieldset>
                        <FormField
                            ref={initialFocus}
                            name="name"
                            label={labels.fields.event.name.label}
                            value={name}
                            onChange={handleNameChange}
                            required
                            maxLength={256}
                        />
                        <Form.Field name="color">
                            <ColorPicker
                                name="color"
                                label={labels.fields.event.color.label}
                                value={color}
                                onValueChange={setColor}
                            />
                        </Form.Field>
                        <DialogDatePickerField
                            name="startDate"
                            label={labels.fields.event.startDate.label}
                            value={startDate}
                            onChange={handleStartDateChange}
                            required
                            serverError={
                                startDate && endDate && startDate > endDate
                                    ? labels.fields.event.startDate.validation.tooLate
                                    : undefined
                            }
                        />
                        <DialogDatePickerField
                            name="endDate"
                            label={labels.fields.event.endDate.label}
                            value={endDate}
                            onChange={handleEndDateChange}
                            required
                            serverError={
                                startDate && endDate && endDate < startDate
                                    ? labels.fields.event.endDate.validation.tooEarly
                                    : undefined
                            }
                        />
                    </FormFieldset>
                    <ButtonGroup>
                        <div className="basis-1/3">
                            <Button
                                variant="text"
                                color="error"
                                disabled={!event}
                                onClick={handleOnDelete}
                                label={labels.buttons.delete}
                                fullWidth
                            />
                        </div>
                        <div className="basis-1/3">
                            <Button fullWidth variant="text" onClick={handleOnCancel} label={labels.buttons.cancel} />
                        </div>
                        <div className="basis-1/3">
                            <Form.Submit asChild>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    label={event ? labels.buttons.update : labels.buttons.add}
                                    fullWidth
                                    disabled={!startDate || !endDate || startDate > endDate}
                                />
                            </Form.Submit>
                        </div>
                    </ButtonGroup>
                </FormContent>
            </Form.Root>
        </>
    );
}
