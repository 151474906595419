import Emoji from "../components/ui/Emoji";

export const labels = {
    buttons: {
        add: "Add",
        cancel: "Cancel",
        delete: "Delete",
        edit: "Edit",
        update: "Update",
        save: "Save",
        print: "Print",
        newLayer: "New layer",
        signIn: "Sign in",
        signUp: "Sign up",
        signOut: "Sign out",
        myCalendar: "Calendar Designer",
        apply: "Apply",
        close: "Close",
        import: "Import",
    },
    titles: {
        layers: "Layers",
        accountSettings: "Account settings",
        confirm: "Are you sure?",
    },
    toolTips: {
        accountSettings: "Account settings",
        zoomButtons: "Zoom in and out",
        edit: "Edit",
        delete: "Delete",
        deleteDefaultLayer: "You cannot delete the default layer.",
        close: "Close",
        closeCalendarSettings: "Close settings panel",
    },
    menus: {
        userMenu: {
            profile: "Profile",
            password: "Password",
            components: "Components",
        },
    },
    errors: {
        unknown: "An error occurred",
        verificationLinkCorrupted: "The verification link is incorrect.",
        codeMismatch: "Invalid verification code.",
        codeExpired: "The verification code has expired.",
    },
    fields: {
        validation: {
            valueMissing: "This field is required.",
            tooShort: "Value too short",
            tooLong: "This field must not have more than 256 characters.",
            patternMismatch: "Invalid value.",
            typeMismatch: "Invalid value.",
            badInput: "Invalid value.",
            rangeOverflow: "Value too high.",
            rangeUnderflow: "Value too low.",
            stepMismatch: "Invalid value.",
            customError: "Invalid value.",
        },
        serverErrors: {
            UserNotFoundException: "We didn't find an account associated with this email in our system.",
            UnknownError: "An error occurred",
            NotAuthorizedException: "Incorrect email address or password.",
            UserNotConfirmedException: "Account not confirmed.",
            CodeMismatchException: "Invalid verification code provided, please try again.",
            UsernameExistsException: "An account with the given email already exists.",
            TooManyRequestsException: "Too many requests. Please try again later.",
        },
        email: {
            label: "Email",
            errors: {
                valueMissing: "Please enter your email.",
                typeMismatch: "Email incorrect.",
            },
        },
        name: {
            label: "Name",
        },
        password: {
            label: "Password",
            errors: {
                valueMissing: "Please enter your password.",
                tooShort: "Password must have at least 8 characters.",
                tooLong: "Password must not have more than 256 characters.",
            },
        },
        newPassword: {
            label: "New Password",
        },
        verificationCode: {
            label: "Verification Code",
            helper: {
                text: "Didn't receive a code?",
                action: "Resend code",
            },
            errors: {
                valueMissing: "Verification code is required.",
                typeMismatch: "Verification code incorrect.",
            },
        },
        date: {
            label: "Date",
            validation: {
                patternMismatch: "Please enter a valid date in the format YYYY-MM-DD.",
            },
        },
        event: {
            name: {
                label: "Name",
            },
            color: {
                label: "Color",
            },
            startDate: {
                label: "Start Date",
                helper: "The date when the event started. Defaults to the start date of the selected week.",
                validation: {
                    tooLate: "Start date must not be after end date.",
                },
            },
            endDate: {
                label: "End Date",
                helper: "The date when the event ended. Defaults to the end date of the selected week or the day before your next birthday.",
                validation: {
                    tooEarly: "End date must not be before start date.",
                },
            },
        },
        layer: {
            name: {
                label: "Name",
            },
            color: {
                label: "Color",
            },
            displayLabel: {
                label: "Display labels?",
                description: "If you turn this on, the event names will be shown on the right hand side.",
            },
            displayUserCard: {
                label: "Display profile card?",
                description:
                    "If you turn this on, your name and profile photo will be shown on the top of the calendar.",
            },
            displayLeftColumn: {
                label: "Display layer name?",
                description: "",
            },
            isDefault: {
                label: "Default layer?",
                description: "The default layer is the one that will be shown by default when you open the calendar.",
            },
        },
    },
    modals: {
        print: {
            title: "Print your life calendar",
        },
        layer: {
            new: {
                title: "New layer",
                defaultLayerWarning:
                    "You can only have one default layer. If you set this layer as default, the previous default layer will be set as non-default.",
            },
            update: {
                title: "Update layer",
            },
            delete: {
                title: "Delete layer?",
                subtitle: "Are you sure you want to delete this layer?",
            },
            import: {
                title: "Confirm import",
                subtitle: "Are you sure you want to import this layer?",
            },
        },
    },
    pages: {
        home: {
            intro: {
                title: "Start a visual journey through time",
                subtitle: "Map your milestones and moments in a unique timeline. Start now by entering your birthdate.",
            },
            calendar: {
                title: "Try it out",
                loggedIn: {
                    title: "Your life calendar",
                },
                subtitle: "",
            },
            layers: {
                title: "Your layers",
            },
            templates: {
                title: "Examples",
                subtitle: "Click on a calendar below to preview it.",
            },
            features: {
                title: "Features",
                subtitle: "",
                items: [
                    {
                        title: "Layers",
                        subtitle:
                            "Lorem Ipsum is the single greatest threat. We are not - we are not keeping up with other websites. Lorem Ipsum best not make any more threats to your website.",
                        image: {
                            src: "/images/layer.png",
                            alt: "",
                        },
                    },
                    {
                        title: "Events",
                        subtitle:
                            "Lorem Ipsum is the single greatest threat. We are not - we are not keeping up with other websites. Lorem Ipsum best not make any more threats to your website.",
                        image: {
                            src: "/images/layer.png",
                            alt: "",
                        },
                    },
                    {
                        title: "Predefined templates",
                        subtitle:
                            "Lorem Ipsum is the single greatest threat. We are not - we are not keeping up with other websites. Lorem Ipsum best not make any more threats to your website.",
                        image: {
                            src: "/images/layer.png",
                            alt: "",
                        },
                    },
                    {
                        title: "Print",
                        subtitle:
                            "Lorem Ipsum is the single greatest threat. We are not - we are not keeping up with other websites. Lorem Ipsum best not make any more threats to your website.",
                        image: {
                            src: "/images/layer.png",
                            alt: "",
                        },
                    },
                    {
                        title: "Order",
                        subtitle:
                            "Lorem Ipsum is the single greatest threat. We are not - we are not keeping up with other websites. Lorem Ipsum best not make any more threats to your website.",
                        image: {
                            src: "/images/layer.png",
                            alt: "",
                        },
                    },
                ],
            },
            examples: {
                title: "Examples",
            },
            print: {
                title: "Print your life calendar",
                subtitle: "Print selected layer",
            },
            join: {
                title: "Join to access all features",
            },
        },
        account: {
            profile: {
                sections: {
                    photo: {
                        title: "Profile photo",
                        actions: {
                            upload: "Upload",
                            remove: "Remove",
                        },
                        dialog: {
                            title: "Delete photo?",
                            subtitle: "Are you sure you want to delete your profile photo?",
                        },
                    },
                    email: {
                        title: "Email",
                    },
                    name: {
                        title: "Name",
                    },
                    deleteAccount: {
                        title: "Danger zone",
                        subtitle: "Delete your account and all your data.",
                        button: "Delete",
                        dialog: {
                            title: "Delete account?",
                            subtitle: "Are you sure you want to delete your account? This action cannot be undone.",
                        },
                        success: "Your account has been deleted.",
                    },
                },
            },
            passwordChange: {
                title: "Change password",
                form: {
                    success: "Your password has been changed.",
                    serverErrors: {
                        NotAuthorizedException: "Entered password incorrect.",
                        LimitExceededException:
                            "You have exceeded the maximum number of attempts to change your password. Please try again later.",
                    },
                    fields: {
                        oldPassword: {
                            label: "Current Password",
                        },
                        newPassword: {
                            label: "New Password",
                        },
                    },
                },
            },
        },
        auth: {
            signUp: {
                title: "Sign up",
                subtitle: "Create your account",
                button: "Sign up",
                form: {
                    fields: {
                        password: {
                            helper: "Password must be at least 8 characters long.",
                        },
                    },
                },
            },
            signUpConfirm: {
                title: "Confirm account",
                subtitle: "Enter the verification code we sent to your email to confirm your account.",
                button: "Confirm",
                messages: {
                    verificationCodeResentSuccess: "We sent a verification code to your email",
                },
            },
            signIn: {
                title: "Sign in",
                subtitle: "Welcome back.",
                button: "Sign in",
                form: {
                    errors: {
                        NotAuthorizedException: "The email and password combination is incorrect.",
                    },
                    fields: {
                        password: {
                            helper: "Forgot your password?",
                        },
                    },
                },
            },
            resetPassword: {
                title: "Forgot password?",
                subtitle:
                    "Enter the email address you used when you joined and we'll send you instructions to reset your password.",
                button: "Reset password",
            },
            resetPasswordConfirm: {
                title: "Forgot password?",
                subTitle: "Enter your new password and the verification code we sent to your email address.",
                button: "Reset password",
            },
            resetPasswordSuccess: {
                title: "Success",
                subtitle: (
                    <>
                        You successfully reset your password! <Emoji label="success" symbol="🎉" />
                    </>
                ),
                button: "Back to login",
            },
        },
    },
};
