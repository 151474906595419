import {ReactNode} from "react";

type SectionPanelProps = {
    children: ReactNode,
}

export default function SectionPanel(props: SectionPanelProps) {
    const {
        children,
    } = props

    return (
        <div className='flex gap-x-8'>
            {children}
        </div>
    )
}