import * as Avatar from '@radix-ui/react-avatar';
import classNames from "classnames";
import {ComponentSize} from "../../types";
import {useGetFileQuery, useUploadFileMutation} from "../../../redux/features/user/storageSlice";
import {useGetUserQuery} from "../../../redux/features/user/userSlice";
import {useState} from "react";
import CameraIcon from "../../ui/icons/CameraIcon";
import {addAvatarBlob, selectAvatarBlob} from "../../../redux/features/reduxSlice";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../redux/hooks";
import UserIcon from "../../ui/icons/user-icon.png";


type Props = {
    size?: ComponentSize,
    zoom?: number,
}

const MenuBarAvatar = (props: Props) => {
    const {
        size = 'medium',
        zoom,
    } = props;

    const avatarBlob = useSelector(selectAvatarBlob)
    const dispatch = useAppDispatch()

    const [inProgress, setInProgress] = useState(false)
    const {data: user} = useGetUserQuery()

    const [uploadFile] = useUploadFileMutation()
    const {
        data: url,
        isLoading,
        isFetching,
    } = useGetFileQuery()

    async function handleUploadAvatar(e: any) {
        setInProgress(true)

        const file = e.target.files[0]

        try {
            dispatch(addAvatarBlob(URL.createObjectURL(file)))
            await uploadFile({file}).unwrap()
        } catch (error) {
        }
        setInProgress(false)
    }

    const classes = getAvatarClasses(size)

    return (
        <>
            <label htmlFor="avatar">
                <Avatar.Root>
                    <div className={`group relative rounded-full ${classNames({
                        'animate-pulse': isLoading || isFetching || inProgress,
                        'cursor-pointer': !inProgress && !isLoading && !isFetching,
                        'w-8': size === 'small' && !zoom,
                        'w-12': size === 'medium' && !zoom,
                        'w-20': size === 'large' && !zoom,
                    })}`}>
                        <Avatar.Image
                            className={classes}
                            src={avatarBlob || url}
                        />
                        <AvatarFallback
                            classes={`${classes} group-hover:opacity-0 transition-opacity duration-300`}
                            userName={user?.name}
                        />
                        {
                            !(inProgress || isLoading || isFetching) && (
                                <>
                                    <div className='absolute inset-0 opacity-0 group-hover:opacity-75 transition-opacity duration-300 flex items-center justify-center bg-stone-900 rounded-full'>
                                    </div>
                                    <div className='absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center text-white'>
                                        <CameraIcon className={`aspect-square ${classNames({
                                            'w-4': size === 'small',
                                            'w-6': size === 'medium',
                                            'w-8': size === 'large',
                                        })}`}/>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Avatar.Root>
            </label>
            <input
                hidden
                id='avatar'
                type="file"
                onChange={handleUploadAvatar}
                accept="image/*"
                disabled={inProgress || isLoading || isFetching}
            />
        </>
    )
}

const MyAvatar = (props: Props) => {
    const {
        size = 'medium',
    } = props;

    const {data: user} = useGetUserQuery()

    const {
        data: url,
    } = useGetFileQuery()

    const classes = getAvatarClasses(size)

    return (
        <Avatar.Root>
            <Avatar.Image
                className={classes}
                src={url}
            />
            <AvatarFallback
                classes={classes}
                userName={user?.name}
            />
        </Avatar.Root>
    )
}


type AvatarFallbackProps = {
    classes?: string,
    userName?: string,
}

const AvatarFallback = (props: AvatarFallbackProps) => {
    const {
        classes,
        userName,
    } = props;

    const initials = userName?.split(' ').slice(0, 2).map((name) => name[0]).join('')

    return (
        <Avatar.Fallback className={classes}>
            {
                initials ? initials : <img src={UserIcon} alt="user icon"/>
            }
        </Avatar.Fallback>
    )
}

function getAvatarClasses(size: ComponentSize) {
    return `flex items-center justify-center aspect-square font-bold text-white bg-stone-400 rounded-full ${classNames({
        'w-10 text-xxs': size === 'small',
        'w-12 text-xs': size === 'medium',
        'w-20 text-xs': size === 'large',
    })}`
}

export default MenuBarAvatar;
export {MyAvatar}