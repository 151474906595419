import {configureStore} from '@reduxjs/toolkit'
import toastReducer from "./features/toast/toastSlice";
import reduxReducer from "./features/reduxSlice";
import {cognitoApi} from "./features/user/userSlice";
import {storageApi} from "./features/user/storageSlice";
import {apiSlice} from "./features/api/apiSlice";
import {setupListeners} from "@reduxjs/toolkit/query";

export interface ExtraArgument {
    apiName: string;
}

const extraArgument: ExtraArgument = {
    apiName: 'apilifecalendar',
}

export const store = configureStore({
    reducer: {
        toast: toastReducer,
        redux: reduxReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        [cognitoApi.reducerPath]: cognitoApi.reducer,
        [storageApi.reducerPath]: storageApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: extraArgument,
            }
        })
            .concat(apiSlice.middleware)
            .concat(cognitoApi.middleware)
            .concat(storageApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)