import {ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import Typography from "../../../components/ui/typography/Typography";
import {ArrowLeftIcon} from "@radix-ui/react-icons";

interface Props {
    title: ReactNode;
    subTitle?: ReactNode;
    headerLink?: any;
    banner?: ReactNode;
    children: any;
}

const AuthLayout = (props : Props) => {
    const {
        headerLink,
        title,
        subTitle,
        banner,
        children,
    } = props;

    const navigate = useNavigate();

    function handleBack() {
        navigate(-1)
    }

    return (
        <>
            <div className='absolute inset-x-0 top-0 flex justify-between h-20 items-center text-sm md:text-base'>
                <div>
                    <button
                        className='group flex gap-x-2 items-center justify-between'
                        onClick={handleBack}
                    >
                        <ArrowLeftIcon className={classNames(
                            'h-4 w-4',
                            'transition-transform duration-100 group-hover:-translate-x-0.5',
                        )}/>
                        <div>Back</div>
                    </button>
                </div>
                <div>
                    { headerLink }
                </div>
            </div>
            <div className='grow flex flex-col justify-center items-center'>
                <div className='max-w-lg w-full'>
                    <div className='mb-10'>
                        <div className='mb-4'>
                            <h1 className='font-bold text-4xl'>{title}</h1>
                        </div>
                        {
                            subTitle && (
                                <Typography variant='subtitle1'>{subTitle}</Typography>
                            )
                        }
                    </div>
                    <div className='mb-6'>
                        { banner }
                    </div>
                    { children }
                </div>
            </div>
        </>
    )
}

export {AuthLayout}