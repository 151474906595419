import * as Form from "@radix-ui/react-form";
import { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmailField from "../../../components/form/EmailField";
import FormContent from "../../../components/form/FormContent";
import { FormFieldset } from "../../../components/form/FormFieldset";
import FormMessage from "../../../components/form/FormMessage";
import PasswordField from "../../../components/form/PasswordField";
import FormFieldHelper from "../../../components/form/components/FormFieldHelper";
import Button from "../../../components/ui/buttons/Button";
import { ButtonGroup } from "../../../components/ui/buttons/ButtonGroup";
import { labels } from "../../../data/labels";
import { AuthLayout } from "../../../features/authentication";
import { AlreadyAMemberLink } from "../../../features/authentication/components/AlreadyMemberLink";
import { extractServerError } from "../../../redux/features/api/serverErrorsHelper";
import { useResendSignUpMutation, useSignUpMutation } from "../../../redux/features/user/userSlice";

export default function SignUp() {
    const [formError, setFormError] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [signUp] = useSignUpMutation();
    const [resendSignUp] = useResendSignUpMutation();

    const navigate = useNavigate();

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setInProgress(true);

        try {
            const data = (await signUp({ email, password }).unwrap()) as any as string;

            navigate("confirm", {
                state: { email, calendarID: data },
            });
        } catch (error: any) {
            console.error("Error signing up: ", error);
            const myError = extractServerError(error);

            switch (myError.code) {
                case "UsernameExistsException":
                    setFormError(labels.fields.serverErrors.UsernameExistsException);
                    break;
                default:
                    setFormError("Labels.unexpectedError");
                    break;
            }
        }

        setInProgress(false);
    }

    function handleClearServerErrors() {
        setFormError("");
    }

    function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        setPassword(event.currentTarget.value);
    }

    return (
        <AuthLayout
            title={labels.pages.auth.signUp.title}
            subTitle={labels.pages.auth.signUp.subtitle}
            headerLink={<AlreadyAMemberLink />}
            banner={
                formError && (
                    <FormMessage severity={"error"} onClose={handleClearServerErrors}>
                        {formError}
                    </FormMessage>
                )
            }
        >
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    <FormFieldset>
                        <EmailField value={email} onChange={handleEmailChange} required size={"large"} />
                        <PasswordField value={password} onChange={handlePasswordChange} required size={"large"} />
                    </FormFieldset>
                    <ButtonGroup>
                        <Form.Submit asChild>
                            <div className="flex basis-full sm:basis-1/2 justify-end">
                                <Button
                                    fullWidth
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    inProgress={inProgress}
                                    label={labels.pages.auth.signUp.button}
                                />
                            </div>
                        </Form.Submit>
                    </ButtonGroup>
                    <FormFieldHelper justify="center">
                        <span>By creating an account you agree with our </span>
                        <Link className="underline underline-offset-2" to={"/terms-of-service"}>
                            Terms of Service
                        </Link>
                        <span> and </span>
                        <Link className="underline underline-offset-2" to={"/privacy"}>
                            Privacy Policy
                        </Link>
                        <span>.</span>
                    </FormFieldHelper>
                </FormContent>
            </Form.Root>
        </AuthLayout>
    );
}
