import * as Toast from '@radix-ui/react-toast';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify, Analytics, Logger} from 'aws-amplify';
import awsExports from './aws-exports';
import * as Tooltip from '@radix-ui/react-tooltip';
import {Provider} from "react-redux";
import {store} from "./redux/store";

Amplify.configure(awsExports);
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const logger = new Logger('index');

if (window.location.hostname !== 'www.lifecalendar.io') {
    logger.info(`Disabling analytics for ${window.location.hostname}`)
    Analytics.disable();
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <Toast.Provider duration={5 * 1000}>
              <Tooltip.Provider>
                  <App />
              </Tooltip.Provider>
          </Toast.Provider>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
