import {ReactNode} from "react";

type Props = {
    children: ReactNode,
}
export function FormFieldset(props: Props) {
    const {
        children,
    } = props;

    return (
        <div className='grid gap-y-6'>
            { children }
        </div>
    )
}