import {Outlet} from "react-router-dom";
import Logo from "../../../components/Logo";
import {AuthImage} from "../index";

const authImage = {
    image: AuthImage,
    ratioW: 3,
    ratioH: 5,
    author: {
        name: 'Christian Grab',
        link: 'https://unsplash.com/@christianmategrab',
    },
}

const AuthRoot = () => {
    return (
        <div className='h-screen overflow-hidden flex'>
            <div className='hidden sm:block basis-1/3'>
                <div className='bg-stone-100 h-full relative'>
                    <div className='absolute h-20 top-0 left-4 flex items-center'>
                        <Logo />
                    </div>
                    <img className='w-full h-full object-cover object-center' src={authImage.image} alt=""/>
                    <div className='absolute bottom-4 left-4 text-white opacity-90 text-xxs'>
                        Photo by <a className='hover:underline' href={authImage.author.link} target='_blank' rel='noreferrer'>{authImage.author.name}</a>
                    </div>
                </div>
            </div>
            <div className='basis-full sm:basis-2/3 px-6 sm:px-8 bg-stone-100'>
                <div className='flex flex-col h-full relative'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export {AuthRoot}