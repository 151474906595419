import * as Form from '@radix-ui/react-form';
import ButtonsDemo from "../../features/demo/ButtonsDemo";
import Grid from "../../features/demo/components/Grid";
import React, {useState} from "react";
import StyledSwitch from "../../components/form/StyledSwitch";
import ButtonLinkDemo from "../../features/demo/ButtonLinkDemo";
import IconButtonsDemo from "../../features/demo/IconButtonsDemo";
import IconLinkButton from "../../components/ui/buttons/IconLinkButton";
import InputsDemo from "../../features/demo/InputsDemo";
import ModalsDemo from "../../features/demo/ModalsDemo";
import DatePickersDemo from "../../features/demo/DatePickersDemo";
import {ArrowLeftIcon} from "@radix-ui/react-icons";

export default function ComponentDemoRoute() {
    const [showGrid, setShowGrid] = useState(false)
    return (
        <div className='min-h-screen relative'>
            <div className='absolute left-4 top-4'>
                <IconLinkButton variant='text' size='large' to='/' icon={<ArrowLeftIcon/>} />
            </div>
            <div className='h-full w-screen max-w-5xl mx-auto relative'>
                <div className='absolute top-8 right-8'>
                    <Form.Root>
                        <StyledSwitch
                            name='showGrid'
                            label={''}
                            isChecked={showGrid}
                            setIsChecked={(enabled) => setShowGrid(enabled)}
                        />
                    </Form.Root>
                </div>
                <Grid disabled={!showGrid}>
                    <div className='grid gap-y-12'>
                        <InputsDemo />
                        <IconButtonsDemo />
                        <ButtonsDemo />
                        <ButtonLinkDemo />
                        <ModalsDemo />
                        <DatePickersDemo />
                    </div>
                </Grid>
            </div>
        </div>
    )
}