import {ReactNode} from "react";

type Props = {
    extraClasses?: string,
    children: ReactNode,
}

export default function LayerCardContainer(props: Props) {
    const {
        extraClasses,
        children,
    } = props;

    return (
        <div className={`flex items-center py-2 aspect-[7/1] sm:aspect-[3/1] gap-x-2 px-4 rounded-xl ${extraClasses}`}>
            {children}
        </div>
    )
}