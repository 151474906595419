import {ReactNode} from "react";
import classNames from "classnames";

type GridProps = {
    children: ReactNode,
    disabled?: boolean,
}
export default function Grid(props: GridProps) {
    const {
        children,
        disabled = false,
    } = props

    const gridColor = '#d6d3d1'; // stone-300

    return (
        <div className={`h-full px-8 pt-8 pb-24 bg-stone-100 ${classNames({
            'border-r border-stone-300': !disabled,
        })}`} style={{
            backgroundSize: '16px 16px',
            backgroundImage: !disabled ? `linear-gradient(to right, ${gridColor} 1px, transparent 1px), linear-gradient(to bottom, ${gridColor} 1px, transparent 1px)`: '',
        }}>
            {children}
        </div>
    )
}