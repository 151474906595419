import * as Form from '@radix-ui/react-form';
import {useChangePasswordMutation, useGetUserQuery} from "../../redux/features/user/userSlice";
import {extractServerError} from "../../redux/features/api/serverErrorsHelper";
import {ChangeEvent, FormEvent, useState} from "react";
import {labels} from "../../data/labels";
import {FormFieldset} from "../../components/form/FormFieldset";
import PasswordField from "../../components/form/PasswordField";
import StyledLink from "../../components/ui/StyledLink";
import Button from "../../components/ui/buttons/Button";
import FormContent from "../../components/form/FormContent";
import {ButtonGroup} from "../../components/ui/buttons/ButtonGroup";
import Section from "../../features/settings/components/Section";
import FormFieldHelper from "../../components/form/components/FormFieldHelper";
import MessageBanner from "../../components/form/MessageBanner";


export default function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordServerError, setCurrentPasswordServerError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [formError, setFormError] = useState('')
    const [formSuccess, setFormSuccess] = useState('')

    const [changePassword] = useChangePasswordMutation()
    const {data} = useGetUserQuery()

    function resetForm() {
        setCurrentPassword('')
        setNewPassword('')
        setCurrentPasswordServerError('')
        setFormError('');
        setFormSuccess('')
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()
        setInProgress(true)

        try {
            await changePassword({oldPassword: currentPassword, newPassword}).unwrap()
            resetForm()
            setFormSuccess(labels.pages.account.passwordChange.form.success)
        } catch (e: any) {
            const myError = extractServerError(e)

            switch (myError.code) {
                case 'LimitExceededException':
                    setFormError(labels.pages.account.passwordChange.form.serverErrors.LimitExceededException)
                    break
                case 'NotAuthorizedException':
                    setCurrentPasswordServerError(labels.pages.account.passwordChange.form.serverErrors.NotAuthorizedException)
                    break
                default:
                    setFormError(labels.errors.unknown)
            }
        }

        setInProgress(false)
    }

    function handleOldPasswordChange(event : ChangeEvent<HTMLInputElement>) {
        setCurrentPassword(event.target.value);
        setCurrentPasswordServerError('')
    }

    function handleNewPasswordChange(event: ChangeEvent<HTMLInputElement>) {
        setNewPassword(event.target.value);
    }

    function handleClearServerErrors() {
        setCurrentPasswordServerError('')
        setFormError('')
        setFormSuccess('')
    }

    return (
        <Section title={labels.pages.account.passwordChange.title}>
            <MessageBanner
                formSuccess={formSuccess}
                formError={formError}
                onClose={handleClearServerErrors}
            />
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    <FormFieldset>
                        <PasswordField
                            name='currentPassword'
                            value={currentPassword}
                            onChange={handleOldPasswordChange}
                            label={labels.pages.account.passwordChange.form.fields.oldPassword.label}
                            required
                            helper={(
                                <FormFieldHelper>
                                    <StyledLink to='/reset-password' state={{email: data?.email}}>Forgot password?</StyledLink>
                                </FormFieldHelper>
                            )}
                            serverError={currentPasswordServerError}
                        />
                        <PasswordField
                            name='newPassword'
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            required
                            label={labels.pages.account.passwordChange.form.fields.newPassword.label}
                            validate={(value, formData) => value === formData.get('currentPassword')}
                            errorMessages={{customError: 'New password must be different from current password.'}}
                        />
                    </FormFieldset>
                    <ButtonGroup>
                        <Form.Submit asChild>
                            <div className='basis-full sm:basis-1/2 md:basis-1/3'>
                                <Button type='submit' fullWidth inProgress={inProgress} label={labels.buttons.update} />
                            </div>
                        </Form.Submit>
                    </ButtonGroup>
                </FormContent>
            </Form.Root>
        </Section>
    )
}