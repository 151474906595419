import {isRouteErrorResponse} from "react-router-dom";

type ErrorResponse = {
    errorTitle: string;
    errorMessage: string;
}

export default function getRouteError(error: unknown): ErrorResponse {
    let errorTitle: string = 'Oops!';
    let errorMessage: string

    if (isRouteErrorResponse(error)) {
        // error is type `ErrorResponse`
        if (error.status === 404) {
            errorTitle = 'Page not found';
            errorMessage = 'The page you were looking for could not be found.';
        } else {
            errorMessage = error.error?.message || error.statusText;
        }
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = 'Unknown error';
    }

    return {
        errorTitle,
        errorMessage
    }
}