import React from 'react';
import StyledLink from "../ui/StyledLink";
import Logo from "../Logo";

export default function Footer() {
    return (
        <footer className="flex flex-col items-center justify-center gap-5 text-center px-4 py-10">
            <div>
                <Logo />
            </div>
            <div className="text-xs font-medium">
                <div className="flex justify-center gap-x-4">
                    <StyledLink to='/privacy'>Privacy</StyledLink>
                    <StyledLink to='/terms-of-service'>Terms of Service</StyledLink>
                    <a className='underline-offset-2 hover:underline' href='https://twitter.com/andrzej__miller' target="_blank" rel='noreferrer'>Contact</a>
                </div>
            </div>
            <div className='text-xxs'>All rights reserved - Life Calendar</div>
        </footer>
    )
}