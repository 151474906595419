import Header from "../components/layouts/Header";
import {Outlet} from "react-router-dom";
import Footer from "../components/layouts/Footer";

export default function Root() {
    return (
        <div className='min-h-screen flex flex-col bg-stone-100'>
            <Header />
            <main className='grow flex flex-col'>
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}