import Box from "./Box";
import {ReactNode} from "react";
import classNames from "classnames";

type Props = {
    children: ReactNode,
    shadow?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'none',
    className?: string,
}

export default function Paper(props: Props) {
    const {
        children,
        shadow = '',
        className = 'rounded bg-white',
    } = props

    return (
        <Box className={classNames(
            className,
            classNames({
                'shadow-sm': shadow === 'sm',
                'shadow': shadow === '',
                'shadow-md': shadow === 'md',
                'shadow-lg': shadow === 'lg',
                'shadow-xl': shadow === 'xl',
                'shadow-2xl': shadow === '2xl',
                'shadow-none': shadow === 'none',
            })
        )}>
            {children}
        </Box>
    )
}