import * as Form from '@radix-ui/react-form';
import {AuthLayout} from '../../../features/authentication'
import {useLocation, useNavigate} from "react-router-dom";
import {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useForgotPasswordMutation} from "../../../redux/features/user/userSlice";
import {extractServerError} from "../../../redux/features/api/serverErrorsHelper";
import {NotAMemberLink} from "../../../features/authentication/components/NotAMemberLink";
import {FormFieldset} from "../../../components/form/FormFieldset";
import EmailField from "../../../components/form/EmailField";
import {labels} from "../../../data/labels";
import Button from "../../../components/ui/buttons/Button";
import FormContent from "../../../components/form/FormContent";
import {ButtonGroup} from "../../../components/ui/buttons/ButtonGroup";
import FormMessage from "../../../components/form/FormMessage";


export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [formError, setFormError] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [forgotPassword] = useForgotPasswordMutation();

    useEffect(() => {
        setEmail(location.state?.email)
    }, [location.state?.email]);

    async function handleSubmit(e: FormEvent ) {
        e.preventDefault();
        setInProgress(true);

        try {
            await forgotPassword({email}).unwrap();
            navigate('confirm', {
                state: { email }
            })
        } catch (e: any) {
            const myError = extractServerError(e)

            switch (myError.code) {
                case 'UserNotFoundException':
                    setFormError(labels.fields.serverErrors.UserNotFoundException)
                    break
                default:
                    setFormError(labels.errors.unknown)
            }
        }

        setInProgress(false);
    }

    function handleClearServerErrors() {
        setFormError('')
    }

    function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    return (
        <AuthLayout
            title={labels.pages.auth.resetPassword.title}
            subTitle={labels.pages.auth.resetPassword.subtitle}
            headerLink={<NotAMemberLink />}
            banner={formError && (
                <FormMessage severity={'error'} onClose={handleClearServerErrors}>{formError}</FormMessage>
            )}
        >
            <Form.Root onSubmit={handleSubmit} onClearServerErrors={handleClearServerErrors}>
                <FormContent>
                    <FormFieldset>
                        <EmailField value={email} onChange={handleEmailChange} size={'large'} required />
                    </FormFieldset>
                    <ButtonGroup>
                        <Form.Submit asChild>
                            <div className='basis-full sm:basis-1/2 justify-end'>
                                <Button fullWidth label={labels.pages.auth.resetPassword.button} size={'large'} type="submit" variant="contained" inProgress={ inProgress } />
                            </div>
                        </Form.Submit>
                    </ButtonGroup>
                </FormContent>
            </Form.Root>
        </AuthLayout>
    )
}