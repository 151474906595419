import SectionTitle from "./components/SectionTitle";
import SectionPanel from "./components/SectionPanel";
import Section from "./components/Section";
import SectionGroup from "./components/SectionGroup";
import IconButton from "../../components/ui/buttons/IconButton";
import {Cross1Icon, GearIcon, Pencil1Icon, PlusIcon, TrashIcon} from "@radix-ui/react-icons";
import IconLinkButton from "../../components/ui/buttons/IconLinkButton";

export default function IconButtonsDemo() {
    return (
        <>
            <SectionGroup>
                <SectionTitle>Icon Buttons</SectionTitle>
                <Section>
                    <SectionPanel>
                        <IconButton
                            size='small'
                            variant='text'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='small'
                            variant='outlined'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='small'
                            variant='contained'
                            icon={<PlusIcon/>}
                        />
                    </SectionPanel>
                    <SectionPanel>
                        <IconButton
                            size='medium'
                            variant='text'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='medium'
                            variant='outlined'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='medium'
                            variant='contained'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='medium'
                            variant='outlined'
                            icon={<TrashIcon/>}
                        />
                        <IconButton
                            size='medium'
                            variant='outlined'
                            icon={<Cross1Icon/>}
                        />
                        <IconButton
                            size='medium'
                            variant='outlined'
                            icon={<Pencil1Icon/>}
                        />
                    </SectionPanel>
                    <SectionPanel>
                        <IconButton
                            size='large'
                            variant='text'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='large'
                            variant='outlined'
                            icon={<PlusIcon/>}
                        />
                        <IconButton
                            size='large'
                            variant='contained'
                            icon={<PlusIcon/>}
                        />
                    </SectionPanel>
                    <SectionPanel>
                        <IconButton
                            size='small'
                            variant='outlined'
                            icon={<PlusIcon/>}
                            disabled
                        />
                        <IconButton
                            size='small'
                            variant='contained'
                            icon={<PlusIcon/>}
                            disabled
                        />
                    </SectionPanel>
                    <SectionPanel>
                        <IconButton
                            size='medium'
                            variant='outlined'
                            icon={<PlusIcon/>}
                            disabled
                        />
                        <IconButton
                            size='medium'
                            variant='contained'
                            icon={<PlusIcon/>}
                            disabled
                        />
                    </SectionPanel>
                    <SectionPanel>
                        <IconButton
                            size='large'
                            variant='outlined'
                            icon={<PlusIcon/>}
                            disabled
                            tooltip='large outlined disabled icon button tooltip'
                        />
                        <IconButton
                            size='large'
                            variant='contained'
                            icon={<PlusIcon/>}
                            disabled
                            tooltip='large contained disabled icon button tooltip'
                        />
                    </SectionPanel>
                    <SectionPanel>
                        <IconButton
                            size='small'
                            variant='text'
                            icon={<PlusIcon className='text-rose-600'/>}
                            color={'error'}
                            tooltip='small text error icon button tooltip'
                        />
                        <IconButton
                            size='small'
                            variant='contained'
                            icon={<PlusIcon className='text-rose-600'/>}
                            color={'error'}
                            tooltip='small contained icon button tooltip'
                        />
                    </SectionPanel>
                </Section>
            </SectionGroup>
            <SectionGroup>
                <SectionTitle>Icon Link Buttons +tooltips</SectionTitle>
                <SectionPanel>
                    <IconLinkButton
                        size='small'
                        variant='text'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='small text icon link button tooltip'
                    />
                    <IconLinkButton
                        size='small'
                        variant='outlined'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='small outlined icon link button tooltip'
                    />
                    <IconLinkButton
                        size='small'
                        variant='contained'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='small contained icon link button tooltip'
                    />
                </SectionPanel>
                <SectionPanel>
                    <IconLinkButton
                        size='medium'
                        variant='text'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='medium text icon link button tooltip'
                    />
                    <IconLinkButton
                        size='medium'
                        variant='outlined'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='medium outlined icon link button tooltip'
                    />
                    <IconLinkButton
                        size='medium'
                        variant='contained'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='medium contained icon link button tooltip'
                    />
                </SectionPanel>
                <SectionPanel>
                    <IconLinkButton
                        size='large'
                        variant='text'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='large text icon link button tooltip'
                    />
                    <IconLinkButton
                        size='large'
                        variant='outlined'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='large outlined icon link button tooltip'
                    />
                    <IconLinkButton
                        size='large'
                        variant='contained'
                        icon={<GearIcon />}
                        to='/'
                        tooltip='large contained icon link button tooltip'
                    />
                </SectionPanel>
            </SectionGroup>
        </>
    )
}