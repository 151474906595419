import * as Form from '@radix-ui/react-form';
import {FormFieldProps} from '@radix-ui/react-form';
import {Switch} from '@headlessui/react'
import classNames from "classnames";
import {ReactNode} from "react";

type Props = {
    label: ReactNode;
    message?: ReactNode;
    description?: string;
    isChecked?: boolean;
    setIsChecked?: (enabled: boolean) => void;
    disabled?: boolean;
} & FormFieldProps

export default function StyledSwitch(props: Props) {
    const {
        label,
        message,
        description,
        isChecked,
        setIsChecked,
        disabled,
        name,
    } = props

    return (
        <Form.Field name={name}>
            <Switch.Group>
                <div className='flex flex-col gap-3'>
                    {
                        message &&
                        <div className='px-3'>
                            {message}
                        </div>
                    }
                    <div className={classNames(
                        'flex items-center justify-between gap-x-4 bg-white px-3 py-2 rounded-2xl',
                        'border border-stone-300',
                        {
                            'hover:border-stone-400 focus:border-stone-400': !disabled,
                        },
                        'sm:px-4 sm:py-3 sm:gap-x-8'
                    )}>
                        <div className='grow flex flex-col gap-y-2'>
                            <Switch.Label className='mr-4 text-xs text-stone-950 font-medium'>{label}</Switch.Label>
                        </div>
                        <Switch
                            checked={isChecked}
                            onChange={setIsChecked}
                            disabled={disabled}
                            className={classNames(
                                'relative inline-flex h-6 w-10 items-center rounded-full flex-none',
                                classNames({
                                    'bg-stone-900': isChecked,
                                    'bg-stone-300': !isChecked,
                                    'opacity-30 cursor-not-allowed': disabled,
                                })
                            )}
                        >
                    <span
                        className={classNames(
                            'inline-block h-6 w-6 transform rounded-full bg-white transition',
                            'border-2',
                            classNames({
                                'translate-x-4 border-stone-900': isChecked,
                                'translate-x-0 border-stone-300': !isChecked,
                            })
                        )}
                    />
                        </Switch>
                    </div>
                    {
                        description &&
                        <Switch.Description className='text-xs font-light px-3'>
                            {description}
                        </Switch.Description>
                    }
                </div>
            </Switch.Group>
        </Form.Field>
    )
}