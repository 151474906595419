import {Analytics, Auth, Logger, Storage} from "aws-amplify";
import {BaseQueryFn, createApi} from "@reduxjs/toolkit/query/react";
import {MyError} from "../api/serverErrorsHelper";
import {getErrorToast} from "../../../utils/toastUtils";
import {pushToast} from "../toast/toastSlice";


type UploadFileInput = {
    file: any;
}

type GetFileInput = {
    userID?: string;
}

const logger = new Logger('storageSlice')

const storageBaseQuery: BaseQueryFn<
    {
        uploadFileInput?: UploadFileInput,
        getFileInput?: GetFileInput | void,
    }, // Args
    unknown, // Result
    MyError, // Error
    { shout?: boolean }, // DefinitionExtraOptions
    { timestamp: number } // Meta
> = async (arg, api) => {
    logger.debug('storageBaseQuery -> arg: ', arg)

    Analytics.record({
        name: 'storage',
        attributes: {
            endpoint: api.endpoint,
        }
    })

    try {
        switch (api.endpoint) {
            case 'uploadFile': {
                const user = await Auth.currentAuthenticatedUser();
                if (!arg.uploadFileInput) {
                    return {
                        error: {
                            code: 'InvalidParameterException',
                        }
                    }
                }

                await Storage.put(user.attributes.sub, arg.uploadFileInput.file, {
                    contentType: arg.uploadFileInput.file.type, // contentType is optional
                });

                await Auth.updateUserAttributes(user, Object.assign(user.attributes, {
                    picture: user.attributes.sub,
                }))

                return {
                    data: user.attributes.sub,
                }
            }
            case 'getFile': {
                let user: undefined | any
                try {
                    user = await Auth.currentAuthenticatedUser();
                } catch (e) {

                }

                try {
                    const defaultKey = user?.attributes?.picture
                    const keyFromParams = arg.getFileInput?.userID

                    if (!defaultKey && !keyFromParams) {
                        return {
                            data: null,
                        }
                    }

                    const getFileResponse = await Storage.get(defaultKey || keyFromParams, {
                        expires: 3600,
                        cacheControl: 'max-age=31536000000',
                        validateObjectExistence: true,
                    });

                    return {
                        data: getFileResponse,
                    }
                } catch (e) {
                    logger.error('storageBaseQuery -> getFile -> error: ', e)
                    return {
                        data: null,
                    }
                }
            }
            case 'removeFile':
                const user = await Auth.currentAuthenticatedUser();
                await Storage.remove(user.attributes.sub);
                await Auth.updateUserAttributes(user, Object.assign(user.attributes, {
                    picture: '',
                }))

                break
            default:
                return {
                    error: {
                        code: 'UnsupportedOperationException',
                        reason: `Unsupported operation: ${api.endpoint}`,
                    }
                }
        }
    } catch (error: any) {
        logger.error('storageBaseQuery -> error: ', error)

        const toast = getErrorToast(error)
        if (toast) {
            api.dispatch(pushToast(toast))
        }

        let errorPayload

        if (typeof error === 'string') {
            errorPayload = {
                code: 'ErrorCodeNotPresent',
                reason: error,
            }
        } else {
            errorPayload = {
                code: error.name,
                reason: error.message,
            }
        }

        Analytics.record({
            name: 'storageError',
            attributes: {
                endpoint: api.endpoint,
                error: errorPayload.reason,
            }
        })

        return {
            error: errorPayload,
        }
    }

    return { data: 'success' }
}


export const storageApi = createApi({
    reducerPath: 'storageApi',
    baseQuery: storageBaseQuery,
    tagTypes: ['File'],
    endpoints: (builder) => ({
        uploadFile: builder.mutation<any, UploadFileInput>({
            query: (uploadFileInput) => ({uploadFileInput}),
            invalidatesTags: ['File'],
        }),
        getFile: builder.query<any, GetFileInput | void>({
            query: (getFileInput) => ({getFileInput}),
            providesTags: ['File'],
        }),
        removeFile: builder.mutation<any, void>({
            query: () => ({}),
            invalidatesTags: ['File'],
        }),
    }),
})

export const {
    useGetFileQuery,
    useUploadFileMutation,
    useRemoveFileMutation,
} = storageApi