import {useState} from "react";
import StyledDialog from "../../../../components/dialogs/StyledDialog";
import placesLivedImage from "./data/places-Lived.jpg"
import travelsImage from "./data/travels.jpg"
import stagesOfLifeImage from "./data/stages-of-life.jpg"
import {labels} from "../../../../data/labels";
import Typography from "../../../../components/ui/typography/Typography";
import Stack from "../../../../components/layouts/Stack";


type Image = {
    title: string,
    image: any
}

const images = [
    {
        title: 'Places I\'ve Lived',
        image: placesLivedImage
    },
    {
        title: 'Travels',
        image: travelsImage
    },
    {
        title: 'Stages of Life',
        image: stagesOfLifeImage
    },
]

export default function Examples() {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedExample, setSelectedExample] = useState<Image>(images[0])

    function onImageClick(example: Image) {
        setSelectedExample(example)
        setIsDialogOpen(true)
    }

    return (
        <Stack spacing='large'>
            <Typography variant='h2' center>{labels.pages.home.examples.title}</Typography>
            <div className='grid grid-cols-3 gap-3 md:gap-6 lg:gap-8'>
                {
                    images.map((example, index) => {
                        return (
                            <div className='shadow-2xl basis-1/3 xs:w-full mx-auto relative' key={index}>
                                <div className='flex items-center gap-4 cursor-pointer hover:scale-105 transition-all duration-300'>
                                    <img
                                        className=''
                                        src={example.image}
                                        alt={example.title}
                                        onClick={() => onImageClick(example)}
                                    />
                                </div>
                                <div className='pt-3 lg:pt-8 absolute inset-x-0 text-center text-xxs opacity-70 uppercase'>
                                    {example.title}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <StyledDialog
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                disableHeader={true}
                disablePadding={true}
                size={'large'}
            >
                <img
                    src={selectedExample.image}
                    alt={selectedExample.title}
                />
            </StyledDialog>
        </Stack>
    )
}