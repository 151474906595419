import Stack from "../../../components/layouts/Stack";
import Typography from "../../../components/ui/typography/Typography";
import {labels} from "../../../data/labels";
import LinkButton from "../../../components/ui/buttons/LinkButton";

export default function CallToAction() {
    return (
        <Stack spacing='large' center>
            <Typography center variant='h2'>{labels.pages.home.join.title}</Typography>
            <div className="w-full flex gap-4 justify-center items-center max-w-prose">
                <div className='basis-1/2'>
                    <LinkButton
                        to='/login'
                        size={'large'}
                        fullWidth
                        variant='outlined'
                        label={labels.buttons.signIn}
                    />
                </div>
                <div className='basis-1/2'>
                    <LinkButton
                        to='/join'
                        size={'large'}
                        fullWidth
                        variant='contained'
                        label={labels.buttons.signUp}
                    />
                </div>
            </div>
        </Stack>
    )
}