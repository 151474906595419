import React, {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {LayerOutput, LifeEvent} from "../../../../../amplify/backend/function/lifecalendarlayers/ts/model";
import PaperSizePicker, {PaperSizeOption} from "./PaperSizePicker";
import LifeCalendar from "../../../life-calendar/LifeCalendar";
import Button from "../../../../components/ui/buttons/Button";
import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@radix-ui/react-icons";
import {PAPER_SIZE_MULTIPLE} from "../../../../utils/getFitCalendarZoom";
import {Analytics} from "aws-amplify";


type Props = {
    layer?: LayerOutput,
    birthdate?: string,
    events?: LifeEvent[],
}

export default function Print(props: Props) {
    const {
        layer,
        birthdate,
        events,
    } = props;

    const [canRender, setCanRender] = useState(false);
    const [resolve, setRevolve] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [paperSize, setPaperSize] = useState<PaperSizeOption>();

    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        // @ts-ignore
        content: () => componentRef.current,
        documentTitle: `lifecalendar.io - ${layer?.name} (${paperSize?.id})`,
        onBeforeGetContent: () => {
            Analytics.record({
                name: 'print',
                attributes: {
                    layer: layer?.layerID || '',
                    birthdate: birthdate || '',
                    paperSize: paperSize?.id || '',
                },
            })

            return new Promise((resolve) => {
                setLoading(true);
                setCanRender(true)
                // @ts-ignore
                setRevolve(resolve);
            })
        },
        onAfterPrint: () => {
            setLoading(false);
            setCanRender(false)
        }
    });

    useEffect(() => {
        // @ts-ignore
        resolve && resolve();
    }, [canRender, resolve]);

    function getSize() {
        const BASE = 793.7007874 / PAPER_SIZE_MULTIPLE;
        let size = BASE;

        switch (paperSize?.id) {
            case 'A4': // 210 x 297 => 793.7007874 x 1122.519685
                size = BASE;
                break;
            case 'A3': // 297 x 420 =>
                size = (297 * BASE) / 210;
                break;
            case 'A2': // 420 x 594 =>
                size = BASE * 2;
                break;
            case 'A1': // 594 x 841 =>
                size = (594 * 2 * BASE) / 420;
                break;
            case 'A0': // 841 x 1189 =>
                size = BASE * 4;
                break;
            default:
                throw new Error(`Paper size not supported ${paperSize}`);
        }

        return size;
    }

    return (
        <div className='flex flex-col gap-6 md:gap-10'>
            <div className='text-xs xs:text-sm lg:text-lg'>
                Select your desired size and press the print button. In the Print window make sure to select <strong>portrait</strong> orientation and the correct <strong>paper size</strong>.
            </div>
            <PaperSizePicker onChange={setPaperSize}/>
            <div className='py-4 w-full xs:w-1/2 sm:w-1/3 mx-auto text-center'>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handlePrint}
                    disabled={paperSize === null || !paperSize}
                    inProgress={loading}
                    size='large'
                    label='Print'
                />
            </div>
            <div className="flex flex-col gap-2 sm:gap-4">
                <Disclosure defaultOpen>
                    {({open}) => (
                        <>
                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg px-2 py-1.5 bg-stone-200 hover:bg-stone-300 text-left text-xs font-medium focus:outline-none focus-visible:ring focus-visible:ring-stone-500/75">
                            <div className='flex items-center gap-2'>
                                    <div className='text-xs px-3 py-1 text-white bg-rose-600 rounded-full'>
                                        Beta
                                    </div>
                                    <div>
                                        <div className='font-bold'>This is a beta feature</div>
                                        <div className='text-xxs'>
                                            This feature is still in development and might not work as expected. Click for more.
                                        </div>
                                    </div>
                                </div>
                                <ChevronUpIcon
                                    className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-stone-500`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm flex flex-col gap-2 sm:gap-3">
                                <div className='font-bold text-xs'>Tested on:</div>
                                <ul className='list-disc list-outside pl-3 text-xxs'>
                                    <li>Chrome 118.0.5993.88 (arm64)</li>
                                    <li>Firefox 119.0 (64-bit)</li>
                                    <li>Opera One 104.0.4944.28 (arm64)</li>
                                </ul>
                                <div className='text-xxs'>
                                    (Tested on macOS Sonoma, Version 14.0, arm64)
                                </div>
                                <div className='font-bold text-xs'>Known issues:</div>
                                <ul className='list-disc list-outside pl-3 text-xxs'>
                                    <li>Element sizes and spacings might slightly differ from the calendar preview, on smaller paper formats.</li>
                                    <li>Poor mobile support</li>
                                </ul>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
            <div className='hidden'>
                {
                    canRender &&
                    <div ref={componentRef}
                         className="flex items-center justify-center overflow-hidden shadow-none rounded-none h-screen">
                        <LifeCalendar
                            displayCurrentWeek={false}
                            layer={layer}
                            zoom={getSize()}
                            birthdate={birthdate}
                            events={events}
                            readonly={true}
                        />
                    </div>
                }
            </div>
        </div>
    )
}