import {Popover} from "@headlessui/react";
import IconButtonBase from "../../components/ui/buttons/IconButtonBase";
import PopoverTransition from "../../components/ui/transitions/PopoverTransition";
import Paper from "../../components/layouts/Paper";
import IconButton from "../../components/ui/buttons/IconButton";
import {CalendarIcon, LayersIcon, PlusIcon} from "@radix-ui/react-icons";
import React, {useRef, useState} from "react";
import EditLayerDialog from "../../features/life-calendar/EditLayerDialog";
import EditEvent from "../../features/life-calendar/event/EditEvent";
import StyledDialog from "../../components/dialogs/StyledDialog";
import {LayerOutput} from "../../../amplify/backend/function/lifecalendarlayers/ts/model";
import DialogTitle from "../../components/dialogs/DialogTitle";

type Props = {
    layer?: LayerOutput,
    birthdate?: string,
    defaultLayer?: LayerOutput,
}

export default function CalendarSettings(props: Props) {
    const {
        layer,
        birthdate,
        defaultLayer,
    } = props;

    const [isLayerDialogOpen, setIsLayerDialogOpen] = useState(false);
    const [isEventDialogOpen, setIsEventDialogOpen] = useState<boolean>(false);

    const initialFocus = useRef(null);

    return (
        <>
            <Popover className='relative'>
                <Popover.Button className='rounded-full'>
                    <IconButtonBase
                        tooltip="Add new layer or event"
                        size={'large'}
                        variant={"outlined"}
                        icon={<PlusIcon className="w-6 aspect-square"/>}
                        side='top'
                    />
                </Popover.Button>
                <PopoverTransition>
                    <Popover.Panel className={`absolute z-10 transform right-0 top-14`}>
                        <div className='flex flex-col gap-2'>
                            <Paper className='rounded-full'
                                   shadow='lg'>
                                <IconButton
                                    tooltip='Add new layer'
                                    onClick={() => {
                                        setIsLayerDialogOpen(true)
                                    }}
                                    variant={'outlined'}
                                    size={'large'}
                                    icon={<LayersIcon/>}
                                    side='right'
                                />
                            </Paper>
                            <Paper className='rounded-full'
                                   shadow='lg'>
                                <IconButton
                                    tooltip={layer ? 'New Event' : 'Please select a layer first'}
                                    onClick={() => setIsEventDialogOpen(true)}
                                    variant={'outlined'}
                                    size={'large'}
                                    icon={<CalendarIcon/>}
                                    side='right'
                                    disabled={!layer}
                                />
                            </Paper>
                        </div>
                    </Popover.Panel>
                </PopoverTransition>
            </Popover>
            <EditLayerDialog
                open={isLayerDialogOpen}
                setIsOpen={setIsLayerDialogOpen}
                defaultLayer={defaultLayer}
            />
            {
                layer && (
                    <StyledDialog
                        isOpen={isEventDialogOpen}
                        setIsOpen={setIsEventDialogOpen}
                        title={<DialogTitle layer={layer} title='New Event' />}
                        initialFocus={initialFocus}
                    >
                        <EditEvent
                            initialFocus={initialFocus}
                            layer={layer}
                            setIsDialogOpen={setIsEventDialogOpen}
                            missingBirthdateWarning={!birthdate}
                        />
                    </StyledDialog>
                )
            }
        </>
    )
}