import {ReactNode} from "react";

type Props = {
    children: ReactNode,
}

export function ButtonGroup(props: Props) {
    const {
        children,
    } = props;

    return (
        <div className='flex justify-end gap-x-4'>
            { children }
        </div>
    )
}