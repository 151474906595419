type Props = {
    children: string,
    extraClasses?: string,
}

export default function SectionTitle(props: Props) {
    const {
        children,
        extraClasses,
    } = props;

    return (
        <div className={`text-sm font-semibold ${extraClasses}`}>{children}</div>
    )
}