import classNames from "classnames";
import {ReactNode} from "react";

type TypographyProps = {
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'p' | 'span',
    color?: 'success' | 'info' | 'warning' | 'error' | 'primary',
    children: ReactNode,
    center?: boolean,
}

export default function Typography(props: TypographyProps) {
    const {
        variant = 'p',
        children,
        color = 'primary',
        center = false,
    } = props

    const classes = `max-w-prose ${classNames({
        'text-rose-600': color === 'error',
        'text-stone-950': color === 'primary' || color === 'info',
        'text-amber-800': color === 'warning',
        'text-center': center,
        })
    }`
    switch (variant) {
        case 'h1':
            return <h1 className={`text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold ${classes}`}>{children}</h1>
        case 'h2':
            return <h2 className={`text-2xl md:text-3xl font-bold ${classes}`}>{children}</h2>
        case 'h3':
            return <h3 className={`text-xl md:text-2xl font-bold ${classes}`}>{children}</h3>
        case 'h4':
            return <h4 className={classes}>{children}</h4>
        case 'h5':
            return <h5 className={classes}>{children}</h5>
        case 'h6':
            return <h6 className={classes}>{children}</h6>
        case 'subtitle1':
            return <p className={`opacity-90 text-lg ${classes}`}>{children}</p>
        case 'subtitle2':
            return <p className={`opacity-80 text-base ${classes}`}>{children}</p>
        case 'p':
            return <p className={`text-xs ${classes}`}>{children}</p>
        case 'span':
            return <span className={`text-sm ${classes}`}>{children}</span>
        default:
            throw new Error('Typography variant not found')
    }
}